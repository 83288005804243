import { withFirebase } from 'providers/firebase';

import { useCallback, useState, useEffect } from 'react';

import { Modal } from '@shopify/app-bridge-react';

import {
  Banner,
} from '@shopify/polaris';

import { useAppSelector } from 'hooks/hooks';
import { dbPaths } from 'refs/db-paths';
import { articlePath } from 'refs/links';


function Announcement({ db, shop }) {
  const [loading, loadingSet] = useState(true)
  const [announcement, announcementSet] = useState({})
  const [articleActive, articleActiveSet] = useState(false)
  const {
    config: {
      hide_getting_started,
      last_announcement,
      _init
    },
    env: {
      hasAuthenticated
    }
  } = useAppSelector(s => s)

  useEffect(() => {
    if (hasAuthenticated) {
      db
        .doc(dbPaths.announcement())
        .get()
        .then(s => {
          announcementSet(s.data() || {})
          loadingSet(false)
        })
    }
  }, [db, hasAuthenticated]);

  const handleDismiss = useCallback(() => {
    db.doc(dbPaths.settingsConfig(shop)).update({ last_announcement: new Date().toISOString() })
  }, [db, shop])

  
  if (
    !_init
    || loading
    || (_init && !hide_getting_started)
    || (!announcement?.article_id || !announcement?.caption)
    || (last_announcement && announcement?.date && new Date(last_announcement) >= announcement.date.toDate())
  ) return null

  return (
    <Banner
      title={announcement.title || "What's new"}
      action={{ content: 'Read more', onAction: () => articleActiveSet(true) }}
      status="info"
      onDismiss={handleDismiss}
    >
      <Modal
        src={articlePath({id: announcement.article_id})}
        open={articleActive}
        onClose={() => articleActiveSet(false)} size={announcement.size || 'Medium'}
      />
      <p dangerouslySetInnerHTML={{ __html: announcement.caption }}></p>
    </Banner>
  )

}


export default withFirebase(Announcement)

