import './style.scss'

import { withFirebase } from 'providers/firebase'

import { useMemo, useEffect, useState, useCallback } from 'react';

// import { TitleBar } from '@shopify/app-bridge-react';

import {
  Page,
  Stack,
  DisplayText,
  Button,
  Icon,
  Layout,
  Tabs,
  Card,
} from '@shopify/polaris';


import styled from 'styled-components'

import useWindowSize from 'hooks/useWindowSize';
import { textsTransfer } from 'refs/resource-names'
import TransfersList from 'components/TransfersList/TransfersList';
import { getQueryParam, greeting, paramsToQuery } from 'utils/helpers'
// import Loader from 'components/Loader/Loader';
import GettingStarted from 'components/GettingStarted/GettingStarted';

import { useAppSelector } from 'hooks/hooks';
import useJob from 'hooks/useJobs';
import store from 'store';
import Announcement from 'components/Announcement/Announcement';
import { Loading } from '@shopify/app-bridge-react';
import { formatShopName } from 'refs/shop';
import { dbPaths } from 'refs/db-paths';
import Exports from "./components/Exports";

function Transfers(props) {
  const { db, shop, loading, history } = props
  const [syncCalled, syncCalledSet] = useState(false)
  const [tab, tabSet] = useState(0)
  const { locations, locations_retrieved } = useAppSelector(s => s.appData)
  const { createJob, jobCodes } = useJob()

  const windowSize = useWindowSize()
  
  const dbRef = useMemo(() => db.collection(dbPaths.transfers(shop)), [db, shop]);

  const hasMultipleLocations = useMemo(() => {
    return loading
    ? true 
    : locations_retrieved
        ? Object.keys(locations || {}).length > 1
        : store.get(`${formatShopName(shop)}_multipleLocations`) || false
  }, [loading, locations, locations_retrieved, shop])

  const handleTabChange = useCallback((i) => {
    tabSet(i)
    history.replace({ search: paramsToQuery({ tab: i }, true)})
  },[history],);

  useEffect(() => {
    if (locations_retrieved) {
      store.set(`${formatShopName(shop)}_multipleLocations`, hasMultipleLocations)
    }
  }, [hasMultipleLocations, locations_retrieved, shop]);
  
  useEffect(() => {
    if (!syncCalled && !loading) {
      syncCalledSet(true)
      createJob(jobCodes.JOB_SYNC_PRODUCTS, { latest: true })
    }
  }, [createJob, jobCodes, loading, syncCalled]);

  useEffect(() => {
    let tab = getQueryParam('tab')
    tabSet(Number(tab))
  }, []);
  
  const distribution = windowSize.width < 900
    ? 'center'
    : hasMultipleLocations 
    ? "equalSpacing" 
    : "leading"

  const tabs = [
    { id: 'open', content: 'Open', panelID: 'open' },
    { id: 'all', content: 'All', panelID: 'all' },
  ]


  return (
      <Page title={`${greeting()}, what would you like to do?`}>

        <Layout>
          <Layout.Section>
            <GettingStarted/>
            <Announcement/>
          </Layout.Section>
          
          <Layout.Section>
            <Stack alignment="center" spacing="extraLoose" distribution={distribution}>
              <Button primary url={'/transfers/external/new'} disabled={loading}>
                <TileButton>
                  <Icon
                    source={textsTransfer.external.icon}
                    color="base" />
                  <DisplayText size="medium">{textsTransfer.external.action}</DisplayText>
                  <TileSubtitle>Transfer inventory from a supplier into one of your locations</TileSubtitle>
                </TileButton>
              </Button>
              {hasMultipleLocations &&
                <Button primary url={'/transfers/internal/new'} disabled={loading}>
                  <TileButton>
                    <Icon
                      source={textsTransfer.internal.icon}
                      color="base" />
                    <DisplayText size="medium">{textsTransfer.internal.action}</DisplayText>
                    <TileSubtitle>Transfer inventory internally between your locations</TileSubtitle>
                  </TileButton>
                </Button>
              }
              <Button primary url={'/transfers/adjustment/new'} disabled={loading}>
                <TileButton>
                  <Icon
                    source={textsTransfer.adjustment.icon}
                    color="base" />
                  <DisplayText size="medium">{textsTransfer.adjustment.action}</DisplayText>
                  <TileSubtitle>Adjust inventory levels at one of your locations</TileSubtitle>
                </TileButton>
              </Button>
            </Stack>
            {loading && <Loading/>}
          </Layout.Section>

          <Exports/>
          
          <Layout.Section>
            <Card>
              <Tabs tabs={tabs} selected={tab} onSelect={handleTabChange}>
                {tab === 0 &&
                  <TransfersList
                    showOpenTransfers
                    hideControls
                    hideTags
                  />
                }
                {tab === 1 &&
                  <TransfersList dbRef={dbRef} />
                }
              </Tabs>
              
            </Card>

          </Layout.Section>
        </Layout>
      </Page>

  );
}


export default withFirebase(Transfers)



const TileButton = styled.button`
  height:220px;
  width:220px;
  border:none;
  background: transparent;
  color:inherit;
  cursor:inherit;
  font-family:inherit;
  font-size:inherit;
  `

const TileSubtitle = styled.div`
  margin-top: 2rem;
`;