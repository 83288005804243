import { RootState } from './../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { settingsPlanPath } from 'refs/links'
function hasSessionStorage () {
  try {
    return typeof(sessionStorage) !== 'undefined'
  } catch (error) {
    return false
  }
}

declare global {
  interface Window {
    zE:any;
  }
}


function hasZendeskWigetAccess () {
  try {
    // eslint-disable-next-line
    return typeof(window.zE) !== 'undefined'
  } catch (error) {
    return false
  }
}

interface Redirect {
  uri: string,
  external?: boolean,
  excludePaths?: string[],
}

interface InitialState {
  shop: string,
  host: string,
  emulating: string,
  isAdmin: boolean,
  isMobile: boolean,
  hasCheckedInstall: boolean,
  hasAuthenticated: boolean,
  redirectUser: Redirect,
  isEmbedded: boolean,
  hasSessionStorage: boolean,
  hasZendeskWigetAccess: boolean
}

const initialState:InitialState = {
  shop: '', 
  host: '', 
  emulating: '', 
  isAdmin: false,
  isMobile: /Mobi|Android/i.test(navigator.userAgent),
  hasCheckedInstall: false,
  hasAuthenticated: false,
  redirectUser: { uri: '', external: false, excludePaths: [''] },
  isEmbedded: window.top !== window.self,
  hasSessionStorage: hasSessionStorage(),
  hasZendeskWigetAccess: hasZendeskWigetAccess()
};

export const slice = createSlice({
  name: 'env',
  initialState,
  reducers: {
    setShop: (state, { payload }) => {
      state.shop = payload
    },
    setHost: (state, { payload }) => {
      state.host = payload
    },
    setEmulating: (state, { payload }) => {
      state.emulating = payload
    },
    setHasAuthenticated: (state, { payload }) => {
      const { authenticated, isAdmin = false }  = payload      
      state.hasAuthenticated = authenticated
      state.isAdmin = isAdmin
    },
    setHasCheckedInstall: (state, { payload  }) => {
      state.hasCheckedInstall = payload
    },
    setRedirect: (state, action:PayloadAction<Redirect>) => {
      state.redirectUser.uri = action.payload.uri
      state.redirectUser.external = action.payload.external || false
      state.redirectUser.excludePaths = action.payload.excludePaths || []
    },
    setSelectPlan: (state ) => {
      state.redirectUser.uri = settingsPlanPath({ force: true })
      state.redirectUser.external = false
      state.redirectUser.excludePaths = ['/support']
    },
  },
});

export const { 
  setShop,
  setHost,
  setEmulating,
  setHasAuthenticated, 
  setHasCheckedInstall,
  setRedirect,
  setSelectPlan,
} = slice.actions;

export const getRequestShop = (state: RootState) => state.env.emulating || state.env.shop;


export default slice.reducer;
