import { useCallback, useState, useMemo } from 'react';

import {
  Icon,
  Autocomplete,
  TextContainer,
  Stack,
  Tag,
} from '@shopify/polaris';

import {
  CirclePlusMinor
} from '@shopify/polaris-icons';

import { focusInput } from 'utils/helpers';
import { useAppSelector } from 'hooks/hooks';
import ScanGuard from 'components/ScanGuard/ScanGuard';


export default function TagsField(props) {
  let {
    label,
    value = [],
    onChange,
    suggestions = [],
    tagsKey,
    onAddNewTag,
    placeholder = 'Tags',
  } = props

  const inputId = 'tags'

  const [inputValue, inputValueSet] = useState('');
  
  const { ui = {} } = useAppSelector(s => s.appData)
  
  const suggestedTags = useMemo(() => {
    let storedTags = []
    if (tagsKey) storedTags = ui[tagsKey] || []
    return [ ...new Set([ ...storedTags, ...value, ...suggestions ])]
  }, [suggestions, ui, tagsKey, value]);

  const handleChange = useCallback((val) => {
    onChange(val)
    inputValueSet('')
    focusInput(inputId)
  }, [onChange])

  const options = useMemo(() => {
    const mapOptions = (tag) => ({ value: tag, label: tag })
    if (inputValue === '') return suggestedTags.map(mapOptions)

    const filterRegex = new RegExp(inputValue, 'i');
    return suggestedTags
      .filter((tag) => tag.match(filterRegex))
      .filter((tag) => !value.includes(tag) || inputValue === tag)
      .map(mapOptions)

  }, [inputValue, suggestedTags, value])

  const removeTag = useCallback((tag) => {
    const selected = [...value];
    selected.splice(selected.indexOf(tag), 1);
    onChange(selected);
  }, [value, onChange]);


  const addNewTag = useCallback(() => {
    if (onAddNewTag) onAddNewTag(inputValue);

    onChange([...value, inputValue])
    inputValueSet('')
    focusInput(inputId)
  }, [inputValue, onAddNewTag, onChange, value]);

  const handleKeyDown = useCallback((e) => {
    if (e.keyCode === 13) {
      if (inputValue && suggestedTags.includes(inputValue) && !value.includes(inputValue)) {
        handleChange([...value, inputValue])
        inputValueSet('')
      } else if (inputValue && !suggestedTags.includes(inputValue)) {
        addNewTag()
      } else {
        inputValueSet('')
      }
    }
  }, [addNewTag, handleChange, inputValue, suggestedTags, value])

  const textField = (
    <div onKeyDown={handleKeyDown}>
      
      <ScanGuard
        component={Autocomplete.TextField}
        id={inputId}
        onChange={inputValueSet}
        label={label}
        value={inputValue}
        placeholder={placeholder}
      />
    </div>
  );

  const actionBefore = {
    content: <><strong>Add </strong> {inputValue}</>,
    onAction: addNewTag,
    icon: () => <Icon source={CirclePlusMinor} color="success" />
  }


  return (
    <div>
      <Autocomplete
        allowMultiple
        options={options}
        selected={value}
        textField={textField}
        onSelect={handleChange}
        actionBefore={inputValue && !suggestedTags.includes(inputValue) && actionBefore}
        listTitle={!inputValue && "Suggested tags"}
      />
      <div style={{ marginTop: "0.8rem" }}>
        <TextContainer>
          <Stack spacing="extraTight">
            {(value || []).map((tag) => (
              <Tag key={`option${tag}`} onRemove={() => removeTag(tag)}>
                {tag}
              </Tag>
            ))}
          </Stack>
        </TextContainer>
      </div>
    </div>
  );
}