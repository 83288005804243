import { withFirebase } from 'providers/firebase'

import { useCallback, useMemo, useEffect, useState } from 'react';

import {
  ResourceList,
  ResourceItem,
  TextStyle,
  Card,
  Stack,
  Caption,
  Heading,
  Button,
  Subheading,
  ButtonGroup,
} from '@shopify/polaris';

import { getQueryParam, highlightResourceItem, objectToArray } from 'utils/helpers';



import SuppliersNewModal from 'views/suppliers/components/SuppliersNewModal';
import LocationItem from 'components/Location/LocationItem';
import LocationAvatar from 'components/Location/LocationAvatar'
import { textsLocation } from 'refs/resource-names'
import styled from 'styled-components';
import { useAppSelector } from 'hooks/hooks';

function Location(props) {
  const {
    locationId,
    locationType,
    // transfer.type,
    showPicker,
    step,
    onGotoStep,
    filterId,
    onChange,
    finishedSteps,
    displayOnly,
    avatarSize = "medium",
  } = props
  const [sortLetter, sortLetterSet] = useState('')

  const { 
    config: {
      default_split_location,
      default_split_location_hidden
    },
    appData: { 
      locations = {} 
    }, 
    suppliers = {},
    transfer
  } = useAppSelector(s => s)

  const toggleSortLetter = useCallback((l) => {
    sortLetterSet(x => x !== l ? l : null)
  },[])

  const handleSelection = useCallback((val) => {
    onChange(locationType, val, step)
  }, [onChange, locationType, step])

  const items = useMemo(() => {
    const prepareItems = (items) => {
      return objectToArray(items)
        .map(({ id, name, address1, avatar }) => (
          { id, name, onAction: handleSelection, location: address1, avatar }
        ))
        .filter(l => l.id !== filterId)
        .sort((a, b) => (a.name > b.name) ? 1 : -1)
    }

    if (locationType === 'destination') return prepareItems(locations)
    if (transfer.type === 'internal') return prepareItems(locations)
    if (transfer.type === 'external') return prepareItems(suppliers)
    return prepareItems(locations)
  }, [locationType, locations, transfer.type, suppliers, handleSelection, filterId])

  const filteredItems = useMemo(() => {
      return items
        .filter(l => sortLetter 
          ? (l.name || '').substr(0, 1).toUpperCase() === sortLetter 
          : true
          )
        .filter(l => default_split_location_hidden
          ? l.id !== default_split_location
          : true
          )
  }, [default_split_location, default_split_location_hidden, items, sortLetter])

  const letters = useMemo(() => {
    const letters = items
      .map(i => (i.name || '').substr(0, 1).toUpperCase())
      .sort((a, b) => a > b ? 1 : -1)
    return [...new Set(letters)]
  }, [items])

  const display = useMemo(() => {
    return textsLocation[transfer.type]?.[locationType] || {}
  }, [locationType, transfer.type])


  useEffect(() => {
    let origin = getQueryParam('origin')
    let destination = getQueryParam('destination')

    if (origin && !suppliers.origin) onChange('origin', origin, 0)
    if (destination && !suppliers.destination) onChange('origin', destination, 1)
  }, [onChange, step, suppliers.destination, suppliers.origin])


  useEffect(() => {
    highlightResourceItem(locationId, 'AC-ListItem__Active')
  }, [locationId, showPicker])

  return (
    <>
      {showPicker && !displayOnly
        && <Card.Section>
          <Stack alignment="center">
            <Stack.Item fill>
              <Heading>{display.heading}</Heading>
              <Caption> {display.subheading} </Caption>
            </Stack.Item>
            {transfer.type === 'external' && locationType === 'origin' &&
              <SuppliersNewModal
                title="Create a new supplier"
                onNew={handleSelection}
                isNew
              />
            }

          </Stack>
          { transfer.type === 'external' && locationType === 'origin' &&
            <div style={{ marginTop: '2rem' }}>
              <ButtonGroup segmentedx>
                {letters.map(l => (
                  <Button
                    key={l}
                    primary={sortLetter === l}
                    onClick={() => toggleSortLetter(l)}
                  >
                    <Character>{l}</Character>
                  </Button>
                ))}
              </ButtonGroup>

            </div>
          }
        </Card.Section>
      }
      {showPicker && !displayOnly
        ?
        <ResourceList
          resourceName={display.resourceNames}
          items={filteredItems}
          renderItem={(item) => {
            const { id, onAction, name, location, avatar = {} } = item;
            return (
              <ResourceItem
                id={id}
                onClick={onAction}
                accessibilityLabel={`Select ${name}`}
              >
                <Stack alignment="center">
                  <LocationAvatar
                    source={avatar && (avatar.src_thumb || avatar.src)}
                    locationType={locationType}
                    transferType={transfer.type}
                    size={avatarSize}
                  />
                  <Stack.Item>
                    <h3>
                      <TextStyle variation="strong">{name}</TextStyle>
                    </h3>
                    <div>{location}</div>
                  </Stack.Item>
                </Stack>
              </ResourceItem>
            );
          }}
        />
        :
        <Card.Section>
          <div style={{ paddingBottom: '0.8rem' }}>
            <Stack alignment="center">
              <Stack.Item fill>
                {finishedSteps || displayOnly
                  ? <Subheading>{display.heading}</Subheading>
                  : <Heading>{display.heading}</Heading>
                }
              </Stack.Item>
              {!displayOnly && 
                <Button plain onClick={() => onGotoStep(step)}>Change</Button>
              }
            </Stack>
          </div>
          <LocationItem
            id={locationId}
            locationType={locationType}
            transferType={transfer.type}
            size={avatarSize}
          />
        </Card.Section>


      }
    </>
  );
}

const Character = styled.div`
  min-width: 1.2rem;
`

export default withFirebase(Location)

