import { createSlice } from '@reduxjs/toolkit';
import { objectToArray } from 'utils/helpers';

const initialState = {
  codes: [],
  levels: {},
  items: {},
};

export const slice = createSlice({
  name: 'inventory',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setInventoryIndex: (state, { payload = {} }) => {
      state.codes = objectToArray(payload)
    },
    setInventoryItem: (state, { payload = {} }) => {
      const { id } = payload
      state.items[id] = payload
    },
    setInventoryItems: (state, { payload = {} }) => {
      state.items = { 
        ...state.items, 
        ...payload
            .filter(item => !!item)
            .reduce((items, item) => ({ ...items, [item.id]: item }), {}) 
      }
    },
    setInventoryLevel: (state, { payload = {} }) => {
      const { id, levels } = payload
      state.levels[id] = levels
    },
    setInventoryLevels: (state, { payload = {} }) => {
      state.levels = { 
        ...state.levels, 
        ...payload
            .filter(item => !!item)
            .reduce((levels, item) => ({ ...levels, [item.id]: item.levels }), {}) 
      }
    },
    clearInventory: (state, { payload = {} }) => {
      state.levels = {}
    },
  },
});

export const { 
  setInventoryIndex, 
  setInventoryItem,
  setInventoryItems,
  setInventoryLevel,
  setInventoryLevels,
  clearInventory
} = slice.actions;


export default slice.reducer;
