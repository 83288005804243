import './style.scss'

import styled from 'styled-components';
import { 
  Stack, 
  Caption,
  TextStyle,
  Thumbnail,
  SkeletonDisplayText,
} from '@shopify/polaris';
import {
  ImageMajor,
} from '@shopify/polaris-icons';
import { formatMoney } from 'utils/formatters';
import { useAppSelector } from 'hooks/hooks';
import { textsTransfer } from 'refs/resource-names';


export default function TransferPrintItem ({item, border}) {

  const {
    transfer,
    inventory: { items },
    config: { transfer_show_prices = false }
  } = useAppSelector(state => state)

  const {
    id,
    qty,
    operation,
  } = item

  const {
    image,
    title,
    productTitle,
    sku,
    price,
    compareAtPrice,
    barcode,
    hasOnlyDefaultVariant,
  } = items[id] || {}

  return (
    <PrintRow border={border} hasPrices={transfer_show_prices}>
        <Thumbnail customer size="small" source={image || ImageMajor} />
    
              <div>
                {!productTitle && <SkeletonDisplayText size="small" />}
                <TextStyle variation="strong">{productTitle}</TextStyle>
                <Caption><TextStyle variation="strong">{!hasOnlyDefaultVariant && title}</TextStyle></Caption>
              </div>
              <DetailsCell>
                <div>
                  <Caption><TextStyle variation="subdued">SKU: {sku}</TextStyle> </Caption>
                  <Caption><TextStyle variation="subdued">Barcode: {barcode}</TextStyle></Caption>
                </div>
                { transfer_show_prices 
                  ? <div>
                    <Caption><TextStyle variation="subdued">Price: {formatMoney(price)}</TextStyle> </Caption>
                    <Caption><TextStyle variation="subdued">Compare at price: {formatMoney(compareAtPrice)}</TextStyle></Caption>
                  </div> 
                  : null
                }        
              </DetailsCell>
            <Stack wrap={false} spacing="tight"distribution="fillEvenly">
                {textsTransfer[transfer.type] &&
                  <Stack.Item>
                    {textsTransfer[transfer.type].operationName(operation, transfer?.status === 'complete')}
                  </Stack.Item>
                }
                <Stack.Item><TextStyle variation="strong">{qty || 0}</TextStyle></Stack.Item>
              </Stack>
      </PrintRow>
  )
}

const PrintRow = styled.div`
  ${p => p.border ? 'border-top: 0.1rem solid #e1e3e5;':''}
  padding: 1rem 1.6rem;
  display: flex;
  align-items: center;

  > div {
    padding-left: 1.6rem;
  }
  > div:first-of-type {
    max-width: ${p => p.hasPrices ? '40%': '55%'};
    min-width: ${p => p.hasPrices ? '40%': '55%'};
  }
`
const DetailsCell = styled.div`
  display: flex;
  flex-grow: 1;
  > div {
    flex: 1 0;
    padding-left: 1.6rem;
  }
`
