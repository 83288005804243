import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
// import { indexedDb } from "hooks/useIndexedDb";
import useAxios from "hooks/useAxios";
import { clearInventory, setInventoryItems, setInventoryLevels } from "redux/slices/inventory";
import { graphQlToInventoryItem, graphQlToInventoryItemLevels } from "utils/mutators";
import { clearTransfer } from "redux/slices/transfer";
import { clearTransferState } from "redux/slices/transfer-state";
import useIndexedDb from "hooks/useIndexedDb";


function InventoryFetcher () {
  const [fetched, fetchedSet] = useState([])

  const {
    env: { hasSessionStorage, shop },
    transfer: { 
      origin, 
      destination,
      items
    },
    transferState: {
      multipleMatches
    }
  } = useAppSelector(s => s)
  const indexedDb = useIndexedDb()
  const dispatch = useAppDispatch()
  const { axios } = useAxios()

  const locations = { origin, destination }

  const itemsIds = useMemo(() => Object.keys(items), [items])

  const retrieveInventoryItems = useCallback(async (ids) => {
    let attempts = 0;

    try {
      ids = ids.filter(id => !fetched.includes(id))

      if (ids.length === 0) return

      fetchedSet(x => [...x, ...ids])

      if (hasSessionStorage) {
        indexedDb.items
          .bulkGet(ids)
          .then(items => {
            dispatch(setInventoryItems(items))
          })
      }

      const getInventoryItems = async () => {
        return new Promise((resolve, reject) => {
          if (attempts <= 4) {
            setTimeout(async () => {
              let items
              try {
                  items = await axios.post(`/api/resources/inventory-items/retrieve`, { ids, locations })
              } catch (error) {
                attempts++
                items = await getInventoryItems()
              }
              resolve(items)
            }, attempts ? Math.pow(3, attempts) * 1000 : 0)
          } else {
            reject("Unable to retrieve inventory")
          }
        })          
      }

      const inventoryItems = await getInventoryItems()

      const filteredInventoryItems = inventoryItems.filter(i => i)
      const items = filteredInventoryItems.map(graphQlToInventoryItem)
      const levels = filteredInventoryItems.map(item => graphQlToInventoryItemLevels(item, locations))

      if (hasSessionStorage) indexedDb.items.bulkPut(items)

      dispatch(setInventoryItems(items))
      dispatch(setInventoryLevels(levels))
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch, fetched, hasSessionStorage, locations])

  useEffect(() => {
    retrieveInventoryItems(itemsIds)
  }, [itemsIds, retrieveInventoryItems]);
  
  useEffect(() => {
    const ids = multipleMatches.map(m => m.matches.map(({id}) => id)).flat()
    retrieveInventoryItems(ids)
  }, [items, itemsIds, multipleMatches, retrieveInventoryItems]);


  useEffect(() => {
    dispatch(clearInventory())
  }, [dispatch, origin, destination]);

  useEffect(() => {
    return () => {
      dispatch(clearTransfer())
      dispatch(clearTransferState())
    }
  }, [dispatch]);



  return null
}


export default InventoryFetcher