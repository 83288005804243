import { withFirebase } from 'providers/firebase'

import React, { useCallback } from "react"



import {
  Card,
  FormLayout,
  Layout,
  Select,
  TextField,
} from "@shopify/polaris";


import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/hooks';
import { setConfigValue } from 'redux/slices/config';
import { dbPaths } from 'refs/db-paths';

function MatchingSection(props) {
  const { db, shop } = props

  const { config } = useSelector(s => s)
  const dispatch = useAppDispatch()
  
  const handleValueChange = useCallback((key, value) => {
    dispatch(setConfigValue({ key, value }))
    db.doc(dbPaths.settingsConfig(shop)).update({ [key]: value })
  }, [db, dispatch, shop]) 

  const handleKeystrokeDelayBlur = useCallback((value) => {
    if (!config.scan_keystroke_delay) handleValueChange('scan_keystroke_delay', config.scan_keystroke_delay_default)
  }, [config.scan_keystroke_delay, config.scan_keystroke_delay_default, handleValueChange])

  return (

    <Layout.AnnotatedSection title="Barcode & SKU matching" >
      <Card>
        <Card.Section title="Matching">
          <FormLayout>
            <Select
              label="Case sensitivity"
              value={config.case_insensitive_matching}
              onChange={(v) => handleValueChange('case_insensitive_matching', v=== 'true')}
              options={[
                { label: 'Match case exactly', value: false },
                { label: 'Match upper or lower case', value: true },]
              }
              helpText="Set how characters of scanned or typed barcodes and SKUs should be matched."
            />
            <TextField
              label="Scanner keystroke delay"
              value={config.scan_keystroke_delay.toString()}
              onBlur={handleKeystrokeDelayBlur}
              onChange={(v) => handleValueChange('scan_keystroke_delay', Number(v))}
              helpText={
                `Expected keystroke delay from scanner. ONLY change if instructed (default: ${config.scan_keystroke_delay_default})`
              }
            />
          </FormLayout>
        </Card.Section>
      </Card>

    </Layout.AnnotatedSection>


  )
}

export default withFirebase(MatchingSection)