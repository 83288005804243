import { withFirebase } from 'providers/firebase'




import {
  Card,
  Page,
} from "@shopify/polaris";
import Shops from './Shops';


function Admin () {


  return (
    <Page title="Admin" >
        <Card>
          <Shops/>
        </Card>
    </Page>
    
  )
}



export default withFirebase(Admin)