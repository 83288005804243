import { 
  AppProvider,
  Banner,
  Page,
} from "@shopify/polaris";

import { getQueryParam } from "utils/helpers";
import { helpNewTicketUrl } from "refs/links";

  
export default function ErrorPage (props) {
  const defaultMessage = 'An unexpected error has occurred and has been reported. You may try again, else contact our support if you require immediate help.'
  const message = getQueryParam('message') || defaultMessage
  
    return (
      <AppProvider forceRedirect={false}>
        <Page>
          <Banner 
          status="critical" 
          title="Unexpected error" 
          action={{ content: 'Submit help request', url: helpNewTicketUrl()}}
          >
            {message}
            </Banner>
        </Page>
      </AppProvider>
    )
}
