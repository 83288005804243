import React, { useCallback, useEffect, useMemo, } from 'react';


import Location from './Location'

import { objectToArray } from 'utils/helpers';

import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { setTransferLocations } from "redux/slices/transfer";

function Locations(props) {
  const {
    transferStep,
    finishedSteps,
    onGotoStep,
    displayOnly
  } = props

  const {
    appData: {

    locations = {},
    },
    transfer
  } = useAppSelector(s => s)


  const dispatch = useAppDispatch()



  const transferLocations = useMemo(() => {
    const { origin, destination } = transfer
    return { origin, destination }
  }, [transfer])

  const handleLocationChange = useCallback((key, val = '', step) => {
    const newLocations = { ...transferLocations, [key]: val.toString() }

    if (step !== undefined) onGotoStep(step + 1)

    // if (step + 1 === 1) {
    let locationsArray = objectToArray(locations)
    let origin = key === 'origin' ? val : transferLocations.origin
    let location = null

    if (
      step === 0
      && locationsArray.length === 1
      && !transferLocations.destination
    ) {
      location = locationsArray[0]
    } else if (
      step === 0
      && transfer.type === 'internal'
      && locationsArray.length <= 2
      && origin
      && !transferLocations.destination
    ) {
      // location = locationsArray.filter(l => l.id !== origin)[0]
    } else if (
      step === 0
      && origin
      && origin === transferLocations.destination
      && locationsArray.length <= 2
    ) {
      location = locationsArray.filter(l => l.id !== origin)[0]
    }
    if (location) {
      newLocations.destination = location.id
      onGotoStep(2)
    }
    // onChange(newLocations)
    dispatch(setTransferLocations(newLocations))
    // }
  }, [transferLocations, onGotoStep, locations, transfer.type, dispatch])

  useEffect(() => {
    let locationsArray = objectToArray(locations)

    if (transfer.type === 'adjustment') {
      if (objectToArray(locations).length === 1 && !transferLocations.destination) {
        dispatch(setTransferLocations({ ...transferLocations, destination: locationsArray[0].id }))
        onGotoStep(2)
      } else if (transferStep === 0) {
        onGotoStep(1)
      }
    }
  }, [dispatch, locations, onGotoStep, transfer.type, transferLocations, transferStep]);


  const showOrigin = useMemo(() => {
    return ['internal', 'external'].includes(transfer.type)
  }, [transfer.type])

  return (
    <>

      {showOrigin &&

        <Location
          locationId={transferLocations.origin}
          onChange={handleLocationChange}
          locationType="origin"
          showPicker={transferStep === 0}
          onGotoStep={onGotoStep}
          finishedSteps={finishedSteps}
          step={0}
          displayOnly={displayOnly}
        />
      }


      {(transferStep > 0 || displayOnly) &&
        <Location
          locationId={transferLocations.destination}
          onChange={handleLocationChange}
          filterId={transferLocations.origin}
          locationType="destination"
          showPicker={transferStep === 1}
          onGotoStep={onGotoStep}
          finishedSteps={finishedSteps}
          step={1}
          displayOnly={displayOnly}
        />
      }
    </>


  );
}


export default Locations
