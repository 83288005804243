import { paramsToQuery } from "../utils/helpers"
import { serverUrl } from "./server-paths"

/**
 * 
 * @param {Object} params any params to be sent as query string
 * @param {Boolean} appendQuery include current window query and append params
 * @returns Path to auth app
 */
export const homeLocation = (params, appendQuery) => '/home'
export const suppliersLocation = (params, appendQuery) => '/suppliers'
export const transfersLocation = (params, appendQuery) => '/transfers'
export const articlePath = (params, appendQuery) => `/app/article/${params.id}`
export const settingsPlanPath = (params, appendQuery) => `/settings/plan${paramsToQuery(params, appendQuery)}`
export const serverAuthPath = (params, appendQuery) => `${serverUrl}/services/auth/signin${paramsToQuery(params, appendQuery)}`
export const supportPath = (params, appendQuery) => '/support'
export const helpNewTicketUrl = (params) => 'https://automatecommerce.zendesk.com/hc/en-us/requests/new'
export const appReviewUrl = () => `https://apps.shopify.com/${process.env.REACT_APP_STORE_SLUG}#modal-show=ReviewListingModal`


export const apiUrls = {
  gotoTransfer: (id, queryParams) => `${serverUrl}/api/transfers/${id}/goto${paramsToQuery(queryParams)}`,
}