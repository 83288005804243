
import { withFirebase } from 'providers/firebase'

import React from "react"
import {
  Layout,
  Button,
  TextContainer,
  Card,
} from "@shopify/polaris";
import { appReviewUrl } from 'refs/links';




export function ReviewSection () {
  return (
    <Layout.AnnotatedSection title="App review">
      <Card.Section>

        <div  style={{ marginBottom: '1rem' }}>
          We are a small passionate team based in Australia, working hard to bring you amazing apps.
          <br/>
          Reviews help us grow and serve more merchants. We would be grateful if you could take a moment to share your experience in a review. 
          It really helps us, and assists smart merchants like you choose the right apps for their store. 
        </div>
          <Button
            primary
            url={appReviewUrl()}
            external
          >
            Review now
          </Button>
      </Card.Section>
  </Layout.AnnotatedSection>  
  )
}


export default withFirebase(ReviewSection)
