import {
  AppProvider,
} from "@shopify/polaris";


import { useAppSelector } from "hooks/hooks";
import { useMemo } from "react";


export default function Logs(props) {

  const {
    // appData: {
    //   logs
    // },
    inventory: {
      items = {}
    }
  } = useAppSelector(s => s)

  const logs = useMemo(() => {
      return JSON.parse(sessionStorage.getItem('ac_logs') || '[]')
  }, [])

  return (
    <AppProvider forceRedirect={false}>
      <pre>
        {[...logs, JSON.stringify(items, null, 2)].join('\n')}
      </pre>
    </AppProvider>
  )
}
