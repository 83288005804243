import { useState, useCallback, useEffect } from 'react';

import {
  Button,
  Link,
  TextField,
} from '@shopify/polaris';

import {
  SearchMajor
} from '@shopify/polaris-icons';


import useWindowSize from 'hooks/useWindowSize';
import { useAppSelector } from 'hooks/hooks';
import useImportFromCSV from "hooks/useImportFromCSV";
import useFeatures from "hooks/useFeatures";

function SearchField(props) {
  const {
    id,
    value,
    onChange,
    onScan,
    onBrowseProducts,
  } = props
  const [lazyValue, lazyValueSet] = useState('')
  const [changeTimeout, changeTimeoutSet] = useState(null)
  const [changeInputTime, changeInputTimeSet] = useState(null)
  const [inputTime, inputTimeSet] = useState(null)
  const [buffer, bufferSet] = useState('')
  const [lastCharacter, lastCharacterSet] = useState('')
  const [isScanEvent, isScanEventSet] = useState(false)
  const [showCSVMessage, setShowCSVMessage] = useState(false)

  const { 
    scan_keystroke_delay: scanKeystrokeDelay,
    scan_min_length: minScanLength
 } = useAppSelector(s => s.config)

 const { importFromCSV } = useImportFromCSV()

 const { hasFeature, upgradeUrl } = useFeatures()

  const smallButton = useWindowSize().width < 960

  const handleChange = useCallback((v) => {
    const eventTime = Number(new Date())
    const timeSinceLast = eventTime - changeInputTime
    changeInputTimeSet(eventTime)
    lazyValueSet(v)
    
    if (timeSinceLast < scanKeystrokeDelay) {
      if (changeTimeout) clearTimeout(changeTimeout)
      isScanEventSet(true)
      changeTimeoutSet(
        setTimeout(() => {
          onScan(v)
          lazyValueSet('')
        }, scanKeystrokeDelay * 2.5)
      )
    } else {
      onChange(v)
      isScanEventSet(false)
    }

  }, [changeInputTime, scanKeystrokeDelay, changeTimeout, onScan, onChange])

  const handlePaste = useCallback((e) => {
    const text = e.clipboardData.getData('text/plain')
    if (text.includes('\n')) {
      e.preventDefault()
      if (hasFeature('csv')) {
        importFromCSV(text, text.includes('\t') ? "\t" : " ")
      } else {
        setShowCSVMessage(true)
        setTimeout(() => setShowCSVMessage(false), 5000)
      }
      onChange('')
    } else {
      onScan(text, false)
      setTimeout(() => lazyValueSet(''), 0);
    }
  }, [hasFeature, importFromCSV, onChange, onScan])

  const handleKeyPress = useCallback((e) => {
    if (e.keyCode === 13) { 
        clearTimeout(changeTimeout)
      lazyValueSet('')
      onScan(lazyValue, isScanEvent)
      isScanEventSet(false)
    }

  }, [changeTimeout, onScan, lazyValue, isScanEvent])

  const csvMessage = (
    <p><Link removeUnderline url={upgradeUrl('csv', true) }>Upgrade</Link> to paste CSV data.</p>
  )

  useEffect(() => {
    lazyValueSet(value)
    bufferSet('')
  }, [value])

  useEffect(() => {
    const handler = (e) => {
      let { target, key, ctrlKey, altKey, shiftKey } = e
      let newValue = `${buffer}${e.key}`
      const eventTime = Number(new Date())
      const timeSinceLast = eventTime - inputTime

      inputTimeSet(eventTime)

      if (key && key.length === 1) lastCharacterSet(e.key)

      if (
        timeSinceLast < scanKeystrokeDelay
        && target.id !== id
        && !ctrlKey && !altKey && !shiftKey
        && !['Backspace', 'Delete'].includes(key)
      ) { 
        if (!buffer) newValue = `${lastCharacter}${newValue}`

        bufferSet(newValue)
        if (buffer.length >= minScanLength) {
          lazyValueSet(buffer)
          let scanElement = document.getElementById(id)
          if (scanElement) scanElement.focus()
        }
      } else {
        bufferSet('')
      }
    }

    document.addEventListener('keydown', handler);

    return () => document.removeEventListener('keydown', handler);
  }, [buffer, id, inputTime, lastCharacter, minScanLength, scanKeystrokeDelay])

  return (
    <div onKeyDown={handleKeyPress} onPaste={handlePaste}>
      <TextField
        id={id}
        value={lazyValue}
        onChange={handleChange}
        helpText={showCSVMessage && csvMessage}
        focused={true}
        autoComplete={false}
        name="barcode"
        placeholder="Enter barcode or sku"
        inputMode="search"
        connectedRight={
          <Button
            icon={smallButton ? SearchMajor : <div></div>}
            onClick={onBrowseProducts}
          >
            {!smallButton ? `Browse products` : null}
          </Button>
        }
      />

    </div>
  );
}


export default SearchField

