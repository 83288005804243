import React, { useState, useEffect } from 'react';

import {
  Avatar,
  Icon,
} from '@shopify/polaris';

import {
  StoreMajor,
} from '@shopify/polaris-icons';

import styled from 'styled-components'
import { preLoadImage } from 'utils/helpers'

export default function LocationAvatar(props) {
  const { source, type, transferType, locationType, size = "large" } = props
  const [avatarSource, avatarSourceSet] = useState(null)

  useEffect(() => {
    const preLoadAvatar = async () => {
      let src = await preLoadImage(source)
      avatarSourceSet(src)
    }
    preLoadAvatar()
  }, [source]);

  if ((transferType === 'external' && locationType === 'origin') || type === 'supplier') {
    return (
      <Avatar source={avatarSource} customer size={size} />
    )
  } else {
    return (
      <>
        <LocationAvatarWrap size={size}>
          <Icon source={StoreMajor} color="base" />
        </LocationAvatarWrap>
      </>
    )
  }

}

const LocationAvatarWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: .1rem solid #c9cccf;

  height: ${p => 
    p.size === "extraSmall"
    ? '2.4rem'
    : p.size === "small"
    ? '3.2rem'
    : p.size === "medium"
    ? '4rem'
    : p.size === "large"
    ? '6rem'
    : '6rem'
  };

  width: ${p => 
    p.size === "extraSmall"
    ? '2.4rem'
    : p.size === "small"
    ? '3.2rem'
    : p.size === "medium"
    ? '4rem'
    : p.size === "large"
    ? '6rem'
    : '6rem'
  };

`