
import { useCallback, useState } from "react"

import {
  ActionList,
  Button,
  Layout,
  Page,
  Popover,
} from "@shopify/polaris";

import {
  MobileVerticalDotsMajor
} from '@shopify/polaris-icons';

import PlanSection from './components/PlanSection'
import MatchingSection from './components/MatchingSection';
import { ReviewSection } from './components/ReviewSection';
import FeatureToggle from 'components/FeatureToggle/FeatureToggle';
import SplitTransferSection from './components/SplitTransferSection';
import DisplaySection from './components/DisplaySection';
import SyncSection from './components/SyncSection';
import { useAppSelector } from "hooks/hooks";
import CSVSection from "./components/CSVSection";
import TransferSection from "./components/TransferSection";

export default function Settings() {
  const [active, activeSet] = useState()
  const {
    env: {
      isAdmin
    },
    appData: {
      loggingEnabled,
    }
  } = useAppSelector(s => s)

  return (
    <Page
      title="Settings"
      secondaryActions={loggingEnabled
        ? [{content: 'Logs', url: '/logs' }]
        : []
      }
      primaryAction={isAdmin &&
        <Popover
          activator={<Button plain onClick={() => activeSet(x => !x)} icon={MobileVerticalDotsMajor}></Button>}
          active={active}
          onClose={() => activeSet(x => !x)}
        >
          <ActionList items={[{ content: 'Admin console', url: '/admin' }]} />
        </Popover>
      }
    >
      <Layout>
        <PlanSection />
        <TransferSection />
        <MatchingSection />
        <CSVSection />
        <DisplaySection />
        <FeatureToggle feature="split_transfers">
          <SplitTransferSection />
        </FeatureToggle>
        <SyncSection />
        <ReviewSection />
      </Layout>
    </Page>

  )
}
