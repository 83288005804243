import { createSlice } from '@reduxjs/toolkit';

interface Plan {
  name: string
  price: number
  description: string
  trial_days: number
  requirements: object
  app_features: string[]
  available: boolean
  feature_descriptions: {
    id: string
    content: string
  }[]
}

interface App {
  _init: boolean,
  state: {
    app_installed: boolean
    installed_at: number
    installed_at_first: number
    app_plan_date: number
    app_plan_name: string
  },
  plans: Plan[]
  features: {
    [key: string]: Object[]
  }
}

const initialState:App = {
  _init: false,
  state: {
    app_installed: false,
    installed_at: 0,
    installed_at_first: 0,
    app_plan_date: 0,
    app_plan_name: '',
  },
  plans: [],
  features: {},
};

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppState: (state, { payload = {} }) => {
      const {
        app_features = [],
        private_features = [],
        ...rest
      } = payload

      const features = [
        ...app_features,
        ...private_features
      ].reduce((features, f) => ({ ...features, [f]: true }), {})

      state._init = true
      state.state = rest
      state.features = features
    },
    setAppPlans: (state, { payload = {} }) => {
      state.plans = payload
    },
  },
});

export const { 
  setAppState, 
  setAppPlans, 
} = slice.actions;

export default slice.reducer;
