import { withFirebase } from 'providers/firebase'

import React, { useState, useCallback, useMemo } from 'react';

// import { TitleBar } from '@shopify/app-bridge-react';

import {
  Page,
  Filters,
  Card,
  ResourceList,
  ResourceItem,
  TextStyle,
} from '@shopify/polaris';


import { objectToArray, arrayHasValues } from 'utils/helpers'

import TagsField from 'components/TagsField/TagsField';
import SuppliersNewModal from './components/SuppliersNewModal'
import LocationAvatar from 'components/Location/LocationAvatar'
import { useAppSelector } from 'hooks/hooks';

function Suppliers({ history }) {
  const [taggedWith, taggedWithSet] = useState([]);
  const [queryValue, setQueryValue] = useState(null);

  const suppliers = useAppSelector(s => s.suppliers)

  const handleClearAll = useCallback(() => {
    taggedWithSet([])
    setQueryValue(null)
  }, []);

  const goToSupplier = useCallback((id) => {
    history.push(`/suppliers/${id}`)
  }, [history])

  const items = useMemo(() => {
    const filterRegex = new RegExp(queryValue, 'i');
    return objectToArray(suppliers)
      .filter(s => {
        if (taggedWith.length > 0 && queryValue) {
          return arrayHasValues(s.tags, taggedWith) && s.name.match(filterRegex)
        } else if (taggedWith.length > 0) {
          return arrayHasValues(s.tags, taggedWith)
        } else if (queryValue) {
          return s.name.match(filterRegex)
        } else {
          return true
        }
      })
      .map(s => {
        const address = `${s.address1 ? s.address1 : ''}${s.address2 ? ` ${s.address2}` : ''}`
        return {
          ...s,
          url: `/suppliers/${s.id}`,
          location: `${address ? `${address}, ` : ''}${s.city ? `${s.city}, ` : ''}${s.province || ''}`
        }
      })
      .sort((a, b) => (a.name > b.name) ? 1 : -1)
  }, [queryValue, suppliers, taggedWith])

  const supplierTags = useMemo(() => {
    let allTags = objectToArray(suppliers).map(s => s.tags || []).flat(1)
    return [...new Set(allTags)]
  }, [suppliers])

  const filters = [
    {
      key: 'tags',
      label: 'Tagged with',
      filter: (
        <TagsField
          value={taggedWith}
          onChange={taggedWithSet}
          suggestions={supplierTags}
        />
      ),
      shortcut: true,
    },
  ];


  const appliedFilters = taggedWith.length > 0
    ? [
      {
        key: 'tags',
        label: `Tagged with ${taggedWith.join(',')}`,
        onRemove: () => taggedWithSet([]),
      },
    ]
    : [];

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={setQueryValue}
      onQueryClear={() => setQueryValue(null)}
      onClearAll={handleClearAll}
    />
  );

  return (
    <Page 
    title="Suppliers" 
    primaryAction={
      <SuppliersNewModal 
        onNew={goToSupplier} 
        title={`Create a new supplier`} 
        isNew 
      />
    }
    >
      {/* <TitleBar title="Suppliers" /> */}
      <Card>
        <ResourceList
          resourceName={{ singular: 'supplier', plural: 'suppliers' }}
          items={items}
          renderItem={renderItem}
          filterControl={filterControl}
        />
      </Card>
    </Page>
  );

  function renderItem(item) {
    const { id, url, name, location, avatar } = item;
    const media = <LocationAvatar
      source={avatar && (avatar.src_thumb || avatar.src)}
      type="supplier"
      size="medium"
    />


    return (
      <ResourceItem id={id} url={url} media={media}>
        <h3>
          <TextStyle variation="strong">{name}</TextStyle>
        </h3>
        <div>{location}</div>
      </ResourceItem>
    );
  }
}


export default withFirebase(Suppliers)


