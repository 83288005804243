import { withFirebase } from 'providers/firebase'

import React, { useCallback, useState, useEffect } from 'react';

import createApp from '@shopify/app-bridge';

import { setupModalAutoSizing } from '@shopify/app-bridge-utils';

import { Modal } from '@shopify/app-bridge/actions';

import {
  Card,
  FormLayout,
  Stack,
  TextField,
  AppProvider
} from '@shopify/polaris';

import store from 'store'

import ImageUpload from 'components/ImageUpload/ImageUpload';

import { getQueryParam, generateId } from 'utils/helpers'
import Loader from 'components/Loader/Loader';
import { useAppSelector } from 'hooks/hooks';

function SuppliersForm(props) {
  const { shop: passedShop, host, db, supplierId, onChange } = props
  const [supplier, supplierSet] = useState({ id: generateId() })
  const [suppliers, suppliersSet] = useState({})
  const [loading, loadingSet] = useState(true)

  const queryId = getQueryParam('id')
  const shop = passedShop || getQueryParam('shop')

  const app = createApp({
    apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
    host
  })


  if (!loading) setupModalAutoSizing(app)

  const id = !['undefined', undefined, null].includes(queryId) ? queryId : supplierId

  const { isEmbedded } = useAppSelector(s => s.env)

  useEffect(() => {
    const suppliersRef = db.doc(`shops/${shop}/shop/suppliers`)
      .onSnapshot(s => {
        loadingSet(false)
        suppliersSet(s.data() || {})
      })
    return suppliersRef
  }, [db, shop]);


  useEffect(() => {
    if (id) {
      let supplier = suppliers[id] || {}
      supplierSet(supplier)
      store.set('supplier', supplier)
    } else {
      store.remove('supplier')
      loadingSet(false)
    }
  }, [id, supplierId, suppliers]);


  const handleChange = useCallback((key, val) => {

    let supplierData = { ...supplier, [key]: val }
    if (key === 'avatar') {
      let storedSupplier = store.get('supplier') || {}
      supplierData = { ...storedSupplier, [key]: val }
    }

    supplierSet(supplierData)

    store.set('supplier', supplierData)

    if (isEmbedded) {
      app.dispatch(Modal.data(supplierData))
    } else if (onChange) {
      onChange(supplierData)
    }
  }, [app, isEmbedded, onChange, supplier])

  const handleLoading = useCallback((state) => {
    app.dispatch(Modal.data({ _private: true, loading: state }));
  }, [app])

  const handleImageChange = useCallback((v) => {
    handleChange('avatar', v)
  }, [handleChange])

  useEffect(() => {
    app.dispatch(Modal.data({ _private: true, loading: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loader color="white"/>

  return (
    <AppProvider>
      <div style={{ backgroundColor: 'white' }}>
        <Card.Section>
          <Stack>
            <div style={{ marginRight: '2rem' }}>
              <ImageUpload
                avatar
                label="Avatar"
                storagePath={`shops/${shop}/suppliers`}
                image={supplier.avatar}
                onChange={handleImageChange}
                onLoading={handleLoading}
              />

            </div>
            <Stack.Item fill>
              <FormLayout>
                <TextField label="Supplier name" name="name" value={supplier.name} onChange={(v) => handleChange('name', v)} />
                <FormLayout.Group>
                  <TextField label="Email" value={supplier.email} onChange={(v) => handleChange('email', v)} type="email" />
                  <TextField label="Phone" value={supplier.phone} onChange={(v) => handleChange('phone', v)} />
                </FormLayout.Group>
              </FormLayout>

            </Stack.Item>
          </Stack>
        </Card.Section>
        <Card.Section title="Address">
          <FormLayout>
            <TextField label="Address line 1" value={supplier.address1} onChange={(v) => handleChange('address1', v)} />
            <TextField label="Address line 2" value={supplier.address2} onChange={(v) => handleChange('address2', v)} />
            <FormLayout.Group>
              <TextField label="City" value={supplier.city} onChange={(v) => handleChange('city', v)} />
              <TextField label="Postal/Zip code" value={supplier.zip} onChange={(v) => handleChange('zip', v)} />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField label="Province" value={supplier.province} onChange={(v) => handleChange('province', v)} />
              <TextField label="Country/Region" value={supplier.country} onChange={(v) => handleChange('country', v)} />
            </FormLayout.Group>

          </FormLayout>
        </Card.Section>
        <Card.Section title="Contact person">
          <FormLayout>
            <TextField label="Contact name" value={supplier.contact_name} onChange={(v) => handleChange('contact_name', v)} />
            <FormLayout.Group>
              <TextField label="Email" value={supplier.contact_email} onChange={(v) => handleChange('contact_email', v)} />
              <TextField label="Phone" value={supplier.contact_phone} onChange={(v) => handleChange('contact_phone', v)} type="phone" />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>

        {/* <Card.Section title="Tags">
      <TagsField
              value={supplier.tags}
              onChange={(v) => handleChange('tags', v)}
            />
      </Card.Section> */}

      </div>
    </AppProvider>
  );
}


export default withFirebase(SuppliersForm)


