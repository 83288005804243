import React, { useCallback,  useMemo, } from 'react';


import {
  Badge,
  Stack
} from '@shopify/polaris';

import styled from 'styled-components';
import { useHistory } from 'react-router';
import { textsTransfer } from 'refs/resource-names'
import { useAppSelector } from 'hooks/hooks';

function LinkedIndicator(props) {
  const {
    // transfer: {
    //   parent,
    //   child,
    //   name,
    //   status
    // } = {},
    size,
    mini,
  } = props

  const { 
    transfers: { open },
    transfer: {
      parent,
      child,
      name,
      status
    } = {}
  } = useAppSelector(s => s)

  const history = useHistory()

  const openIds = useMemo(() => open.map(t => t.id.toString()), [open])

  const childName = useMemo(() => parent ? name : child?.name, [child, name, parent])
  const parentName = useMemo(() => child ? name : parent?.name, [child, name, parent])

  const childProgress = useMemo(() => {
    return parent 
      ? status === 'complete' ? 'complete' : 'incomplete' 
      : openIds.includes((child?.id || '').toString()) ? 'incomplete' : 'complete' 
  }, [child?.id, openIds, parent, status])

  const path = useMemo(() => {
    let id = parent?.id || child?.id
    return `/transfers/${id}?returnTo=${window.location.pathname}`
  }, [child?.id, parent?.id])


  const gotoTransfer = useCallback((e) => {
    e.stopPropagation()
    history.push(path)
  }, [history, path])

  if (!parent && !child) return null

  return (
    <>
      {mini
        ? <MiniWrap onClick={gotoTransfer}>
          <Badge>
            <svg x="0px" y="0px" viewBox="0 0 100 100"><g><g><g><g><polygon points="73.6,42.2 71,39.7 82.5,28.2 71,16.7 73.6,14.1 87.7,28.2     "></polygon></g></g><g><g><polygon points="73.6,86 71,83.4 82.5,72 71,60.5 73.6,57.9 87.7,72     "></polygon></g></g><g><path d="M85.1,73.8H62.4c-5.5,0-10.6-2.7-13.7-7.3L37.8,50.1l10.9-16.4c3.1-4.6,8.2-7.3,13.7-7.3h22.7V30H62.4     c-4.3,0-8.3,2.1-10.6,5.7l-9.6,14.4l9.6,14.4c2.4,3.6,6.3,5.7,10.6,5.7h22.7V73.8z"></path></g></g><g><rect x="12.4" y="48.2" width="27.7" height="3.7"></rect></g></g></svg>
            {parent ? parentName : childName}
          </Badge>
        </MiniWrap>
        : <Stack vertical>
          <Stack wrap={false} spacing="extraTight" alignment="center">
            <Badge size={size}>{textsTransfer.internal.title}</Badge>
            <Stack.Item>
              <IconWrap>
                <svg x="0px" y="0px" viewBox="0 0 100 100"><g><g><g><g><polygon points="73.6,42.2 71,39.7 82.5,28.2 71,16.7 73.6,14.1 87.7,28.2     "></polygon></g></g><g><g><polygon points="73.6,86 71,83.4 82.5,72 71,60.5 73.6,57.9 87.7,72     "></polygon></g></g><g><path d="M85.1,73.8H62.4c-5.5,0-10.6-2.7-13.7-7.3L37.8,50.1l10.9-16.4c3.1-4.6,8.2-7.3,13.7-7.3h22.7V30H62.4     c-4.3,0-8.3,2.1-10.6,5.7l-9.6,14.4l9.6,14.4c2.4,3.6,6.3,5.7,10.6,5.7h22.7V73.8z"></path></g></g><g><rect x="12.4" y="48.2" width="27.7" height="3.7"></rect></g></g></svg>
              </IconWrap>
            </Stack.Item>
            <Stack vertical spacing="extraTight">
              <BadgeWrap onClick={gotoTransfer} active={parent}>
                <Badge status={child && 'success'} size={size} progress="complete">{parentName}</Badge>
              </BadgeWrap>
              <BadgeWrap onClick={gotoTransfer} active={child}>
                <Badge status={parent && 'success'} size={size} progress={childProgress}>{childName}</Badge>
              </BadgeWrap>
            </Stack>
          </Stack>
        </Stack>

      }
    </>
  );
}

const BadgeWrap = styled.div`
  ${props => props.active && 'cursor:pointer;'}
  &:hover {
    ${props => props.active && `
      text-decoration: underline;
      font-weight: 600 !important;
    `}
  }
  .Polaris-Badge--sizeSmall {
    line-height: 1.6rem;
  }
`
const IconWrap = styled.div`
  svg {
    fill: var(--p-icon);
    margin-top: 0.5rem;
    width: 5rem ;
  }
`

const MiniWrap = styled.span`

  svg {
    fill: var(--p-icon);
    width: 1.6rem;
    stroke: var(--p-icon);
    stroke-width: 4px;
    margin-right: 0.2rem;
    margin-top: 0.1rem;
  }

  &:hover {
    text-decoration: underline;
    font-weight: 600 !important;
  }

`


export default LinkedIndicator
