import './../style.scss'

import { withFirebase } from 'providers/firebase'

import { useState, useCallback, useMemo } from 'react';

import { ResourcePicker } from '@shopify/app-bridge-react';

import {
  Card,
  Banner,
  Stack,
  Button,
} from '@shopify/polaris';

import {
  BarcodeMajor,
} from '@shopify/polaris-icons';

import SearchField from './SearchField'
import InventoryItem from './InventoryItem'

import { Table, Tbody } from './styled-components'

import { objectToArray, focusInput } from 'utils/helpers';

import { gidToId, graphQlProductToInventoryItems } from 'utils/mutators'

import ShopifyScanner from './ShopifyScanner';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import useCodeMatcher from 'hooks/useCodeMatcher';
import { addMultiMatch, addScanError, removeMultiMatch, removeScanError, setDirtyState } from 'redux/slices/transfer-state';
import { setInventoryItem } from 'redux/slices/inventory';
import { addToLogs } from "redux/slices/app-data";
import { setTransferItems } from "redux/slices/transfer";

function Inventory() {
  const [code, codeSet] = useState('')
  const [lastScanned, lastScannedSet] = useState(null)
  const [timeout, timeoutSet] = useState(null)
  const [resourcePickerActive, resourcePickerActiveSet] = useState(false)
  const [resourcePickerQuery, resourcePickerQuerySet] = useState('')
  const [pickerRemovalWarning, pickerRemovalWarningSet] = useState(false)

  // const items = transfer.items
  
  const {
    env: { 
      isMobile
    },
    inventory: { 
      items: inventoryItems 
    },
    transferState: {
      matchErrors,
      multipleMatches,
    },
    transfer: {
      type
    },
    config: {
      transfer_items_add_to = 'top',
      default_adjustment_operation
    },
    transfer
  } = useAppSelector(s => s)

  const { 
    codeMatcher,
   } = useCodeMatcher()

  const dispatch = useAppDispatch()

  const items = useMemo(() => transfer.items, [transfer.items])

  const transferItems = useMemo(() => {
    return transfer_items_add_to === 'top' 
      ? objectToArray(items).reverse()
      : objectToArray(items)
  }, [items, transfer_items_add_to])
  
  const storeProduct = useCallback((product) => {
    graphQlProductToInventoryItems(product)
      .forEach(v => {
        if (!inventoryItems[v.id]) dispatch(setInventoryItem(v))
      })
  }, [dispatch, inventoryItems])
  
  const handleCodeChange = useCallback((v) => codeSet(v), [])

  const multipleMatchItems = useCallback((items) => {
    return items.map(({ id }) => ({ ...inventoryItems[id], id, }))
  }, [inventoryItems])

  // const isMobile = useMemo(() => /Mobi|Android/i.test(navigator.userAgent), [])

  const focusScanInput = useCallback(() => {
    if (!isMobile) focusInput('search_field')
  }, [isMobile])

  const setLastScanned = useCallback((id) => {
    if (timeout) clearTimeout(timeout)
    lastScannedSet(null)
    timeoutSet(null)
    timeoutSet(setTimeout(() => { lastScannedSet(id); timeoutSet(null) }, 10))
    timeoutSet(setTimeout(() => { lastScannedSet(null) }, 500))
  },[timeout])

  const openResourcePicker = useCallback((initialQuery = '') => {
    let query = typeof initialQuery === 'string' ? initialQuery : ''
    resourcePickerActiveSet(true)
    resourcePickerQuerySet(query)
  },[])

  const cancelResourcePicker = useCallback(() => {
    resourcePickerActiveSet(false)
    resourcePickerQuerySet('')
    focusScanInput()
  },[focusScanInput])

  const handleChange = useCallback((items) => {
    dispatch(setTransferItems(items))
    dispatch(setDirtyState(true))
  },[dispatch])

  const defaultOperation = useMemo(() => type === 'adjustment' ? default_adjustment_operation : 'add', [type, default_adjustment_operation])

  const addItem = useCallback((id, qty = 1, opp) => {
    const operation = opp || defaultOperation
    const item =  items[id]
      ? { ...items[id], qty: Number(items[id].qty) + qty  }
      : { id, qty, operation, index: Object.keys(items).length }
    try {
      dispatch(addToLogs(`Add Item: ${JSON.stringify(item)}}`))
    } catch (e) {}
    handleChange({ ...items, [id]: item  })
    setLastScanned(id)
    focusScanInput()
  }, [defaultOperation, dispatch, focusScanInput, handleChange, items, setLastScanned])

  const addItemsFromPicker = useCallback(({selection}) => {  
    const inventoryIds = selection.reduce((items, product) => ([
        ...items,
        ...product
            .variants
            .map(({inventoryItem}) => gidToId(inventoryItem.id))
      ]),[])

    const removedItems = Object.keys(items).map(id => inventoryIds.includes(id))

    if (removedItems.some(i => !i)) {
      pickerRemovalWarningSet(removedItems.filter(i => !i).length)
    }
    const newItems = inventoryIds.reduce((items, id) => {
      return items[id]
      ? items
      : { ...items, [id]: { qty: "1", operation:defaultOperation, index: Object.keys(items).length }  }
    },items)

    inventoryIds.forEach(id => {
      try {
        dispatch(addToLogs(`Add From Picker: ${JSON.stringify({id, ...newItems[id]})}`))
      } catch (e) {}
    })
    handleChange(newItems)
    cancelResourcePicker()
    selection.forEach(storeProduct)
  }, [items, handleChange, cancelResourcePicker, storeProduct, dispatch, defaultOperation])


  const handleMultipleMatchesCancel = useCallback((index) => {
    dispatch(removeMultiMatch(index))
    focusScanInput()
  }, [dispatch, focusScanInput])

  const handleMultipleItemsAdd = useCallback((item, index) => {
    const { id, qty, operation } = item
    addItem(id, qty, operation)
    handleMultipleMatchesCancel(index)
  }, [addItem, handleMultipleMatchesCancel])

  const handleErrorDismiss = useCallback((index) => {
    dispatch(removeScanError(index))
    focusScanInput()
  }, [dispatch, focusScanInput])


  const resourcePickerSelectedItems = useMemo(() => {
      return Object
          .keys(items)
          .filter(id => inventoryItems[id])
          .map(id => (
            {
              id: `gid://shopify/Product/${inventoryItems[id].pid}`,
              variants: [{ 
                id:`gid://shopify/ProductVariant/${inventoryItems[id].vid}`,
              }],
            }            
          ))
  }, [inventoryItems, items])

  const handleInventoryItemChange = useCallback((item) => {
    handleChange({ ...items, [item.id]: item })
  }, [items, handleChange])

  const handleRemoveItem = useCallback((id) => {
    const newItems = { ...items }
    delete newItems[id]
    handleChange(newItems)
  }, [items, handleChange])


  const matchAndAddInventory = useCallback((code, fromScaner = true) => {
    const time = new Date().toLocaleTimeString()
    try {
      dispatch(addToLogs(`Code - ${fromScaner ? "SCANNED" : "TYPED"}: ${code}`))
    } catch (e) {}

    const matches = codeMatcher(code)
    try {
      dispatch(addToLogs(`Matches(${matches.length}): ${JSON.stringify(matches)}}`))
    } catch (e) {}

    if (matches.length === 0) {
      if (fromScaner) {
        dispatch(addScanError({ code, time }))
      } else {
        openResourcePicker(code)
      }
    } else if (matches.length === 1) {
      const { id } = matches[0]
      addItem(id)
    } else {
      dispatch(addMultiMatch({ code, matches, time }))
    }

    codeSet('')
    focusScanInput()
  }, [addItem, codeMatcher, dispatch, focusScanInput, openResourcePicker])

  
  return (
    <Card title="Products">

      <Card.Section>
        <SearchField
          id="search_field"
          value={code}
          onChange={handleCodeChange}
          onScan={matchAndAddInventory}
          onBrowseProducts={openResourcePicker}
        />

        <ResourcePicker
          open={resourcePickerActive}
          initialQuery={resourcePickerQuery}
          resourceType="Product"
          selectMultiple={true}
          showVariants={true}
          showArchived={true}
          showArchivedBadge={true}
          showDraft={true}
          showDraftBadge={true}
          onCancel={cancelResourcePicker}
          onSelection={addItemsFromPicker}
          initialSelectionIds={resourcePickerSelectedItems}
        />
        <ShopifyScanner
          onScan={matchAndAddInventory}
          activator={
            <div style={{ marginTop: '1.6rem' }}>
              <Button icon={BarcodeMajor} fullWidth> Scan product</Button>
            </div>
          }
        />
        


        <div style={{ marginTop: '1.6rem' }}>
          <Stack vertical>
            { pickerRemovalWarning &&
              <Banner
                  title={`Unable to remove ${pickerRemovalWarning} items`}
                  onDismiss={() => pickerRemovalWarningSet(false)}
                  status="warning"
                >
                To prevent accidental removal items cannot be removed using the product picker. Remove items directly from the list below.
              </Banner>
            }
            {multipleMatches.map((m, i) => (
              <Banner
                onDismiss={() => handleMultipleMatchesCancel(i)}
                status="warning"
                key={i}
              >
                {m.time} - Multiple matches for {m.source || 'Scan'}<b>: {m.code}</b>. <br/>Select correct product:
                <div style={{ marginTop: '1rem' }}>
                  <Card>
                    <Table>
                      <Tbody>
                        {
                          multipleMatchItems(m.matches).map(item => (
                            <InventoryItem
                              key={item.id}
                              item={item}
                              hideControls
                              thumbSize="medium"
                              onClick={() => handleMultipleItemsAdd({...m, id: item.id}, i)}
                            />
                          ))
                        }
                      </Tbody>
                    </Table>
                  </Card>
                </div>
              </Banner>
            ))}
            {matchErrors.map((e, i) => (
              <Banner onDismiss={() => handleErrorDismiss(i)} status="critical" key={i}>
                <span style={{ marginRight: '0.8rem' }}>
                  {e.time} - {e.source || 'Scan'}<b>: {e.code}</b> could not be found
                </span>
                <Button onClick={() => openResourcePicker(e.code)} plain>Browse products</Button>
              </Banner>
            ))}
          </Stack>
        </div>
      </Card.Section>
      {/* <Card.Section>

      </Card.Section> */}
      {/* <Card.Section></Card.Section> */}

      <Table>
        <Tbody>
          {
            transferItems.map(item => (
              <InventoryItem
                key={item.id}
                item={item}
                active={item.id === lastScanned}
                onRemove={handleRemoveItem}
                onChange={handleInventoryItemChange}
              />
            ))
          }
        </Tbody>
      </Table>
    </Card>
  );
}

export default withFirebase(Inventory)

