import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locations: {},
  locations_retrieved: false,
  ui: {
    tags_transfer: [],
    tags_supplier: [],
    index_created: false,
  },
  counts: {},
  logs: [],
  loggingEnabled: false,
};

export const slice = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    setLocations: (state, { payload = {} }) => {
      state.locations = Object
        .values(payload)
        .filter(location => location.active)
        .reduce((locations, location) => ({ ...locations, [location.id]: location }),{})
      state.locations_retrieved = true
    },
    setUiData: (state, { payload = {} }) => {
      state.ui = payload || initialState.ui
    },
    updateTransferTags: (state, { payload = {} }) => {
      state.ui.tags_transfer = payload
    },
    updateSupplierTags: (state, { payload = {} }) => {
      state.ui.tags_supplier = payload
    },
    setCounts: (state, { payload = {} }) => {
      state.counts = payload
    },
    addToLogs: (state, { payload = {} }) => {
      if(state.loggingEnabled) {
        let timestamp = new Date().toUTCString()
        let log = `[${timestamp}] ${payload}`

        let storedLogs = sessionStorage.getItem('ac_logs')
        let logs = storedLogs ? JSON.parse(storedLogs) : []
        logs.push(log)
        sessionStorage.setItem('ac_logs', JSON.stringify(logs))
        // state.logs.push(log)
      }
    },
    setLoggingEnabled(state, { payload = {} }) {
      state.loggingEnabled = payload
    },
  },
});

export const {
  setLocations,
  setUiData,
  updateTransferTags,
  updateSupplierTags,
  setCounts,
  addToLogs,
  setLoggingEnabled,
} = slice.actions;

export default slice.reducer;
