import './style.scss'

import {
  Layout,
  Card,
  Stack,
  Button,
  DisplayText,
  List,
  SkeletonDisplayText,
  SkeletonBodyText,
  Banner,
} from "@shopify/polaris";


import { getQueryParam } from 'utils/helpers';
import styled from 'styled-components';


function PlansList(props) {
  const { 
    plans,
    planName,
    onSelectPlan,
    settingPlan,
  } = props

  const featureHighlight = getQueryParam('feature')

  return (
      <Layout>
        {plans.length === 0
          ? (
            <Layout.Section>
              <Stack distribution="fillEvenly">
                {[1, 2, 3].map(i => (
                  <Card key={i}>
                    <Card.Section subdued>
                      <Name><SkeletonDisplayText size="medium" /></Name>
                    </Card.Section>
                    <Card.Section>
                      <Name><SkeletonDisplayText size="extraLarge" /></Name>
                    </Card.Section>
                    <Card.Section><SkeletonBodyText /></Card.Section>
                  </Card>
                ))
                }
              </Stack>
            </Layout.Section>
          )
          : (
            <>
              {planName === 'VIP' &&
                <Layout.Section>
                  <Banner status="info" title="You are a VIP">
                    You are on our VIP plan. This is an exclusive plan granted only by the {process.env.REACT_APP_APP_NAME} team. If you change your plan you will lose VIP access and that might make you sad.
                  </Banner>
                </Layout.Section>
              }
              <Layout.Section>

                <div className="AC-Row Wrap Justify-Center">
                  {plans.map(plan => {
                    let isCurrentPlan = plan.name === planName
                    return (
                      <div
                        className={'AC-Col Col-12 Col-M-4 ' + (isCurrentPlan ? ' AC-Feature-Card__Active' : '')}
                        key={plan.name}
                      >
                        <Card>
                          <Card.Section subdued>
                            <Name>
                              <DisplayText size="small">{plan.name}</DisplayText>
                            </Name>
                          </Card.Section>
                          <Card.Section>
                            <Stack vertical alignment="center">
                              <PriceWrap>
                                <span>{plan.price > 0 ? '$' : ''}</span>
                                <Price>{plan.price > 0 ? plan.price : 'FREE'}</Price>
                              </PriceWrap>
                              {onSelectPlan &&
                              <div>
                                {isCurrentPlan
                                  ? <CurrentPlan>Current plan</CurrentPlan>
                                  : <Button
                                    primary
                                    onClick={() => onSelectPlan(plan.name)}
                                    outline={!plan.available}
                                    disabled={settingPlan || !plan.available}
                                    loading={settingPlan === plan.name}
                                  >
                                    { 
                                      !plan.available
                                        ? 'not available'
                                        : 'Choose this plan'
                                    }
                                  </Button>
                                }
                              </div>
                              }
                              { isCurrentPlan && !plan.available &&
                                <Banner
                                  title="Upgrade required"
                                  status="critical"
                                  >
                                    This plan is insufficent for your store. Upgrade imediatly to ensure your app continues to operate.
                                </Banner>
                              }
                              <Description>{plan.description}</Description>

                            </Stack>
                          </Card.Section>
                          <Card.Section title="Features">
                            <List>
                              {
                                (plan.feature_descriptions || []).map((f, i) => (
                                  <ListItem feature={f} key={i} featureHighlight={featureHighlight} />
                                ))}
                            </List>
                          </Card.Section>
                        </Card>
                      </div>
                    )
                  })}
                </div>
              </Layout.Section>
            </>
          )
        }
      </Layout>
  )
}

const ListItem = ({ feature, featureHighlight }) => {
  let highlight = featureHighlight && featureHighlight === feature.id
  return (
    <div className={`AC-Feature-Item ${highlight ? ' Active' : ''}`}>
      {feature.id === '_previous_plan'
        ? <span>{feature.content}</span>
        : <List.Item>{feature.content}</List.Item>
      }
    </div>
  )
}

const Price = styled.div`
  font-size: 50px;
  font-weight: normal;
  margin: -1.4rem 0.8rem 0rem;
  line-height: initial;
`
const PriceWrap = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: top;
  align-items: top;
`
const Name = styled.div`
  min-width: 120px;
  display: flex;
  justify-content: center;
  >div {
    width: 100%;
  }
`

const CurrentPlan = styled.div`
  padding: .5rem 0;
  font-size: 1.6rem;
  color: var(--p-action-primary);
  font-weight: 700;
  line-height: 2.4rem;
`

const Description = styled.div`
  text-align: center;
`


export default PlansList
