import { withFirebase } from 'providers/firebase';

import { useCallback, useState, useEffect } from 'react';

import {
  ResourceItem,
  TextStyle,
  Stack,
  Badge,
  Button,
  Collapsible,
} from '@shopify/polaris';



import styled from 'styled-components'
import { timeAgo } from 'utils/helpers'
import { useAppDispatch } from 'hooks/hooks';
import { setEmulating } from 'redux/slices/env';
import { useNavigate } from '@shopify/app-bridge-react';
import { DateTime } from 'luxon'

function ShopRenderItem ({db, shop, item}) {
  const { 
    id, 
    url, 
    shop_name, 
    myshopify_domain,
    app_plan_name, 
    app_plan_active,
    seen_last,
    installed_at,
  } = item;
  const [active, activeSet] = useState(false)
  const [shopInfo, shopInfoSet] = useState(null)
  const [renderKey, renderKeySet] = useState(id)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const emulateShop = useCallback((shop) => {
    dispatch(setEmulating(shop))
    navigate('/')
  },[dispatch, navigate])

  const updateRender = useCallback(() => renderKeySet(`${id}-${Number(new Date())}`),[id])

  const toggleActive = useCallback(() => {
    activeSet(x => !x)
    updateRender()
  },[updateRender])


  useEffect(() => {
    if (!shopInfo && active) {
      db
        .doc(`shops/${myshopify_domain}/shop/shop`)
        .get()
        .then(d => {
          shopInfoSet(d.data())
          updateRender()
        })
    }
  }, [active, db, shopInfo, myshopify_domain, updateRender]);

  return (
    <ResourceItem 
      id={id} 
      url={url} 
      key={renderKey} 
      onClick={toggleActive}
    >
      <Stack alignment="center">
        <Stack.Item>
          <TitleWrapper>
            <TextStyle variation="strong">{shop_name}</TextStyle>
          </TitleWrapper>
          {/* {!active && <div>Active: {timeAgo(seen_last.toMillis())} </div>} */}
          
        </Stack.Item>
        <Stack.Item fill>
        {!active && myshopify_domain}
        </Stack.Item>
        {!active && 
          <Badge
            status={app_plan_active ? 'success' : ''}
            progress={app_plan_active ? 'complete' : 'incomplete'}
          >
            { app_plan_name || 'No plan' }
          </Badge>
        }
        <Button onClick={() => emulateShop(myshopify_domain)} size="slim">Emulate</Button>
      </Stack>
      <Collapsible open={active}>
        <DetailsWapper>
          <TextStyle variation="subdued">Shop domain:</TextStyle> {myshopify_domain}
          <br/>
          <TextStyle variation="subdued">Plan:</TextStyle> {app_plan_name}
          { seen_last && <>
            <br/>
            <TextStyle variation="subdued">Seen:</TextStyle> {DateTime.fromMillis(seen_last.toMillis()).toLocaleString(DateTime.DATE_MED)} ({timeAgo(seen_last.toMillis())}) 
          </> }
          { installed_at && <>
            <br/>
            <TextStyle variation="subdued">Installed:</TextStyle> {installed_at && DateTime.fromMillis(installed_at.toMillis()).toLocaleString(DateTime.DATE_MED)} ({timeAgo(installed_at.toMillis())})
          </> }
          { shopInfo && 
            <>
              <br/>
              <TextStyle variation="subdued">Owner:</TextStyle> {shopInfo.shop_owner}
              <br/>
              <TextStyle variation="subdued">Country:</TextStyle> {shopInfo.city}, {shopInfo.country_name}
              <br/>
              <TextStyle variation="subdued">Shopify plan:</TextStyle> {shopInfo.plan_display_name}
              <br/>
            </>
          }
        </DetailsWapper>
      </Collapsible>
    </ResourceItem>
  );
}

const TitleWrapper = styled.div`
  width: 30rem;
`
const DetailsWapper = styled.div`
  line-height: 1.75;
`

export default withFirebase(ShopRenderItem)