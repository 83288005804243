import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import Papa from "papaparse";
import useCodeMatcher from "./useCodeMatcher";
import { setTransferItems } from 'redux/slices/transfer';
import { addMultipleMatches, addMatchErrors, setDirtyState } from 'redux/slices/transfer-state';



function useImportFromCSV() {
  const dispatch = useAppDispatch() 
  const {
    transfer,
    config: {
      transfer_items_add_to
    }
  } = useAppSelector(s => s)

  const { items } = transfer

  const {
    csv_limit = 100,
    csv_include_zero_qty,
    csv_default_operation = 'add',
    csv_code_priority = 'sku'
  } = useAppSelector(s => s.config)

  const {
    codeMatcherMultiple
  } = useCodeMatcher()


  const addItemsToTransfer = useCallback((itemsToAdd) => {
    const time = new Date().toLocaleTimeString()

    let codes = itemsToAdd.map(({ code }) => code)
    const results = codeMatcherMultiple(codes)
      .map((item, index) => ({ ...item, ...itemsToAdd[index] }))
      .map(item => item.matches.length === 0 && item.inventory_id
        ? { ...item, matches: [{ id: item.inventory_id }] }
        : item
      )

    let newItems = { ...items }
    const errors = []
    const multipleMatches = []
    const itemsLength = Object.keys(items || {}).length;
    const sortedResults = (transfer_items_add_to === 'top' ? results.reverse() : results)
    
    sortedResults.forEach((result, i) => {
      const { matches, qty = 1, operation } = result
      if (matches.length === 0) {
        errors.push({ ...result, time })
      } else if (matches.length === 1) {
        const { id } = matches[0]
        const index = items[id]?.index || itemsLength + i
        newItems = {
          ...newItems,
          ...(newItems[id]
            ? { [id]: { ...newItems[id], qty: Number(qty) + Number(newItems[id].qty), index } }
            : { [id]: { id, qty: Number(qty) + Number(items[id]?.qty || 0), operation, index } }
          )
        }
      } else {
        multipleMatches.push({ ...result, time })
      }
    })
    dispatch(setDirtyState(true))
    dispatch(setTransferItems(newItems))
    dispatch(addMultipleMatches(multipleMatches))
    dispatch(addMatchErrors(errors))

  }, [codeMatcherMultiple, dispatch, items, transfer_items_add_to])


  const importFromCSV = useCallback((rawData, delimiter = "") => {
    const isAdjustment = transfer.type === 'adjustment'

    const clean = (str) =>
      typeof str === 'string'
        ? str.trim().replace(/[',]/g, '')
        : str

    const code = (item) => clean(
      item.inventory_id
        || csv_code_priority === 'sku'
        ? (item.sku || item.barcode)
        : (item.barcode || item.sku)
    )


    const source = (item) => item.inventory_id
      ? 'INVENTORY ID'
      : csv_code_priority === 'sku'
        ? item.sku ? "SKU" : "BARCODE"
        : item.barcode ? "BARCODE" : "SKU"


    const { data } = Papa.parse(
      rawData,
      {
        header: true,
        delimiter,
        skipEmptyLines: true,
        transformHeader: h => h.toLowerCase().trim()
      }
    )

    const items = data
      .map(item => ({
        ...item,
        operation: (item.operation || '').toLowerCase()
      }))
      .map(item => ({
        ...item,
        inventory_id: clean(item.inventory_id),
        code: code(item),
        source: source(item),
        operation: isAdjustment && ['add', 'subtract', 'set'].includes(item.operation)
          ? item.operation
          : isAdjustment ? csv_default_operation : 'add',
        qty: Number(clean(item.quantity) || clean(item.qty) || 0),
        csv: true
      }))
      .filter(({ code, inventory_id }) => inventory_id || code)
      .filter(({ qty, operation }) => qty > 0
        || csv_include_zero_qty
        || (operation === 'set' && ![null, undefined].includes(qty))
      )


    addItemsToTransfer(items.slice(0, csv_limit))

  }, [addItemsToTransfer, csv_code_priority, csv_default_operation, csv_include_zero_qty, csv_limit, transfer.type])

  return {
    importFromCSV,
  }
}



export default useImportFromCSV

