import { withFirebase } from 'providers/firebase'

import { useCallback, useEffect, useState } from "react"

import {
  Button,
  Card,
  Layout,
  SettingToggle,
  Stack
} from "@shopify/polaris";


import useJob from 'hooks/useJobs';
import { dbPaths } from 'refs/db-paths';
import { DateTime } from 'luxon'

function SyncSection({ db, shop }) {
  const [loading, setLoading] = useState(true)
  const [syncing, setSyncing] = useState(false)
  const [building, setBuilding] = useState(false)
  const [syncInfo, syncInfoSet] = useState({})

  const { createJob, jobCodes } = useJob()

  const runSync = useCallback((latest) => {
    if (latest) setSyncing(true) 
    else setBuilding(true)
    createJob(jobCodes.JOB_SYNC_PRODUCTS, { latest })
    createJob(jobCodes.JOB_SYNC_LOCATIONS)
  }, [createJob, jobCodes])

  useEffect(() => {
    let dbRef = db
      .doc(dbPaths.appData(shop, 'sync'))    
      .onSnapshot(s => {
        let data = s.data() || {}
        syncInfoSet(data)
        setSyncing(data.inventory_index_running)
        setBuilding(data.inventory_index_building)
        setLoading(false)
      })
    return dbRef
  }, [db, shop]);

  return (
    <Layout.AnnotatedSection title="Indexes" >

      <Card
          primaryFooterAction={{
            content: 'Sync latest changes',
            onAction:() => runSync(true),
            loading: syncing && !building,
            disabled: loading || building
        }}
        secondaryFooterActions={[{
          content: 'Rebuild indexes',
          onAction:() => runSync(false),
          loading: building,
          disabled: loading || syncing
        }]}
      >
        <Card.Section title="Inventory indexes">
              Sync latest changes to retrieve any product changes made since the last index update. 
              <br/>
              Rebuilding indexes is generally not required as we maintain a live sync with your store. Use only if products are scanning incorrectly or directed by support.
              <br/>
              <br/>
              { loading 
                ? <i>checking index status...</i>
                : 
                <span>
                  Index updated: { 
                  syncing
                    ? building 
                      ? <i>building indexes...</i> 
                      : <i>syncing latest changes...</i>
                    : syncInfo.inventory_index_updated 
                      ? DateTime.fromISO(syncInfo.inventory_index_updated).toLocaleString(DateTime.DATETIME_MED)
                      : <i>never</i>
                    }
                </span>
              }
              { 
                syncInfo.inventory_index_built &&
                <span>
                  <br/>
                  Index built: {
                    building 
                    ? <i>building indexes...</i>
                    : DateTime.fromISO(syncInfo.inventory_index_built).toLocaleString(DateTime.DATETIME_MED)
                  }
                </span>
              }
        </Card.Section>
        {/* <Card.Section>
          </Card.Section> */}
      </Card>
      {/* <SettingToggle
        action={{ 
          content: loading ? 'checking index' : 'Rebuild index', 
          onAction: runSync, 
          loading: running,
          disabled: loading 
        }}
        enabled={false}
      >
        Rebuild inventory indexes. Generally not required as app maintains live sync. Use if products are not scanning correctly.
        <br/>
        <br/>
        { loading 
          ? <i>checking index status...</i>
          : 
          <span>
            Index updated: { 
            running
              ? <i>syncing latest changes...</i>
              : syncInfo.inventory_index_updated 
                ? DateTime.fromISO(syncInfo.inventory_index_updated).toLocaleString(DateTime.DATETIME_MED)
                : <i>never</i>
              }
          </span>
        }
        
        
        { 
          syncInfo.inventory_index_built &&
          <span>
            <br/>
            Index built: {DateTime.fromISO(syncInfo.inventory_index_built).toLocaleString(DateTime.DATETIME_MED)}
          </span>
        }
      </SettingToggle> */}

    </Layout.AnnotatedSection>
  )
}


export default withFirebase(SyncSection)