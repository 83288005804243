import { withFirebase } from 'providers/firebase'

import { useEffect } from 'react';

import { setAppState } from 'redux/slices/app';
import { setCounts, setLocations, setLoggingEnabled, setUiData } from 'redux/slices/app-data';
import { setConfig } from 'redux/slices/config';
import { setInventoryIndex } from 'redux/slices/inventory';
import useIndexedDb from "hooks/useIndexedDb";
import { mapQuerySnapshot } from 'utils/helpers';
import { setSuppliers } from 'redux/slices/suppliers';
import { dbPaths, rtdbPaths } from 'refs/db-paths';
import { setOpenTransfers } from 'redux/slices/transfers';
import { getRequestShop } from 'redux/slices/env';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';

const AppData = (props) => {
  const { db, auth, rtdb } = props
  const indexedDb = useIndexedDb()
  const dispatch = useAppDispatch();
  const { hasSessionStorage, hasAuthenticated } = useAppSelector(s => s.env)
  const shop = useAppSelector(getRequestShop)

  useEffect(() => {
    if (hasAuthenticated) {
      db.doc(dbPaths.appFeatures(shop))
        .get()
        .then(s => {
          dispatch(setAppState(s.data()))
        })

      const configRef = db.doc(dbPaths.settingsConfig(shop))
        .onSnapshot(s => {
          dispatch(setConfig(s.data()))
          dispatch(setLoggingEnabled(s.data()?.logging_enabled || false))
        })

      const uiDataRef = db.doc(dbPaths.appData(shop, 'ui'))
        .onSnapshot(s => {
          dispatch(setUiData(s.data()))
        })

      const countsRef = db.doc(dbPaths.appCounts(shop))
        .onSnapshot(s => {
          dispatch(setCounts(s.data() || {}))
        })

    const inventoryIndexRef = rtdb.ref(rtdbPaths.inventoryIndex(shop))
      inventoryIndexRef.on('value', (s) => {
        const index = s.val() || {}
        dispatch(setInventoryIndex(index))
        if (hasSessionStorage) indexedDb.indexes.put({ name: 'inventory_index', index })
      })

      // const locationsRef = db.doc(dbPaths.shopLocations(shop))
        // .onSnapshot(s => {
      db.doc(dbPaths.shopLocations(shop))
        .get()
        .then(s => {
          dispatch(setLocations(s.data() || {}))

        })

      const suppliersRef = db.doc(dbPaths.suppliers(shop))
        .onSnapshot(s => {
          dispatch(setSuppliers(s.data() || {}))
        })

      const transfersRef = db
        .collection(dbPaths.transfers(shop))
        .where('status', 'not-in', ['complete','canceled'])
          .onSnapshot(s => {
            dispatch(setOpenTransfers(mapQuerySnapshot(s)))
          })

      if (hasSessionStorage) {
        try {
          indexedDb.indexes.get('inventory_index')
            .then(({ index } = {}) => {
              dispatch(setInventoryIndex(index || {}))
            })

        } catch (error) {
          console.error(error);
        }
      }
      const unSubscribe = () => {
        configRef()
        countsRef()
        uiDataRef()
        suppliersRef()
        transfersRef()
        inventoryIndexRef.off()
      }

      return unSubscribe
    }
  }, [db, shop, auth, dispatch, rtdb, hasSessionStorage, indexedDb.indexes, hasAuthenticated])


  return null
}

export default withFirebase(AppData)