import { withFirebase } from "providers/firebase";

import React, { useEffect, useState, useRef } from "react"

import {
  AppProvider,
  Card,
  DisplayText,
} from "@shopify/polaris";

import createApp from '@shopify/app-bridge';


import { useParams } from "react-router";
import useAxios from "hooks/useAxios";
import { setupModalAutoSizing } from "@shopify/app-bridge-utils";
import Loader from "components/Loader/Loader";
import styled from "styled-components";


function Article({ host, id, active }) {
  const [article, articleSet] = useState({})
  const [loading, loadingSet] = useState(true)
  // const { id } = useParams()
  const { axios } = useAxios()

  const container = useRef()

  const app = createApp({
    apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
    host
  })


  useEffect(() => {
    if (article.body && container.current) {
      container.current.firstChild.innerHTML = article.body
      setupModalAutoSizing(app)
      let images = [...document.getElementsByTagName('img')]
      images.forEach(image => {
        image.addEventListener('load', () => setupModalAutoSizing(app), false)
      })
    }
  }, [app, article.body]);

  useEffect(() => {
    if (!active) return
    axios
      .get(`/api/article/${id}`)
      .then(res => {
        articleSet(res)
        loadingSet(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, active]);


  if (loading) return <Loader color="white" />

  return (
    <>
      <style>{`body{
        background-color: transparent !important;
        min-height: 200px;
        }`}</style>
      <Card>
        <Card.Section>    
        <DisplayText size="large">{article.title}</DisplayText>
        </Card.Section>
        <Card.Section>
          <div ref={container}>
            <ArticleContainer  />
          </div>
        </Card.Section>
      </Card>
    </>
  )
}

export default withFirebase(Article)

const ArticleContainer = styled.div`
  &>:not(:first-child) {
    margin-top: 1rem;
  }

  h1 {
    font-size: 2.625rem;
    font-weight: 600;
    margin-bottom: 1.6em;
  }
  h2 {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 0.6em;
  }
  h3 {
    font-size: 1.625rem;
    font-weight: 500;
  }
  h4 {
    font-size: 1.25rem;
  }
  p {
    margin-bottom: 1em;
    line-height: 1.5;
  }

  a {
    color: #008060;
  }
  ol {
    list-style-type: none;
    counter-reset: none;
    padding-left: 2em;
    margin-top: 0.3125em;
    margin-bottom: 1em;
    line-height: 1.5;
    /* font-size: 1rem; */

    &>li{
      position: relative;
      padding-left: 1.5625em;
      counter-increment: chapter;
      margin-bottom: 4px;

      ::before {
        content: counter(chapter, decimal) ". ";
        font-weight: 700;
        position: absolute;
        top: 1px;
        left: 0;
        min-width: 32px;
      }
    }
  }

  ul {
    padding-left: 3.25em;
    /* padding-left: 2em; */
    list-style: disc outside;
    margin-top: 0.3125em;
    margin-bottom: 1em;
    line-height: 1.5;

    li {
      padding-left: 5px;
      margin-bottom: 4px;
      ::marker {
        unicode-bidi: isolate;
        font-variant-numeric: tabular-nums;
        text-transform: none;
        text-indent: 0px !important;
        text-align: start !important;
        text-align-last: start !important;
      }
    }
  }

  img {
    max-width: 100%;
  }
`