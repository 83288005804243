import { withFirebase } from 'providers/firebase'

import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Card,
  FormLayout,
  TextField,
} from '@shopify/polaris';

import ScanGuard from 'components/ScanGuard/ScanGuard';

import TagsField from 'components/TagsField/TagsField';

import { addTransferSearchFields, tagsToMap } from 'utils/helpers'
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { setTransferDetail } from "redux/slices/transfer";
import { updateTransferTags } from "redux/slices/app-data";
import { dbPaths } from "refs/db-paths";
import { Loading } from "@shopify/app-bridge-react";

function Details({ db, shop, autoSave }) {
  const [isDirty, setIsDirty] = useState(false)
  const [saving, setSaving] = useState(false)

  const {
    config,
    transfer,
    appData: {
      ui: {
        tags_transfer = []
      }
    }
  } = useAppSelector(s => s)

  const dispatch = useAppDispatch()

  const handleSave = useCallback(async (transferToSave) => {
    if (!autoSave) return

    setSaving(true)

    await db
      .doc(dbPaths.transfers(shop, transfer.id))
      .set(addTransferSearchFields(transferToSave), { merge: true })

    setSaving(false)
  }, [autoSave, db, shop, transfer.id])

  const handleBlur = useCallback(() => {
    if (isDirty) {
      handleSave(transfer)
      setIsDirty(false)
    }
  }, [handleSave, isDirty, transfer])

  const handleChange = useCallback((key, val) => {
    setIsDirty(true)
    dispatch(setTransferDetail({ [key]: val }))
  }, [dispatch])


  const handleTagsChange = useCallback(async (tags) => {
    dispatch(setTransferDetail({ tags }))

    const newTags = [...new Set([...tags_transfer, ...tags])]

    dispatch(updateTransferTags(newTags))

    db.doc(dbPaths.appData(shop, 'ui'))
      .set(
        { tags_transfer: newTags },
        { merge: true }
      )
    handleSave({ ...transfer, tags })

  }, [db, dispatch, handleSave, shop, tags_transfer, transfer])


  const showShippingDetails = useMemo(() => {
    return config.show_shipping_details === "all"
      || (config.show_shipping_details === "external" && transfer.type === "external")
  }, [config.show_shipping_details, transfer.type])

  return (
    <>
      {saving && <Loading />}
      {showShippingDetails &&
        <Card>
          <Card.Header title="Shipment details"></Card.Header>
          <Card.Section >
            <FormLayout>
              <ScanGuard
                label="Estimated arrival"
                component={TextField}
                value={transfer.shipping_date}
                onBlur={handleBlur}
                type="date"
                onChange={(v) => handleChange('shipping_date', v)}
              />
              <ScanGuard
                label="Tracking"
                component={TextField}
                value={transfer.shipping_tracking}
                onChange={(v) => handleChange('shipping_tracking', v)}
                onBlur={handleBlur}
              />
              <ScanGuard
                label="Shipping carrier"
                component={TextField}
                value={transfer.shipping_carrier}
                onChange={(v) => handleChange('shipping_carrier', v)}
                onBlur={handleBlur}
              />
            </FormLayout>
          </Card.Section>
        </Card>
      }
      <Card subdued>
        <Card.Header title="Additional details">
        </Card.Header>
        {/* <Card.Section title="Reference"> */}
        <Card.Section>
          <ScanGuard
            label="Reference"
            component={TextField}
            value={transfer.reference}
            onBlur={handleBlur}
            onChange={(v) => handleChange('reference', v)}
          />
          {/* </Card.Section> */}
          {/* <Card.Section title="Notes"> */}
          <ScanGuard
            label="Notes"
            component={TextField}
            value={transfer.note}
            multiline={3}
            onChange={(v) => handleChange('note', v)}
            onBlur={handleBlur}
          />
        </Card.Section>
        <Card.Section title="Tags">
          <TagsField
            value={transfer.tags}
            onChange={handleTagsChange}
            tagsKey="tags_transfer"
            placeholder="Supplier, Order, Stocktake"
            onBlur={handleBlur}
          />
        </Card.Section>
      </Card>

    </>
  );
}


export default withFirebase(Details)


