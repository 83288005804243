
import {
  Badge,
  Spinner,
} from '@shopify/polaris';

import styled from 'styled-components';

import { store } from "../redux/store";

export function StatusBadge({ status }) {
  switch (status) {
    case 'processing': return (
      <Badge status="info">
        <LoadingWrapper>
          <Spinner accessibilityLabel="Spinner example" size="small" />
          <div>Transferring</div>
        </LoadingWrapper>
        
      </Badge>
    )
    case 'draft': return (
      <Badge  progress="incomplete" status="warning">Draft</Badge>
    )
    case 'complete': return <Badge status="default" progress="complete">Complete</Badge>
    case 'canceled': return <Badge status="default"><i>Canceled</i></Badge>
    case 'error': return <Badge status="critical">Error</Badge>
    default:
      return null;
  }
}
const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;

  .Polaris-Spinner {
    /* margin-top: -0.1rem; */
    margin-bottom: -0.3rem;
    margin-left: -0.3rem;
    margin-right: 0.6rem;

  }
`

export function formatMoney (amount) {
  const { config:{ money_format } = {} } = store.getState()

  function formatMoney (cents, format) {
    if (typeof cents == 'string') { 
      if (cents.includes('.') && cents.split('.')[1].length === 1) {
        cents = `${cents}0`
      }
      cents = cents.replace('.',''); 
    }
    let value = '';
    const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
    const formatString = (format || "${{amount}}");
  
    function defaultOption(opt, def) {
       return (typeof opt == 'undefined' ? def : opt);
    }
  
    function formatWithDelimiters(number, precision, thousands, decimal) {
      precision = defaultOption(precision, 2);
      thousands = defaultOption(thousands, ',');
      decimal   = defaultOption(decimal, '.');
  
      if (isNaN(number) || number == null) { return 0; }
  
      number = (number/100.0).toFixed(precision);
  
      const parts   = number.split('.'),
          dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands),
          cents   = parts[1] ? (decimal + parts[1]) : '';
  
      return dollars + cents;
    }
  
    switch(formatString.match(placeholderRegex)[1]) {
      case 'amount':
        value = formatWithDelimiters(cents, 2);
        break;
      case 'amount_no_decimals':
        value = formatWithDelimiters(cents, 0);
        break;
      case 'amount_with_comma_separator':
        value = formatWithDelimiters(cents, 2, '.', ',');
        break;
      case 'amount_no_decimals_with_comma_separator':
        value = formatWithDelimiters(cents, 0, '.', ',');
        break;
      case "amount_with_apostrophe_separator":
        value = formatWithDelimiters(cents, 2, "'", ".");
        break;
      default: value = '-'
    }
  
    return formatString.replace(placeholderRegex, value);
  };
  return formatMoney(amount, money_format)
  // return templateString(money_format, { amount })
}
