import React, { useCallback, useState, useMemo, useEffect } from 'react';

import {
  Filters,
  TextField,
  ChoiceList,
  DatePicker,
  Button,
  ActionList,
  ActionMenu,
  Icon,
  Popover
} from '@shopify/polaris';

import {
  LockMajor,
} from '@shopify/polaris-icons';

import TagsField from 'components/TagsField/TagsField'

import { objectToArray } from 'utils/helpers'
import { textsTransfer } from 'refs/resource-names'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { removeAllFilters, removeFilter, selectFilterLabels, selectHasFilters, setFilter, setFilterQuery } from 'redux/slices/transfers';
import useFeatures from "hooks/useFeatures";
import useExportToCSV from "hooks/useExportToCSV";

export default function FiltersControl(props) {
  const currentDate = new Date()
  const [{ month, year }, setDate] = useState({ month: currentDate.getMonth(), year: currentDate.getFullYear() });

  const [referenceInput, referenceInputSet] = useState('');
  const [query, querySet] = useState('');
  const [queryTimeout, queryTimeoutSet] = useState(null);
  const [referenceTimeout, referenceTimeoutSet] = useState(null);
  const [exportActive, exportActiveSet] = useState(false);
  const [actionListKey, setActionListKey] = useState(0);

  const {
    suppliers,
    appData: {
      locations,
    },
    transfers: {
      filters,
      filterDefs
    }
  } = useAppSelector(s => s)

  const filterLabels = useAppSelector(selectFilterLabels)
  const hasFilters = useAppSelector(selectHasFilters)

  const { hasFeature } = useFeatures()

  const { exportBulkTransfersToCSV } = useExportToCSV()

  const dispatch = useAppDispatch()

  const handleClearAll = useCallback(() => dispatch(removeAllFilters()), [dispatch]);

  const handleFilterSet = useCallback((key, value) => dispatch(setFilter({ key, value })), [dispatch])

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const handleRemoveFilter = useCallback((key) => {
    dispatch(removeFilter(key))
    referenceInputSet('')
  }, [dispatch])


  const handleDateRangeChange = useCallback((dateRange) => {
    dispatch(setFilter({
      key: 'time_executed',
      value: `${dateRange.start.toISOString()},${dateRange.end.toISOString()}`
    }))
  }, [dispatch]);

  const dateRangeValue = useMemo(() => {
    const now = new Date()
    const [start, end] = filters.time_executed
      ? filters.time_executed.split(',').map(x => x ? new Date(x) : new Date())
      : [now, now]

    return { start, end }
  }, [filters.time_executed])


  const supplierOptions = useMemo(() => objectToArray(suppliers).map(x => ({ label: x.name, value: x.id })), [suppliers]);

  const locationOptions = useMemo(() => objectToArray(locations).map(x => ({ label: x.name, value: x.id })), [locations]);

  const typeOptions = useMemo(() => ['internal', 'external', 'adjustment'].map(x => ({ label: textsTransfer[x].name, value: x })), []);

  const handleReferenceChange = useCallback((value) => {
    if (referenceTimeout) clearTimeout(referenceTimeout)
    referenceInputSet(value)
    referenceTimeoutSet(
      setTimeout(() => {
        handleFilterSet('reference', value)
      }, 1000)
    )
  }, [handleFilterSet, referenceTimeout])

  const filterFields = useMemo(() => {
    return {
      tags_map: {
        ...filterDefs.tags_map,
        filter: (
          <TagsField
            value={filters.tags_map}
            onChange={v => handleFilterSet('tags_map', v)}
            tagsKey="tags_transfer"
          />
        ),
      },
      ...(
        supplierOptions.length > 0
          ? {
            origin: {
              ...filterDefs.origin,
              filter: (
                <ChoiceList
                  selected={[filters.origin]}
                  onChange={v => handleFilterSet('origin', v[0])}
                  choices={supplierOptions}
                />
              )
            }
          }
          : {}
      ),
      locations: {
        ...filterDefs.locations,
        filter: (
          <ChoiceList
            selected={[filters.locations]}
            onChange={v => handleFilterSet('locations', v[0])}
            choices={locationOptions}
          />
        )
      },
      reference: {
        ...filterDefs.reference,
        filter: (
          <TextField
            value={referenceInput}
            onChange={handleReferenceChange}
          />
        )
      },
      type: {
        ...filterDefs.type,
        filter: (
          <ChoiceList
            selected={[filters.type]}
            onChange={v => handleFilterSet('type', v[0])}
            choices={typeOptions}
          />
        )
      },
      time_executed: {
        ...filterDefs.time_executed,
        filter: (
          <DatePicker
            month={month}
            year={year}
            onChange={handleDateRangeChange}
            onMonthChange={handleMonthChange}
            selected={dateRangeValue}
            allowRange
          />
        )
      }
    }
  }, [dateRangeValue, filterDefs.locations, filterDefs.origin, filterDefs.reference, filterDefs.tags_map, filterDefs.time_executed, filterDefs.type, filters.locations, filters.origin, filters.tags_map, filters.type, handleDateRangeChange, handleFilterSet, handleMonthChange, handleReferenceChange, locationOptions, month, referenceInput, supplierOptions, typeOptions, year]);

  const filtersOptions = useMemo(() => objectToArray(filterFields, undefined, 'key').filter(f => !f.hide), [filterFields]);

  const appliedFilters = useMemo(() => {
    return Object
      .entries(filters)
      .map(([key, value]) => ({
        ...filterFields[key],
        key,
        value,
        label: filterLabels[key],
        onRemove: handleRemoveFilter
      })
      )
      .filter(f => f.value)
  }, [filterFields, filterLabels, filters, handleRemoveFilter]);


  const handleQuerySet = useCallback((query) => {
    if (queryTimeout) clearTimeout(queryTimeout)
    querySet(query)
    queryTimeoutSet(
      setTimeout(() => {
        dispatch(setFilterQuery(query))
      }, 1000)
    )
  }, [dispatch, queryTimeout])

  const handleExportToCSV = useCallback((format) => {
    exportBulkTransfersToCSV(format)
    setActionListKey(actionListKey + 1)
  }, [actionListKey, exportBulkTransfersToCSV])

  const handleQueryClear = useCallback(() => {
    querySet('')
    dispatch(setFilterQuery(''))
  }, [dispatch])

  return (
    <Filters
      queryPlaceholder="Transfer ID"
      queryValue={query}
      onQueryChange={handleQuerySet}
      onQueryClear={handleQueryClear}
      filters={filtersOptions}
      appliedFilters={appliedFilters}
      onClearAll={handleClearAll}
    >

      {hasFilters &&
        <div style={{ marginLeft: '8px' }}>
          <Popover
            active={exportActive}
            activator={<Button onClick={() => exportActiveSet(true)}>Export to CSV</Button>}
            onClose={() => exportActiveSet(false)}
          >
            <ActionList
              key={actionListKey}
              items={[
                {
                  content: 'Export to CSV (for Excel, Numbers, Sheets etc)',
                  onAction: () => handleExportToCSV(true),
                  suffix: !hasFeature('csv') && <Icon source={LockMajor} />,
                },
                {
                  content: 'Export to CSV (plain csv)',
                  onAction: () => handleExportToCSV(false),
                  suffix: !hasFeature('csv') && <Icon source={LockMajor} />,
                }

              ]} />
          </Popover>
        </div>
      }
    </Filters>
  )
}
