import { createSlice } from '@reduxjs/toolkit';
import { tagsToMap } from 'utils/helpers';

const initialState = {
  origin: '',
  name: '',
  destination: '',
  items: {},
  tags: [],
  note: '',
  reference: '',
  type: '',
  method: 'instant'
};

function locationsArray(origin, destination) {
  return [
    destination,
    ...(origin ? [origin] : [])
  ]
}

export const slice = createSlice({
  name: 'transfer',
  initialState,
  reducers: {
    setTransfer: (state, { payload = {} }) => {
      const { origin, destination, tags } = payload
      return {
        ...initialState,
        ...payload,
        locations: locationsArray(origin, destination),
        tags_map: tagsToMap(tags)
      }
    },
    clearTransfer: (state, { payload = {} }) => {
      return initialState
    },
    setTransferDetail: (state, { payload = {} }) => {
      if (payload.tags) payload.tags_map = tagsToMap(payload.tags)
      return {
        ...state,
        ...payload
      }
    },
    setTransferItems (state, { payload = {} }) {
      state.items = payload
    },
    setTransferLocations: (state, { payload = {} }) => {
      const { origin, destination } = payload
      state.origin = origin
      state.destination = destination
      state.locations = locationsArray(origin, destination)
    },
  },
});

export const { 
  setTransfer,
  setTransferItems, 
  setTransferDetail,
  setTransferLocations,
  clearTransfer
} = slice.actions;

export default slice.reducer;
