import { getRequestShop } from 'redux/slices/env';

const { store } = require('../redux/store');

/**
 * 
 * @returns Shop name - Full url without myshopify.com
 */
export const getShopName = () => {
  const shop = getRequestShop(store.getState())
  return formatShopName(shop)
}
/**
 * 
 * @returns Shop url shop-name.myshopify.com
 */
export const getShopUrl = () => {
  const shop = getRequestShop(store.getState())
  return formatShop(shop)
}

export const formatShop = (shop) => shop 
  ? shop.includes('.myshopify.com', '') 
    ? shop 
    : `${shop}.myshopify.com`
  :'';

export const formatShopName = (shop) => shop ? shop.replace('.myshopify.com', '') : '';
