import { useCallback } from "react";
import { setInventoryItem } from "redux/slices/inventory";
import { useAppDispatch, useAppSelector } from "./hooks";



export default function useCodeMatcher() {

  const {
    inventory: { codes, items },
    config: { case_insensitive_matching = false },
  } = useAppSelector(s => s)
  
  const dispatch = useAppDispatch()
  
  const storeIntermediateInventoryItem = useCallback((item) => {
    if (!items[item.id])dispatch(setInventoryItem(item))
  },[dispatch, items])
  
  const matcher = useCallback((code) => {
    const caseify = (str) => (str || '').toString().toLowerCase()

    if (!code) return []
    
    code = case_insensitive_matching
      ? caseify(code)
      : code

    const filter = case_insensitive_matching
      ? (item) => item.id === code || caseify(item.s) === code || caseify(item.b) === code
      : (item) => item.id === code || item.s === code || item.b === code

    return codes.filter(filter)
  },[case_insensitive_matching, codes])
  
  const codeMatcher = useCallback((code) => {
    const matches = matcher(code)

    matches.forEach(({s, b, id}) => storeIntermediateInventoryItem({ sku: s, barcode: b, id }))
    
    return matches
  },[matcher, storeIntermediateInventoryItem])

  const codeMatcherMultiple = useCallback((codes) => {
    const results = codes.reduce((matches, code) => {
      return [
        ...matches,
        { code, matches: matcher(code) }
      ]
    },[])
    
    const allMatches = [].concat.apply([], results.map(m => m.matches))
    const matchedIds = allMatches.map(o => o.id)
    allMatches
      .filter(({id}, index) => {
        return !matchedIds.includes(id, index + 1)
      })
      .forEach(({s, b, id}) => {
        storeIntermediateInventoryItem({ sku: s, barcode: b, id })
      })

    return results

  },[matcher, storeIntermediateInventoryItem])

  return {
    codeMatcher,
    codeMatcherMultiple
  }
};
