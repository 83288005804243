import useAxios from "hooks/useAxios";
import { db } from "providers/firebase";
import { useCallback } from 'react';
import { getRequestShop } from "redux/slices/env";
import { dbPaths } from "refs/db-paths";
import jobCodes from 'refs/jobs-codes'
import { useAppSelector } from "./hooks";



/**
 * @param {String} shop [shop-name].myshopify.com
 */
export default function useJob() {

  const { axios } = useAxios()
  
  const shop = useAppSelector(getRequestShop)

  const createJob = useCallback(
    /**
     * 
     * @param {String} topic Topic name for job JOB_[JOB_NAME]
     * @param {Object} [data] Optional data to pass to job
     * @returns {Promise}
     */
    async (topic, data = {}) => {
      return await axios.post(`/api/create-job`, { topic, data })
    },
    [axios]
  )




  const awaitJob = useCallback(
    /**
     * 
     * @param {String} topic Topic name for job JOB_[JOB_NAME]
     * @param {Object} [data] Optional data to pass to job
     * @returns {Promise}
     */
    async (topic, data = {}) => {
      const { id } = await axios.post(`/api/create-job`, { topic, data: { ...data, _watched: true } })

      return new Promise((resolve, reject) => {
        const doc = db.doc(dbPaths.shopJobs(shop, id))
        const listener = doc.onSnapshot(s => {
          if (s.exists) {
            const { running, status, errored } = s.data() || {}
            if ((status && !running) || errored) {
              listener()
              doc.delete()
              if (errored) reject()
              if (status && !running) resolve()
            }

          }
        })
      })
    },
    [axios, shop]
  )
  return {
    createJob,
    awaitJob,
    jobCodes
  }
};