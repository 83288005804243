import { Button, Frame, Heading, Icon, Stack } from '@shopify/polaris';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { useCallback } from 'react';
import { setEmulating } from 'redux/slices/env';
import styled from 'styled-components';

import {
  TransferWithinShopifyMajor
} from '@shopify/polaris-icons';


const ViewWrapper = ({children}) => {
  const { emulating } = useAppSelector(s => s.env)
  const dispatch = useAppDispatch()

  const stopEmulating = useCallback(() => {
    dispatch(setEmulating(''))      
  },[dispatch])



  const TopBarMarkup = (
    <TopBar>
      <Stack alignment="center" spacing="tight">
          <Icon
            source={TransferWithinShopifyMajor}
            color="warning"
          />
          <Stack.Item fill>
            <Heading>{emulating}</Heading>
          </Stack.Item>
          <Stack.Item>
            <Button size="slim" outline onClick={stopEmulating}>Stop emulating</Button>
          </Stack.Item>

      </Stack>
    </TopBar>
  )


  return emulating 
  ? (
      <Frame topBar={TopBarMarkup}>
        {children}
      </Frame>
    )
  : children
}

const TopBar = styled.div`
  background-color: #fff5ea;
  box-shadow: 0 0.125rem 0.125rem -0.0625rem #e1b878;
  padding: 0.8rem 1.6rem;
`


export default ViewWrapper

