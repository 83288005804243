import styled from 'styled-components';

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`
export const Tbody = styled.tbody`
`
export const Row = styled.tr`
  ${props => props.active
    ? 'animation: highlight 0.5s; animation-timing-function: ease-out;'
    : ''
  }
  ${props => props.hasClick
    ? `
    cursor: pointer;
    &:hover {
      background-color: var(--p-surface-hovered);
    }
    `
    : ''
  }
  &:not(:first-child) {
    td {
      border-top: 0.1rem solid #e1e3e5
    }
  }
`
export const Cell = styled.td`
  padding: 1.2rem 0.8rem;
  ${props => props.fullWidth ? 'width: 100%' : ''};
  ${props => props.minWidth ? 'min-width:' + props.minWidth : ''};
  ${props => props.maxWidth ? 'max-width:' + props.maxWidth : ''};
  ${props => props.noWrap ? 'white-space: nowrap;' : ''};
  &:first-child {
    padding-left: 2rem;
  }
  &:last-child {
    padding-right: 2rem;
  }
  @media screen and (max-width: 811px) {
    vertical-align: top
  }
  
`

export const SpacedDiv = styled.div`
  margin-top: 1.2rem;
`

export const InventoryLevelWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0.6rem 0;
  * {
    margin:0
  }
  >:first-child {
    /* margin-right: 0.4rem; */
    /* text-decoration: line-through; */
  }>:nth-child(2) {
    margin: 0 0.3rem;
  }
`
export const Link = styled.span`
  cursor: pointer;
  &:hover{
    text-decoration: underline;

    svg { 
      ${props => props.newContext ? 'display: inline-block' : ''};
    }
  }
  svg {
    display: none;
    width: 16px;
    height: 16px;
    margin-bottom: -4px;
    margin-left: 4px;
  }
`
