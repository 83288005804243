import { Redirect } from '@shopify/app-bridge/actions';
import { getSessionToken } from "@shopify/app-bridge-utils";
import { useAppBridge } from '@shopify/app-bridge-react';

import React, { useEffect, useState, useCallback } from "react"

import {
  Banner,
} from "@shopify/polaris";

import styled from 'styled-components'



import useAxios from 'hooks/useAxios'
import { auth, firebase } from 'providers/firebase';
import { helpNewTicketUrl, serverAuthPath } from 'refs/links';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { setHasAuthenticated } from 'redux/slices/env.ts';

function Authenticator(props) {
  const { onAuth } = props
  const [error, errorSet] = useState(false)
  const { shop, hasSessionStorage } = useAppSelector(s => s.env)
  const dispatch = useAppDispatch()
  const app = useAppBridge();
  const redirect = Redirect.create(app)

  const { axios } = useAxios()

  const authenticated = useCallback((isAdmin) => {
      onAuth()
      dispatch(setHasAuthenticated({ authenticated: true, isAdmin }))
  },[dispatch, onAuth])

  const authenticate = useCallback(async () => {
    try {
      if (shop) {
        const shopifySessionToken = await getSessionToken(app);

        if (shopifySessionToken) {
          const { token } = await axios.get('/services/auth/app', { shopifySessionToken })
          const persistence = hasSessionStorage
            ? firebase.auth.Auth.Persistence.SESSION
            : firebase.auth.Auth.Persistence.NONE

          await auth.setPersistence(persistence)

          await auth.signInWithCustomToken(token)

        } else {
          redirect.dispatch(Redirect.Action.REMOTE, serverAuthPath(null, true))
        }
      } else {
        console.error('NO SHOP PRESENT');
      }

    } catch (error) {
      console.error(error);
      errorSet(true)
    }
  }, [app, axios, hasSessionStorage, redirect, shop])

  useEffect(() => {
    let authWatch = auth.onAuthStateChanged((user) => {
      if (user) {
        auth.currentUser.getIdTokenResult().then(token => {
          if (token.claims.shop === shop) {
            authenticated(token.claims.admin)
          } else {
            authenticate()
          }
        })
      } else {
        authenticate()
      }
    })
    return authWatch
  }, [authenticate, shop, authenticated])


  if (!error) return null

  return (
    <ErrorMessageWrap>
      <ErrorMessage>
        <Banner title="Error signing in" status="critical" action={{ content: 'Get Help', url: helpNewTicketUrl(), external: true }}>
          <p>There was a problem signing you in. Please contact support</p>
        </Banner>
      </ErrorMessage>
    </ErrorMessageWrap>
  )
}

export default Authenticator



const ErrorMessageWrap = styled.div`
    background-color: var(--p-background);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2000;
  `

const ErrorMessage = styled.div`
    display: flex;
    justify-content: center;
    padding: 1.6rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2001;
  `