import { useEffect, useContext, useState, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { AppBridgeContext } from '@shopify/app-bridge-react/context';
import { Redirect } from '@shopify/app-bridge/actions';

import Loader from 'components/Loader/Loader';
import { useAppSelector } from 'hooks/hooks';

function CheckInstallation({ children }) {
  const [redirecting, redirectingSet] = useState(false)

  const { redirectUser: { uri, external, excludePaths } } = useAppSelector(s => s.env)

  const { pathname }  = useLocation()
  const { replace } = useHistory()

  const app = useContext(AppBridgeContext);
  const redirect = Redirect.create(app)

  const noRedirectPathNames = useMemo(() => 
    [...excludePaths, uri].map(p => p.split('?')[0])
  , [excludePaths, uri])

  useEffect(() => {
    if (uri && !noRedirectPathNames.includes(pathname)) {
      if (external) {
        redirectingSet(true)
        redirect.dispatch(Redirect.Action.REMOTE, uri)
      } else {
        replace(uri)
      }
    }
  }, [excludePaths, external, replace, noRedirectPathNames, pathname, redirect, uri]);

  if (redirecting) return <Loader />

  if (!uri || noRedirectPathNames.includes(pathname)) return children
  return null

};

export default CheckInstallation;