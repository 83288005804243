import { withFirebase } from 'providers/firebase'

import React, { useCallback } from "react"

import { 
  Loading, 
  // TitleBar 
} from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { useAppBridge } from '@shopify/app-bridge-react';

import {
  Page,
} from "@shopify/polaris";


import { useEffect } from 'react';
import { getQueryParam, paramsToQuery } from 'utils/helpers';
import { useState } from 'react';
import useAxios from 'hooks/useAxios';
import { setAppPlans } from 'redux/slices/app';
import PlansList from 'components/PlansList/PlansList';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';


function Plan(props) {
  const { shop, history } = props
  const [targetPlan, targetPlanSet] = useState('')
  const [loading, loadingSet] = useState(false)

  const { state: { app_plan_name }} = useAppSelector(s => s.app)

  const historyReplace = history?.replace

  const returnPath = getQueryParam('return_path')
  const shouldReturn = getQueryParam('should_return')
  const forcePlanSelection = getQueryParam('force')

  const backPath = returnPath || '/settings'
  
  const app = useAppBridge()
  const dispatch = useAppDispatch()
  const { plans } = useAppSelector(s => s.app)
  const { axios, axiosCancel, axiosCancelError } = useAxios()
  
  const generageCharge = useCallback(async (planName) => {
    try {
      targetPlanSet(planName)
      const query = paramsToQuery({ 
        shop, 
        plan_name: planName, 
        return_to: shouldReturn ? returnPath : undefined
      })

      const { redirectUri } = await axios.get(`/services/charges/create${query}`)

      if (redirectUri) {
        const redirect = Redirect.create(app)
        redirect.dispatch(Redirect.Action.REMOTE, redirectUri)
      } else {
        targetPlanSet(null)
        if (shouldReturn && returnPath) historyReplace(returnPath)
      }
    } catch (error) {
      console.error(error)
    }
  }, [axios, shop, returnPath, app, shouldReturn, historyReplace])


  useEffect(() => {
    const getPlans = async () => {
      if (plans.length === 0) loadingSet(true)
      try {
        const plans = await axios.get(`/services/plans-available?shop=${shop}`)
        dispatch(setAppPlans(plans))
        loadingSet(false)
      } catch (error) {
        if (!axiosCancelError(error)) console.error(error);
      }
    }
    getPlans()
    return () => axiosCancel()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop])

  return (
    <Page 
      breadcrumbs={forcePlanSelection ? [] : [{ content: 'Settings', url: backPath }]} 
      title={forcePlanSelection ? 'Select plan' : 'Plan'}
    >
      { loading && <Loading/> }
      <PlansList
        plans={plans} 
        planName={app_plan_name}
        onSelectPlan={generageCharge}
        settingPlan={targetPlan}
      />
    </Page>
  )
}



export default withFirebase(Plan)
