
export default function useDbPath (props) {  
  const { db, shop } = props
  if (!db || !shop) throw new Error('Firebase must be passed via props to useDbPath')

  function isEven(n) {
    return n % 2 === 0;
 }

  // const getPathKey = (path, type) => {
  //   const parts = path.split('/')

  //   if (!isEven(parts.length)) throw new Error('Path must have an even number of parts')
    
  //   const lastPart = parts.pop()
  //   const [lastPathPart, ...dataRefParts] = lastPart.split('.')

  //   if (dataRefParts.length > 1 && type === 'set') throw new Error('Data ref cannot contain any more than one key. Only update will accept multiple keys in dot notation')

  //   const docPath = [...parts, lastPathPart].join('/')
  //   const key = dataRefParts.join('.')

  //   return {docPath, key}
  // }

  
  // const update = (path, data) => {
  //   const { docPath, key } = getPathKey(path, 'update')
  //   return key ? db.doc(docPath).update({ [key]: data }) : db.doc(docPath).update(data)
  // }

  
  // const set = (path, data, params = {}) => {
  //   const { docPath, key } = getPathKey(path, 'set')
  //   return key ? db.doc(docPath).set({ [key]: data }, params) : db.doc(docPath).set(data, params) 
  // }

  const path = (path) => {
    const parts = path.split('/')

    if (!isEven(parts.length)) throw new Error('Path must have an even number of parts')
    
    const lastPart = parts.pop()
    const [lastPathPart, ...dataRefParts] = lastPart.split('.')

    
    const docPath = [...parts, lastPathPart].join('/')
    const key = dataRefParts.join('.')

    return {
      update: (data) => {
        
        return key ? db.doc(docPath).update({ [key]: data }) : db.doc(docPath).update(data)
      },
      set: (data, params = {}) => {
        let update = {}

        if (dataRefParts.length > 0) {
          let length = dataRefParts.length
          dataRefParts.reduce((o, l, i) => o[l] = i === length - 1 ? data : o[l] || {}, update)
        } else {
          update = data
        }
        return key ? db.doc(docPath).set(update, params) : db.doc(docPath).set(data, params) 
      }
    }

  }


  return {
    path,
    // update,
    // set,
  }

}

