import { withFirebase } from 'providers/firebase'

import { useCallback, useEffect, useMemo, useState } from 'react';

import { Modal } from '@shopify/app-bridge-react';

import {
  Button,
  Stack,
  OptionList,
  Caption,
  Select,
  Subheading,
} from '@shopify/polaris';


import { objectToArray } from 'utils/helpers';

import { useAppDispatch, useAppSelector } from 'hooks/hooks';

import styled from 'styled-components';
import FeatureToggle from 'components/FeatureToggle/FeatureToggle';
import { textsMethod } from 'refs/resource-names';
import { articlePath } from 'refs/links';
import { setTransferDetail } from "redux/slices/transfer";

function TransferMethod({ loading }) {
  const [showMethodInfo, showMethodInfoSet] = useState(false)

  const {
    config: {
      default_split_location,
    },
    appData: {
      locations,
    },
    transfer
  } = useAppSelector(s => s)

  const dispatch = useAppDispatch()

  const defaultMethod = 'instant'

  const splitLocationsOptions = useMemo(() => {
    return objectToArray(locations)
      .filter(l => l.id && ![transfer.origin, transfer.destination].includes(l.id))
      .map(l => ({ label: l.name, value: l.id.toString() }))
  }, [locations, transfer.destination, transfer.origin])

  const defaultSplitLocation = useMemo(() => {
    return splitLocationsOptions
      .map(l => l.value)
      .includes(default_split_location)
      ? default_split_location
      : splitLocationsOptions[0]?.value
  }, [splitLocationsOptions, default_split_location, locations])

  const toggleMethodInfo = useCallback(() => showMethodInfoSet(x => !x), [])

  const handleMethodChange = useCallback((v) => {
    dispatch(setTransferDetail({ method: v[0] }))
  }, [dispatch])

  const handleSplitLocationChange = useCallback((v) => {
    dispatch(setTransferDetail({ split_location: v }))
  }, [dispatch])

  const locationSplitAval = useMemo(() => splitLocationsOptions.length > 0, [splitLocationsOptions.length])

  const locationsSelect = useMemo(() => (
    <Select
      options={splitLocationsOptions}
      onChange={handleSplitLocationChange}
      value={transfer.split_location}
      disabled={!locationSplitAval}
      placeholder={!locationSplitAval && "Requires third location"}
    />
  ), [splitLocationsOptions, handleSplitLocationChange, locationSplitAval, transfer.split_location])

  const transferMethods = useMemo(() => ({
    instant: {
      ...textsMethod.instant,
      disabled: loading
    },
    split_adjustment: {
      ...textsMethod.split_adjustment,
      disabled: loading
    },
    split_location: {
      ...textsMethod.split_location,
      selector: locationsSelect,
      disabled: loading || splitLocationsOptions.length <= 0
    },
  }), [splitLocationsOptions, loading, locationsSelect])

  const transferMethodsOptions = objectToArray(transferMethods)
    .map(method => ({
      value: method.id,
      label: <MethodOption {...method} />,
      disabled: method.disabled
    }))

    useEffect(() => {
    if (
      !transfer.split_location 
      && locationSplitAval 
      && defaultSplitLocation 
      // && transfer.method === 'split_location' 
    ) {
      dispatch(setTransferDetail({ split_location: defaultSplitLocation }))
    }
  }, [defaultSplitLocation, dispatch, locationSplitAval, transfer.method, transfer.split_location]);

  useEffect(() => {
    if (!transfer.method) dispatch(setTransferDetail({ method: defaultMethod }))
  }, [dispatch, transfer.method]);


  return (
    <OptionsBox>
      <Modal
        src={articlePath({id: '4579142523151'})}
        open={showMethodInfo}
        onClose={() => showMethodInfoSet(false)} size="Large"
      />
      <OptionsTitle>
        <Stack alignment="center">
          <Stack.Item fill>
            <Subheading>Transfer method</Subheading>
          </Stack.Item>
          <Stack.Item>
            <Button plain onClick={toggleMethodInfo}>Learn more</Button>
          </Stack.Item>
        </Stack>
      </OptionsTitle>
      <FeatureToggle
        feature="split_transfers"
        preview
      >
        <OptionList
          selected={transfer.method || ''}
          onChange={handleMethodChange}
          options={transferMethodsOptions}
        />
      </FeatureToggle>

    </OptionsBox>



  );
}


export default withFirebase(TransferMethod)

const MethodOption = ({ title, help, selector }) => {

  return (
    <Stack vertical spacing="extraTight">
      <Stack.Item>{title}</Stack.Item>
      <Stack.Item><Caption>{help}</Caption></Stack.Item>
      <Stack.Item>{selector}</Stack.Item>
    </Stack>
  )
}

const OptionsBox = styled.div`
  border: 0.1rem solid var(--p-divider);
  border-radius: var(--p-border-radius-base);
`
const OptionsTitle = styled.div`
  text-transform: uppercase;
  padding: 1.3rem 1.3rem 0.5rem;
  color: var(--p-text-subdued);
`