import { createSelector, createSlice } from '@reduxjs/toolkit';
import { sortArray } from 'utils/helpers';
import { DateTime } from 'luxon';

const initialState = {
  open: [],
  filterQuery: '',
  filters: {
    locations: '',
    origin: '',
    tags_map: '',
    reference: '',
    time_executed: '',
    type: '',
  },
  filterDefs: {
    time_executed: {
      index: 0,
      field: 'time_executed',
      label: 'Date',
      operation: '><',
      shortcut: true,
      valueType: 'date',
    },
    origin: {
      index: 1,
      field: 'origin',
      label: 'Supplier',
      operation: '==',
      shortcut: true,
    },
    locations: {
      index: 2,
      field: 'locations',
      label: 'Location',
      operation: 'array-contains',
      shortcut: true,
    },
    tags_map: {
      index: 3,
      field: 'tags_map',
      label: 'Tagged with',
      operation: '==',
      shortcut: true,
    },
    reference: {
      index: 4,
      field: 'reference',
      label: 'Reference',
      operation: '==',
      shortcut: true,
    },
    type: {
      index: 5,
      field: 'type',
      label: 'Transfer type',
      operation: '==',
      shortcut: true,
    },
  },
  _init: false,
};

export const slice = createSlice({
  name: 'transfers',
  initialState,
  reducers: {
    setOpenTransfers: (state, { payload = {} }) => {
      
      state.open = sortArray(payload, 'id')
      state._init = true
    },
    setFilterQuery: (state, { payload = {} }) => {
      state.filterQuery = payload
    },
    setFilter : (state, { payload = {} }) => {      
      state.filters[payload.key] = payload.value
    },
    removeFilter: (state, { payload = {} }) => {      
      state.filters[payload] = '' 
    },
    removeAllFilters: (state, { payload = {} }) => {      
      Object.keys(state.filters).forEach(k => state.filters[k] = '')
    },
  },
});

export const { 
  setFilterQuery,
  setFilter,
  removeFilter,
  removeAllFilters,
  setOpenTransfers, 
} = slice.actions;

export default slice.reducer;

const selectFilters = state => state.transfers.filters
const selectFilterDefs = state => state.transfers.filterDefs
const selectLocations = state => state.appData.locations
const selectSuppliers = state => state.suppliers

export const selectFilterValues = createSelector(
  [selectFilters, selectFilterDefs],
  (filters, filterDefs) => {
    return Object
      .entries(filters)
      .map(([field, value]) => { 
        if (!value) return null
        const { operation, valueType } = filterDefs[field]

        if (operation === '><' && valueType === 'date') {
          const [start, end] = value.split(',');
          if (!start || !end) return null
          value = [new Date(new Date(start).setHours(0, 0, 0, 0)).toISOString(), new Date(new Date(end).setHours(23, 59, 59, 999)).toISOString()]
        }

        return { field, value, operation }
      })
      .filter(v => v !== null)
  },
);

export const selectHasFilters = createSelector(
  [selectFilters],
  (filters) => {
    return Object.values(filters).some(v => v !== '')
  },
);

export const selectFilterLabels = createSelector(
  [
    selectFilters,
    selectLocations,
    selectSuppliers
  ],
  (filters, locations, suppliers) => {
    return Object.entries(filters)
    .reduce((acc, [field, value]) => {
      let label = ''
        switch (field) {
        case 'tags_map':
          label = `Tagged with: ${value ? value.join(',') : ''}`;
          break;
        case 'origin':
          label = `Supplier: ${suppliers[value]?.name || ''}`;
          break;
        case 'locations':
          label = `Location: ${locations[value]?.name || ''}`;
          break;
        case 'reference':
          label = `Reference: ${value}`;
          break;
        case 'type':
          label = `Transfer type: ${value}`;
          break;
        case 'time_executed':
          if (!value || value.split(',').length !== 2) {
            label = '';
            break;
          }
          const [start, end] = value.split(',').map(v => new Date(v));
          label = `Transfer date: ${DateTime.fromJSDate(start).toLocaleString(DateTime.DATE_MED)} - ${DateTime.fromJSDate(end).toLocaleString(DateTime.DATE_MED)}`;
          break;
        default:
          label = value;
          break;
        }
        return { ...acc, [field]: label }
    },{})
  }
)
