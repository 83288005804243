import { withFirebase } from 'providers/firebase'

import React, { useCallback } from "react"



import {
  Card,
  Checkbox,
  FormLayout,
  Layout,
  Select,
} from "@shopify/polaris";


import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { setConfigValue } from 'redux/slices/config';

function DisplaySection(props) {
  const { db, shop } = props

  const { config } = useAppSelector(s => s)
  const dispatch = useAppDispatch()

  const handleChange = useCallback((key, value) => {
    dispatch(setConfigValue({ key, value }))
    db.doc(`shops/${shop}/settings/config`).update({ [key]: value })
  }, [db, dispatch, shop])

  
  return (

    <Layout.AnnotatedSection title="Display preferences" >
      <Card>
        <Card.Section title="Products">
          <FormLayout>
            <Select
              label="Add scanned products to:"
              value={config.transfer_items_add_to}
              onChange={v => handleChange('transfer_items_add_to', v)}
              options={[
                { label: 'Top of list', value: 'top' },
                { label: 'Bottom of list', value: 'bottom' },
              ]
              }
              helpText="Add newly scanned products to top or bottom of transfer product list. Does not affect how products are transfered."
            />
            <Checkbox
              label="Show prices"
              checked={config.transfer_show_prices}
              onChange={v => handleChange('transfer_show_prices', v)}
              helpText="Display current Price and Compare at price for products in transfer."
            />
            <Checkbox
              label="Show costs"
              checked={config.transfer_show_cost}
              onChange={v => handleChange('transfer_show_cost', v)}
              helpText="Display unit cost for products in transfer."
            />
          </FormLayout>
        </Card.Section>
        <Card.Section title="Transfer options">
          <FormLayout>
            <Select
              label="Shipment details"
              value={config.show_shipping_details}
              onChange={v => handleChange('show_shipping_details', v)}
              options={[
                { label: 'Hidden', value: 'none' },
                { label: 'Show - for inventory receipts only', value: 'external' },
                { label: 'Show - for all transfer types', value: 'all' },
              ]
              }
              helpText="Show shipment deails in transfers."
            />
          </FormLayout>
        </Card.Section>
      </Card>

    </Layout.AnnotatedSection>


  )
}

export default withFirebase(DisplaySection)