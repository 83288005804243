import React, { useEffect } from 'react';

import { 
  Loading as AppBridgeLoading,
} from '@shopify/app-bridge-react';

import {
  AppProvider,
  SkeletonBodyText,
  Frame,
  Loading,
  Layout,
  TextContainer,
  SkeletonDisplayText,
  TopBar,
  Navigation,
  Card,
  TextField,
  Button,
  Stack,
  SkeletonPage,
  SkeletonThumbnail,
  Icon,
} from '@shopify/polaris';
import {
  HomeMajor,
  SearchMinor
} from '@shopify/polaris-icons';

import styled from 'styled-components';

// import shopifyLogo from 'assets/shopify_icon.svg';

const ShopifyAdmin = () => {
  const theme = {
    logo: {
      width: 25,
      topBarSource: `data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 28'%3e%3cpath fill='%2395BF47' fill-rule='evenodd' d='M17.836 27.059l-.062-23.736c-.16-.16-.472-.112-.594-.076l-.813.252a5.675 5.675 0 00-.39-.957c-.576-1.1-1.42-1.682-2.44-1.683h-.003c-.068 0-.136.006-.204.012h-.008a2.234 2.234 0 00-.092-.105C12.786.29 12.216.059 11.533.079c-1.318.038-2.63.99-3.693 2.679-.75 1.19-1.318 2.683-1.48 3.84L3.767 7.4c-.764.24-.788.263-.888.982C2.803 8.928.806 24.377.806 24.377l16.743 2.895.287-.213zM12.35 1.163a1.347 1.347 0 00-.792-.208c-2.033.06-3.807 3.235-4.26 5.352l1.949-.604.347-.107c.255-1.344.896-2.738 1.733-3.636a3.821 3.821 0 011.023-.797zm-1.793 4.135l2.796-.866c.009-.728-.07-1.805-.435-2.565-.388.16-.715.44-.95.691-.628.675-1.14 1.705-1.41 2.74zM14.23 4.16l1.299-.403c-.208-.674-.7-1.805-1.7-1.994.311.802.391 1.73.4 2.397z' clip-rule='evenodd'/%3e%3cpath fill='%235E8E3E' d='M21.587 5.088c-.099-.008-2.035-.037-2.035-.037s-1.619-1.573-1.778-1.733a.399.399 0 00-.225-.103v24.053l7.256-1.804S21.844 5.447 21.825 5.31a.263.263 0 00-.238-.222z'/%3e%3cpath fill='white' d='M13.528 8.824l-.843 3.153s-.94-.429-2.054-.358c-1.635.103-1.652 1.134-1.636 1.392.09 1.41 3.799 1.718 4.008 5.021.163 2.599-1.379 4.376-3.601 4.516-2.667.169-4.135-1.405-4.135-1.405l.565-2.404s1.478 1.115 2.66 1.04c.773-.048 1.05-.677 1.021-1.121-.116-1.84-3.137-1.731-3.328-4.754-.16-2.544 1.51-5.12 5.196-5.353 1.42-.09 2.147.273 2.147.273'/%3e%3c/svg%3e`,
    },
  };
  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      searchField={<TopBar.SearchField placeholder="Search" disabled />}
    />
  )
  
  useEffect(() => {
    const logoContainer = document.getElementsByClassName("Polaris-TopBar__LogoContainer")[0];
    const title = document.createElement("div")
    title.innerText = "Loading...";
    if (logoContainer) logoContainer.appendChild(title);
  },[])

  return (
    <ShopifyAdminWrapper>
      <AppProvider theme={theme}>
        <Frame 
        topBar={topBarMarkup} 
        navigation={
        <Navigation location="/">
          <Navigation.Section
          items={[
            { label: 'Home', icon: HomeMajor },
          ]}
        />
          
        </Navigation>
      }
        >
          <Loading />
        </Frame>
      </AppProvider>
    </ShopifyAdminWrapper>
  )
}

const ShopifyAdminWrapper = styled.div`
  .Polaris-TopBar__LogoContainer {
    div {
      flex: 1 1;
      flex-shrink: 0;
      margin: 0 .4rem 0 1.2rem;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 600;
    }
  }
`

const Skeleton = ({ children }) => {
  const isEmbeded = window.top !== window.self
  return (
    <AppProvider>
      {
        isEmbeded 
        ? <AppBridgeLoading/> 
        : <ShopifyAdmin/>
      }
    </AppProvider>
  )
}

Skeleton.Transfer = () => {
  return (
    <AppProvider>
      <SkeletonPage>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <SkeletonResourceItem first>
                <Stack>
                  <SkeletonThumbnail />
                  <Stack.Item fill><SkeletonBodyText /></Stack.Item>
                  <Stack.Item fill></Stack.Item>
                </Stack>
              </SkeletonResourceItem>
              <SkeletonResourceItem>
                <Stack>
                  <SkeletonThumbnail />
                  <Stack.Item fill><SkeletonBodyText /></Stack.Item>
                  <Stack.Item fill></Stack.Item>
                </Stack>
              </SkeletonResourceItem>
              <SkeletonResourceItem>
                <Stack>
                  <SkeletonThumbnail />
                  <Stack.Item fill><SkeletonBodyText /></Stack.Item>
                  <Stack.Item fill></Stack.Item>
                </Stack>
              </SkeletonResourceItem>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card title="Transfer">
              <Card.Section>
                <Stack>
                  <SkeletonThumbnail size="medium" />
                  <Stack.Item fill><SkeletonBodyText /></Stack.Item>
                </Stack>
              </Card.Section>
              <Card.Section>
                <Stack>
                  <SkeletonThumbnail size="medium" />
                  <Stack.Item fill><SkeletonBodyText /></Stack.Item>
                </Stack>
              </Card.Section>
            </Card>
            <Card subdued title="Additional details">
              <Card.Section>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={2} />
                </TextContainer>
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={2} />
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    </AppProvider>
  )
}

Skeleton.Page = ({ title }) => {
  return (
    <SkeletonPage title={title} primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextField
              disabled
              prefix={<Icon source={SearchMinor} />}
              connectedRight={
                <Stack spacing="extraTight">
                  <Button disabled><div style={{ minWidth: "80px" }}></div></Button>
                  <Button disabled><div style={{ minWidth: "80px" }}></div></Button>
                  <Button disabled><div style={{ minWidth: "80px" }}></div></Button>
                </Stack>
              }
            />
            <div style={{ marginTop: '1.8rem' }}>
              <SkeletonResourceItem>
                <Stack>
                  <SkeletonThumbnail size="small" />
                  <Stack.Item fill><SkeletonBodyText /></Stack.Item>
                  <Stack.Item fill></Stack.Item>
                </Stack>
              </SkeletonResourceItem>
              <SkeletonResourceItem>
                <Stack>
                  <SkeletonThumbnail size="small" />
                  <Stack.Item fill><SkeletonBodyText /></Stack.Item>
                  <Stack.Item fill></Stack.Item>
                </Stack>
              </SkeletonResourceItem>
              <SkeletonResourceItem>
                <Stack>
                  <SkeletonThumbnail size="small" />
                  <Stack.Item fill><SkeletonBodyText /></Stack.Item>
                  <Stack.Item fill></Stack.Item>
                </Stack>
              </SkeletonResourceItem>
            </div>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}

let SkeletonResourceItem = styled.div`
  &:not(:first-child) {
    padding-top: 1.2rem;
    border-top: 0.1rem solid var(--p-divider);
  };
  &:not(:last-child) {
    padding-bottom: 1.2rem;
  };
`



export default Skeleton


