import React from 'react'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'
import { dbDevServerUrl, rtdbDevServerUrl } from 'refs/server-paths'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  ...(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ? { measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID } : {}),
};

firebase.initializeApp(config)

const db = firebase.firestore()
const rtdb = firebase.database()
const firestore = firebase.firestore
const auth = firebase.auth()
const storage = firebase.storage()

if (process.env.REACT_APP_IS_EMULATOR === 'true') {
  console.log('IS EMULATOR');
  auth.useEmulator('http://localhost:9099/')
  db.useEmulator("localhost", 8080);
  rtdb.useEmulator("localhost", 9000);
  // db.useEmulator(dbDevServerUrl, 80);  
  // rtdb.useEmulator(rtdbDevServerUrl, 80);

  storage.useEmulator("localhost", 9199);
}


const FirebaseContext = React.createContext({
  db,
  rtdb,
  firestore,
  auth,
  storage,
  firebase,
})

export const withFirebase = Component => props => {
  return (
    <FirebaseContext.Consumer>
      {({ shop, host }) => {
        return (
          <Component
            {...props}
            firebase={firebase}
            db={db}
            firestore={firestore}
            rtdb={rtdb}
            storage={storage}
            auth={auth}
            shop={shop}
            host={host}
          />
        )
      }
      }
    </FirebaseContext.Consumer>
  )
}

export { firebase, db, rtdb, firestore, auth, storage }

export default FirebaseContext
