import React, { useEffect, useContext, useState, useCallback } from 'react';
import { AppBridgeContext } from '@shopify/app-bridge-react/context';
import { Redirect } from '@shopify/app-bridge/actions';
import { Banner, Page } from '@shopify/polaris';
import useAxios from 'hooks/useAxios';
import { setHasCheckedInstall, setRedirect} from 'redux/slices/env.ts';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { setSelectPlan } from 'redux/slices/env';
import { getQueryParam } from 'utils/helpers';

function CheckInstallation() {
  const [redirectUrl, redirectUrlSet] = useState('')
  const [promptPermissions, promptPermissionsSet] = useState()
  const selectPlan = getQueryParam('select_plan')

  const { hasCheckedInstall, hasAuthenticated } = useAppSelector(s => s.env)
  const dispatch = useAppDispatch()

  const app = useContext(AppBridgeContext);
  const redirect = Redirect.create(app)
  const { axios, axiosCancelError } = useAxios()

  const reAuth = useCallback(() => {
    redirect.dispatch(Redirect.Action.REMOTE, redirectUrl)
  }, [redirect, redirectUrl])

  useEffect(() => {
    const check = async () => {
      try {
        const {
          error,
          redirectUrl,
          forceRedirect,
        } = await axios.get(`/services/auth/check`)

        redirectUrlSet(redirectUrl)

        dispatch(setHasCheckedInstall(true))
        
        if (redirectUrl && forceRedirect) {
          dispatch(setRedirect({uri: redirectUrl, external: true}))
        }
        
        if (error === 'PLAN') dispatch(setSelectPlan())

        if (error === 'SCOPES') promptPermissionsSet(true)

      } catch (error) {
        if (!axiosCancelError(error)) console.error(error);
        console.error(error)
        console.log(error?.data);
      }
    }

    if (!hasCheckedInstall && hasAuthenticated) check()
    // return () => axiosCancel()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAuthenticated]);

  useEffect(() => {
    if (selectPlan) dispatch(setSelectPlan())
  }, [dispatch, selectPlan]);


  if (promptPermissions) return (
    <Page fullWidth>
        <Banner
          title="Permissions have changed"
          status="warning"
          action={{ content: 'Update app', onAction: reAuth }}
        >
          <p>
            The permissions required for this app have changed.
            Update now to to continue using the app without any interuptions.
            <br />This will only take a moment and will not affect any of your data.
          </p>
        </Banner>
    </Page>
  )

  return null

};

export default CheckInstallation;