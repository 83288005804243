/* eslint-disable max-len */
const { getShopName, getShopUrl } = require('./shop');

exports.rtdbPaths = {
  /**
   * Path to inventory index
   * @param {String} shop - {shop-name}.myshopify.com
   * @param {String} [id] - id of inventory item
   * @return {String} shops/{shop-name}/inventory_index[/{id}]
   */
  inventoryIndex: (shop, id) => `shops/${getShopName(shop)}/inventory_index${id ? `/${id}` : ''}`,
  /**
   * Path to inventory index
   * @param {String} shop - {shop-name}.myshopify.com
   * @param {String} topic - webhook topic being queued
   * @param {String} timeId - timeid that webhooks are queued under
   * @return {String} shops/{shop-name}/webhooks/{topic}/{timeId}
   */
  webhookQueue: (shop, topic, timeId) => `shops/${getShopName(shop)}/webhooks/${topic}/${timeId}`,
  // /**
  //  * Path to product index
  //  * @param {String} shop - {shop-name}.myshopify.com
  //  * @param {String} [id] - id of product
  //  * @return {String} shops/{shop-name}/product_index[/{id}]
  //  */
  // productIndex: (shop, id) => `shops/${getShopName(shop)}/product_index${id ? `/${id}` : ''}`,
};

exports.dbPaths = {
  /**
   * Path for the shops index
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @return {String} shops/_index
   */
  shopsIndex: (shop) => 'shops/_index',
  /**
   * Path for the shop overview doc
   * @memberof dbPaths
    * @param {String} shop - {shop-name}.myshopify.com
    * @return {String} shops/{shop-name.myshopify.com}
   */
  shopCollection: (shop) => 'shops',

  /**
   * Path for the shop overview doc
   * @memberof dbPaths
    * @param {String} shop - {shop-name}.myshopify.com
    * @return {String} shops/{shop-name.myshopify.com}
   */
  shopDoc: (shop) => `shops/${getShopUrl(shop)}`,

  /**
   * Path for shop errors
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @return {String} shops/{shop-name.myshopify.com}/errors
   */
  shopErrors: (shop) => `shops/${getShopUrl(shop)}/errors`,

  /**
   * Path for shop errors
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @param {String} [id] - id of operation
   * @return {String} shops/{shop-name.myshopify.com}/bulk_operations
   */
  bulkOperations: (shop, id) => `shops/${getShopUrl(shop)}/bulk_operations${id ? `/${id}` : ''}`,

  /**
   * Path shop info
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @return {String} shops/{shop-name.myshopify.com}/shop/shop
   */
  shopInfo: (shop) => `shops/${getShopUrl(shop)}/shop/shop`,

  /**
   * Path shop locations
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @return {String} shops/{shop-name.myshopify.com}/shop/locations
   */
  shopLocations: (shop) => `shops/${getShopUrl(shop)}/shop/locations`,

  /**
   * Path shop locations
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @param {String} [id] - id of job
   * @return {String} shops/{shop-name.myshopify.com}/jobs[/{id}]
   */
  shopJobs: (shop, id) => `shops/${getShopUrl(shop)}/jobs${id ? `/${id}` : ''}`,

  /**
   * Path for the installation info
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @return {String} shops/{shop-name.myshopify.com}/app/installation
   */
  appInstallation: (shop) => `shops/${getShopUrl(shop)}/app/installation`,

  /**
    * Path for the installation info
    * @memberof dbPaths
    * @param {String} shop - {shop-name}.myshopify.com
    * @return {String} shops/{shop-name.myshopify.com}/app/webhooks
    */
  appWebhooks: (shop) => `shops/${getShopUrl(shop)}/app/webhooks`,

  /**
   * Path for the app auth info. This path contains access_token
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @return {String} shops/{shop-name.myshopify.com}/app/auth
   */
  appAuth: (shop) => `shops/${getShopUrl(shop)}/app/auth`,

  /**
  /**
   * Path for the app features
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @return {String} shops/{shop-name.myshopify.com}/app/features
   */
  appFeatures: (shop) => `shops/${getShopUrl(shop)}/app/features`,

  /**
   * Path for the settings config
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @return {String} shops/{shop-name.myshopify.com}/settings/config
   */
  settingsConfig: (shop) => `shops/${getShopUrl(shop)}/settings/config`,

  /**
   * Path for the app counts
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @return {String} shops/{shop-name.myshopify.com}/app_data/counts
   */
  appCounts: (shop) => `shops/${getShopUrl(shop)}/app_data/counts`,

  /**
   * Path for the transfers or a specific transfer if an id is passed
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @param {String} [id] - id of transfer
   * @return {String} shops/{shop-name.myshopify.com}/transfers[/{id}]
   */
  transfers: (shop, id) => `shops/${getShopUrl(shop)}/transfers${id ? `/${id}` : ''}`,

  /**
   * Path for csv exports
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @param {String} [id] - id of transfer
   * @return {String} shops/{shop-name.myshopify.com}/transfers[/{id}]
   */
  csvExport: (shop, id) => `shops/${getShopUrl(shop)}/csv${id ? `/${id}` : ''}`,

  /**
   * Path for the suppliers
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @return {String} shops/{shop-name.myshopify.com}/shop/suppliers
   */
  suppliers: (shop) => `shops/${getShopUrl(shop)}/shop/suppliers`,

  /**
   * Path for update times, i.e when idnexes were last updated
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @return {String} shops/{shop-name.myshopify.com}/app_data/sync
   */
  syncInfo: (shop) => `shops/${getShopUrl(shop)}/app_data/sync`,

  /**
   * Path for update times, i.e when idnexes were last updated
   * @memberof dbPaths
   * @param {String} shop - {shop-name}.myshopify.com
   * @param {String} child - [child path]
   * @return {String} shops/{shop-name.myshopify.com}/app_data/[child]
   */
  appData: (shop, child) => `shops/${getShopUrl(shop)}/app_data${child ? `/${child}` : ''}`,

  /**
    * Path for the what's new info
    * @memberof dbPaths
    * @return {String} public/announcement
    */

  announcement: () => 'public/announcement',
  /**
    * Path for test shops doc. Used to allow test charge for approved shops
    * @memberof dbPaths
    * @return {String} system/test_shops
    */
  testShops: () => 'system/test_shops',

};
