
import React, { useMemo } from 'react';

import {
  TextStyle,
  Stack,
  SkeletonThumbnail,
  SkeletonBodyText,
} from '@shopify/polaris';

import LocationAvatar from 'components/Location/LocationAvatar'
import { useAppSelector } from 'hooks/hooks';

export default function LocationItem({ id, locationType, transferType, type, size }) {
  const { appData: { locations = {} }, suppliers = {} } = useAppSelector(s => s)

  const location = useMemo(() => {
    if (locationType === 'destination') return locations[id] || {}
    if (transferType === 'internal') return locations[id] || {}
    if (transferType === 'external') return suppliers[id] || {}
    if (type === 'supplier') return suppliers[id] || {}
    return null
  }, [locationType, locations, id, transferType, suppliers, type])

  if (!location) return (
    <Stack>
      <SkeletonThumbnail size="medium" />
      <Stack.Item fill>
        <SkeletonBodyText />
      </Stack.Item>
    </Stack>
  )
  return (
    <Stack wrap={false}>
      <LocationAvatar
        key={location.id}
        source={(location.avatar || {}).src_thumb || (location.avatar || {}).src}
        locationType={locationType}
        transferType={transferType}
        type={type}
        size={size}
      />
      <Stack.Item fill>
        <TextStyle variation={location.id ? 'strong' : 'subdued'}>
          {location.id ? location.name : <i>Deleted</i>}
        </TextStyle>
        <div>{location.address1}</div>
        <div>{location.address2}</div>
        <div>{location.city} {location.province} {location.zip}</div>

      </Stack.Item>
    </Stack>
  )

}