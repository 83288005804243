import { withFirebase } from 'providers/firebase'

import React from 'react';

import { useAppBridge } from '@shopify/app-bridge-react';

import { getSessionToken } from "@shopify/app-bridge-utils";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
  concat,
  // useQuery,
  // gql
} from "@apollo/client";
import { serverUrl } from 'refs/server-paths';



const GraphQlClient = (props) => {
  const { shop, children } = props

  const app = useAppBridge()


  const httpLink = new HttpLink({
    uri: `${serverUrl}/proxy/graphql`
  });

  const authMiddleware = new ApolloLink(async (operation, forward) => {
    const token = await getSessionToken(app);
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'Authorization': `Bearer ${token}`,
        'Authorization-Type': 'shopify',
        'X-Shopify-Shop-Domain': shop,
        'Bypass-Tunnel-Reminder': true,
      }
    }));
    return forward(operation);
  })

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
  });

  // const client = new ApolloClient({
  //   cache: new InMemoryCache(),
  //   uri: "https://countries.trevorblades.com"
  // });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}


export default withFirebase(GraphQlClient)


// TODO: modify to use the new shopify app bridge
// https://community.shopify.com/c/shopify-cli-and-tools/app-bridge-react-and-the-new-auth-method/m-p/840009