
import { withFirebase } from 'providers/firebase'

import React from "react"
import {
  Layout,
  Card,
  Stack,
  Button,
  TextStyle,
  Icon,
} from "@shopify/polaris";

import {
  SecureMajor,
} from '@shopify/polaris-icons';

import { DateTime } from 'luxon'

import { settingsPlanPath } from 'refs/links'
import { useAppSelector } from 'hooks/hooks';




export function PlanSection () {
  const {
    app_plan_name,
    app_plan_date,
    installed_at_first
  } = useAppSelector((s) => s.app.state)

  return (
    <Layout.AnnotatedSection
      title="Plan"
      description='Manage your plan and compare the features.'
      >
        <Card >
          <Card.Section>
            <Stack distribution="fillEvenly">
              <Stack.Item>
                <div>Current Plan</div>
                <div style={{display: 'flex'}}>
                  { app_plan_name === 'VIP' && <span style={{marginRight: '0.8rem'}}><Icon source={SecureMajor} color="teal"/></span> }
                  <TextStyle variation="strong">{app_plan_name}</TextStyle>
                </div>
              </Stack.Item>
              { app_plan_name && 
                <Stack.Item>
                  <div>Current Plan Since</div>
                  <TextStyle variation="strong">
                    {app_plan_date && DateTime.fromMillis(app_plan_date).toLocaleString(DateTime.DATE_MED)}
                    </TextStyle>
                </Stack.Item>
              }
              { installed_at_first && 
                <Stack.Item>
                  <div>Member since</div>
                  <TextStyle variation="strong">
                    {installed_at_first && DateTime.fromMillis(installed_at_first).toLocaleString(DateTime.DATE_MED)}
                    </TextStyle> 
                </Stack.Item> 
              }
              <Stack.Item></Stack.Item>
            </Stack>
          </Card.Section>
          <Card.Section>
            <Stack distribution="trailing">
              <Button url={settingsPlanPath()}>Manage plan</Button>
            </Stack>
          </Card.Section>
        </Card>

    </Layout.AnnotatedSection>  
  )
}

export default withFirebase(PlanSection)
