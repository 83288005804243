import { useAppSelector } from './hooks';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { settingsPlanPath } from 'refs/links'

const useFeatures = () => {
  const { replace } = useHistory()
  const { features } = useAppSelector(s => s.app)

  
  const upgradeUrl = useCallback((feature: string , shouldReturn: boolean) => {
    const returnTo = `${window.location.pathname}${window.location.search}`
  
    return features[feature] 
    ? ''
    : settingsPlanPath({
      feature,
      return_path: returnTo,
      should_return: shouldReturn
    })
  },[features])


  const hasFeature = useCallback((feature: string) => {
    return !!features[feature]
  },[features])

  const gotoUpgrade = useCallback((feature: string , shouldReturn: boolean) => {
    const link = upgradeUrl(feature, shouldReturn)
    replace(link)
  },[replace, upgradeUrl])

  


  return {
    gotoUpgrade,
    upgradeUrl,
    hasFeature
  }
};

export default useFeatures