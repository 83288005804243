import { withFirebase } from 'providers/firebase'

import { useState, useCallback, useMemo } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { 
  Provider as AppBridgeProvider, 
} from '@shopify/app-bridge-react';

import enTranslations from '@shopify/polaris/locales/en.json';

import { AppProvider } from '@shopify/polaris';

import GraphQlClient from 'providers/graphQl'

import { serverAuthPath } from 'refs/links';

import Routes, { AdapterLink, SkeletonRoutes } from './components/Routes'
import InstallationChecker from './components/InstallationChecker';
import Authenticator from './components/Authenticator';
import RedirectGuard from './components/RedirectGuard';
import ViewWrapper from './components/ViewWrapper';

import { getQueryParam } from 'utils/helpers';

const Layout = (props) => {
  const { host } = props
  const [loading, loadingSet] = useState(true)
  const returnTo = getQueryParam('return_to')
  const history = useHistory()
  const location = useLocation()
  const historyReplace = history.replace
  const isEmbeded = window.top !== window.self

  const config = {
    apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
    host,
    forceRedirect: true
  }

  const router = useMemo(() => ({location, history}),[location, history]);

  const handleAuthStateChange = useCallback(() => {
    loadingSet(false)
    if (returnTo) {
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.delete('return_to')
      historyReplace(returnTo)
    }
  },[historyReplace, returnTo])

  


  if (!isEmbeded) {
    window.location.href = serverAuthPath(null, true)
    return <SkeletonRoutes />
  }

  return (
    <AppProvider linkComponent={AdapterLink} i18n={enTranslations}>
      <AppBridgeProvider config={config} router={router}>
        <InstallationChecker/>
        <Authenticator onAuth={handleAuthStateChange}/>
        <ViewWrapper>
          <RedirectGuard>
            <GraphQlClient>
              { loading || !isEmbeded
                ? <SkeletonRoutes/>
                : <Routes/>
              }
            </GraphQlClient>
          </RedirectGuard>
        </ViewWrapper>
      </AppBridgeProvider>
    </AppProvider>
  )
}


export default withFirebase(Layout)

