import {
  Redirect,
  Route,
  NavLink,
  Switch,
} from "react-router-dom";


import Skeleton from './Skeleton'
import Loader from 'components/Loader/Loader';

import Transfers from 'views/transfers/Transfers';
import TransfersEdit from 'views/transfers/TransfersEdit';
import TransfersView from 'views/transfers/TransfersView';
import Settings from 'views/settings/Settings';
import Plan from 'views/settings/plan/Plan';
import SuppliersView from 'views/suppliers/SuppliersView';
import Suppliers from 'views/suppliers/Suppliers';
import SuppliersForm from 'views/suppliers/components/SuppliersForm';
import AdminRoute from "./AdminRoute";
import Admin from "views/admin/Admin";
import Logs from "views/logs/Logs";


function AppRoutes () {
  return (
    <Route render={({ location }) => (
      <Switch location={location}>
        <Route path="/transfers/:type/:id" component={TransfersEdit}/>
        <Route path="/transfers/:id" component={TransfersView}/>
        <Route path="/transfers" component={Transfers}/>
        <Route path="/suppliers/form" component={SuppliersForm}/>
        <Route path="/suppliers/:id" component={SuppliersView}/>
        <Route path="/suppliers/" component={Suppliers}/>
        <Route path="/settings/plan" component={Plan}/>
        <Route path="/settings" component={Settings}/>
        <Route path="/logs" component={Logs}/>
        <AdminRoute path="/admin" component={Admin}/>
        <Redirect from="/*" to={`/transfers`}/>

      </Switch>
    )} />
  )
}


export const SkeletonRoutes = () => {
  return (
      <Route render={({ location }) => (
        <Switch location={location}>
          <Route path="/settings" component={Loader}/>
          <Route path="/suppliers/form" render={() => <Loader color="white"/>}/>
          <Route path="/suppliers/" render={Skeleton.Page}/>
          <Route path="/transfers/" render={() => <Transfers loading/>}/>
          <Route path="/transfers/:id" component={Skeleton.Transfer}/>
          <Route path="/suppliers/:id" component={Skeleton.Transfer}/>
          <Route path="/transfers/product-picker" render={() => <Loader color="white"/>}/>
          <Route path="/*" component={Skeleton}/>
        </Switch>
      )} />
  )
}


export const AdapterLink = ({ url, external, ...rest }) => {
  return url.substr(0, 1) === '/'
    ? <NavLink {...rest} to={url} exact activeClassName=" Polaris-Navigation__Item--selected" 
        />
    : <a href={url} target={external ? '_blank' : ''} rel="noreferrer" {...rest}>{rest.children}</a>
}


export default AppRoutes