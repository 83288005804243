import { Route, Redirect } from "react-router-dom";
import { useAppSelector } from 'hooks/hooks';
import Loader from "components/Loader/Loader";
// https://blog.netcetera.com/how-to-create-guarded-routes-for-your-react-app-d2fe7c7b6122

const AdminRoute = ({ component: Component, ...rest }) => {
  const { isAdmin, hasAuthenticated } = useAppSelector(s => s.env)
  
  return hasAuthenticated
  ? (
      <Route {...rest} render={(props) => (
        isAdmin
          ? <Component {...props} />
          : <Redirect to='/' />
      )} />
    )
  : <Loader/>
}

export default AdminRoute;