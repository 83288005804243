import {
  TransferInMajor,
  TransferWithinShopifyMajor,
  UpdateInventoryMajor,
} from '@shopify/polaris-icons';


export const textsTransfer = {
  internal: {
    action: 'Transfer inventory',
    create: 'Transfer',
    name: 'Transfer',
    title: 'Inventory transfer',
    operationName: (opp, complete = true) => complete ? 'Transfered:' : 'Transfer:',
    icon: TransferWithinShopifyMajor
  },
  external: {
    action: 'Receive inventory',
    create: 'Receive',
    name: 'Receipt',
    title: 'Inventory receipt',
    operationName: (opp, complete = true) => complete ? 'Received:' : 'Receive',
    icon: TransferInMajor
  },
  adjustment: {
    action: 'Adjust inventory',
    create: 'Adjust',
    name: 'Adjustment',
    title: 'Inventory adjustment',
    operationName: (opp, complete = true) => {
      switch (opp) {
        case 'add': return 'Add:'
        case 'subtract': return complete ? 'Removed:' : "Remove:"
        case 'set': return 'Set to:'    
        default: return ''
      }
    },
    icon: UpdateInventoryMajor
  }
}

export const textsMethod = {
  instant: {
    title: 'Instant',
    help: 'Instantly transfer inventory between your locations',
  },
  split_adjustment: {
    title: 'Split - Adjustment',
    help: 'Split transfer into two inventory adjustments',
  },
  split_location: {
    title: 'Split - Location',
    help: 'Split transfer into two inventory transfers to holding location',
  }
}

export const textsLocation = {
  external :{
    origin: {
      heading: 'Supplier',
      subheading: 'Select supplier that inventory is being received from.',
      resourceName: { singular: 'supplier', plural: 'suppliers' }
    },
    destination: {
      heading: 'Receive to',
      subheading: 'Select location that inventory is being received into',
      resourceNames: { singular: 'destination location', plural: 'destination locations' }
    }
  },
  internal :{
    origin: {
      heading: 'Transfer from',
      subheading: 'Select location that inventory is being transfered from',
      resourceNames: { singular: 'destination location', plural: 'destination locations' }
    },
    destination: {
      heading: 'Transfer to',
      subheading: 'Select location that inventory is being transfered to',
      resourceNames: { singular: 'destination location', plural: 'destination locations' }
    }
  },
  adjustment :{
    origin: {
      heading: '',
      subheading: '',
      resourceNames: { singular: 'origin location', plural: 'origin locations' }
    },
    destination: {
      heading: 'Location',
      subheading: 'Select location that inventory is being adjusted for',
      resourceNames: { singular: 'destination location', plural: 'destination locations' }
    }
  }
}