import { withFirebase } from 'providers/firebase'

import { useMemo, useCallback, useEffect, useState, } from 'react';

import { useParams } from 'react-router-dom';

import {
  Page,
  Layout,
  Card,
  Link,
  Stack,
  TextStyle,
  Heading,
  Button,
} from '@shopify/polaris';

import { getQueryParam } from 'utils/helpers'
import { suppliersLocation } from 'refs/links'
import SuppliersNewModal from './components/SuppliersNewModal';

import TransfersList from 'components/TransfersList/TransfersList';
import LocationItem from 'components/Location/LocationItem';
import TagsField from 'components/TagsField/TagsField';
import DeleteButton from 'components/DeleteButton/DeleteButton';
import { useAppSelector } from 'hooks/hooks';
import { dbPaths } from 'refs/db-paths';
import { Loading } from '@shopify/app-bridge-react';
// import ImageUpload from 'components/ImageUpload/ImageUpload';


function SuppliersView({ db, shop, firestore, history }) {
  const [supplier, supplierSet] = useState({})
  const [saving, savingSet] = useState(false)
  const { id } = useParams()

  const returnTo = getQueryParam('returnTo')

  const {
    suppliers,
    appData: {
      ui: { tags_supplier = [] }
    }
  } = useAppSelector(s => s)

  useEffect(() => {
    supplierSet(suppliers[id] || {})
  }, [id, suppliers]);

  const dbRef = useMemo(() => db.doc(dbPaths.suppliers(shop)), [db, shop])

  const handleTagsChange = useCallback(async (tags) => {
    let supplierData = { ...supplier, tags }
    const newTags = [...new Set([...tags_supplier, ...tags])]
    savingSet(true)

    db.doc(dbPaths.appData(shop, 'ui')).set(
      { tags_supplier: newTags },
      { merge: true }
    )

    await dbRef.set({ [id]: supplierData }, { merge: true })

    supplierSet(supplierData)
    savingSet(false)
  }, [db, dbRef, id, shop, supplier, tags_supplier])

  const handleDeleteSupplier = useCallback(() => {
    dbRef.set({ [id]: firestore.FieldValue.delete() }, { merge: true })
    history.replace(suppliersLocation())
  }, [dbRef, firestore.FieldValue, history, id])


  const transfersDbRef = useMemo(() => db.collection(dbPaths.transfers(shop)).where('origin', '==', id), [db, id, shop])

  return (
    <Page
      title={supplier.name}
      breadcrumbs={[{ content: 'Transfers', url: returnTo || suppliersLocation() }]}
    >
      {saving && <Loading/>}
      <Layout>
        <Layout.Section>
          <Card>
            <TransfersList dbRef={transfersDbRef} hideControls noTransfersText="No transfers for this supplier" />
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card title={
            <Stack alignment="center">
              <Stack.Item fill><Heading>Supplier overview</Heading></Stack.Item>
              <Stack.Item >
                <SuppliersNewModal
                  title={`Edit: ${supplier.name}`}
                  id={id}
                  key={id}
                  activator={<Button plain>Edit</Button>}
                />
              </Stack.Item>
            </Stack>
          }
          >
            <Card.Section>
              <LocationItem
                id={id}
                type="supplier"
              />

            </Card.Section>
            <Card.Section title="Contacts">
              <Stack vertical spacing="tight">
                { supplier.email && <Stack.Item><Link external url={`mailto:${supplier.email}`}>{supplier.email}</Link></Stack.Item>}
                { supplier.phone && <Stack.Item><Link external url={`tel:${supplier.phone}`}>{supplier.phone}</Link></Stack.Item>}

              </Stack>
            </Card.Section>
            <Card.Section title="Contact person">
              <Stack vertical spacing="tight">
                <TextStyle variation="strong">{supplier.contact_name}</TextStyle>
                <Stack.Item><Link external url={`mailto:${supplier.contact_email}`}>{supplier.contact_email}</Link></Stack.Item>
                <Stack.Item><Link external url={`tel:${supplier.contact_phone}`}>{supplier.contact_phone}</Link></Stack.Item>

              </Stack>
            </Card.Section>

          </Card>
          <Card subdued>
            <Card.Header title="Tags">
  
            </Card.Header>
            <Card.Section>
              <TagsField
                value={supplier.tags}
                onChange={handleTagsChange}
                tagsKey="tags_supplier"
              />
            </Card.Section>
          </Card>
          <Card.Section>
            <DeleteButton textConfirm="Delete supplier" onDelete={handleDeleteSupplier}>
              <Button destructive plain fullWidth>
                <TextStyle variation="strong">Delete supplier</TextStyle>
              </Button>
            </DeleteButton>
          </Card.Section>
        </Layout.Section>
      </Layout>
    </Page>
  );
}


export default withFirebase(SuppliersView)


