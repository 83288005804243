import { withFirebase } from 'providers/firebase'

import { useEffect, useMemo } from 'react';

import { useAppSelector } from 'hooks/hooks';
import { useLocation } from 'react-router';
import { dbPaths } from 'refs/db-paths';

const ZendeskConfigure = (props) => {
  const { db, shop } = props

  const { hasZendeskWigetAccess, hasAuthenticated } = useAppSelector(s => s.env)

  const location = useLocation()
  const { pathname } = location
  
  const hideWidgetPaths = useMemo(() => [
    /\/suppliers\/form/, 
    /\/app\/article\/[a-zA-Z_0-9]+/,
    /\/logs/
  ], [])

  useEffect(() => {
    try {
      const match = hideWidgetPaths.map(r => pathname.match(r)).some(x => x)

      document.getElementById('root').style.paddingBottom = match ? '': '6.4rem'
      // eslint-disable-next-line no-undef
      zE('webWidget', match ? 'hide': 'show')
    } catch (error) {
      console.error(error);
    }
  }, [hideWidgetPaths, pathname]);

    useEffect(() => {
    if (hasZendeskWigetAccess) {
      if (hasAuthenticated) {
        db.doc(dbPaths.shopDoc(shop))
          .get()
          .then(s => {
            const { email, shop_owner: name } = s.data() || {}
            // eslint-disable-next-line no-undef
            zE('webWidget', 'identify', {
              name,
              email,
              organization: shop
            })
            // eslint-disable-next-line no-undef
            zE('webWidget', 'prefill', {
              name: {
                value: name,
              },
              email: {
                value: email,
              }
            });
          })
      }

      // eslint-disable-next-line no-undef
      zE('webWidget', 'updateSettings', {
        webWidget: {
          color: {
            theme: '#008060'
          },
          contactForm: {
            fields: [
              { id: 900000703146, prefill: { '*': shop } }
            ]
          }
        }
      });

      // eslint-disable-next-line no-undef
      // zE('webWidget', 'show')
      // eslint-disable-next-line no-undef
      // return () => zE('webWidget', 'hide')
    }
  }, [db, hasAuthenticated, hasZendeskWigetAccess, shop]);
 

  return null
}


export default withFirebase(ZendeskConfigure)

