import { getRequestShop } from 'redux/slices/env';
import { useAppSelector } from './hooks';
import Dexie, { Table } from 'dexie';
import { formatShopName } from 'refs/shop';

export interface Items {
  id?: number;
}
export interface Indexes {
  name: string;
  index: Object
}

export class IndexedDb extends Dexie {
  items!: Table<Items>; 
  indexes!: Table<Indexes>; 
  
  constructor(shop:String) {
    super(`${formatShopName(shop)}-inventory`);
    this.version(1).stores({
      items: 'id', // Primary key and indexed props
      indexes: 'name, index' // Primary key and indexed props
    });
  }
}

// export const indexedDb = new IndexedDb();

export default function useIndexedDb () {
  const shop = useAppSelector(getRequestShop)
  return new IndexedDb(shop)
}