import { withFirebase } from 'providers/firebase'

import { useCallback } from "react"



import {
  Card,
  FormLayout,
  Layout,
  Select,
} from "@shopify/polaris";


import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/hooks';
import { setConfigValue } from 'redux/slices/config';
import { dbPaths } from 'refs/db-paths';

function CSVSection(props) {
  const { db, shop } = props

  const { config } = useSelector(s => s)
  const dispatch = useAppDispatch()
  
  const handleValueChange = useCallback((key, value) => {
    dispatch(setConfigValue({ key, value }))
    db.doc(dbPaths.settingsConfig(shop)).update({ [key]: value })
  }, [db, dispatch, shop]) 

  return (

    <Layout.AnnotatedSection title="Transfer options" >
      <Card>
        <Card.Section title="Inventory adjustments">
          <FormLayout>
              <Select
                label="Default adjustment operation"
                value={config.default_adjustment_operation}
                onChange={(v) => handleValueChange('default_adjustment_operation', v)}
                options={[
                  { label: 'add', value: 'add' },
                  { label: 'subtract', value: 'subtract' },
                  { label: 'set', value: 'set' },
                ]
                }
                helpText="When adding products to an inventory adjustment use this operation as default"
              />
          </FormLayout>
        </Card.Section>
      </Card>

    </Layout.AnnotatedSection>


  )
}

export default withFirebase(CSVSection)