
import React, { useCallback, useState, useContext, useEffect } from 'react';
import { AppBridgeContext } from '@shopify/app-bridge-react/context';

import { Features, Group, Scanner } from '@shopify/app-bridge/actions';
import { useAppSelector } from "hooks/hooks";

function ShopifyScanner({ activator, onScan }) {
  const [hasCamera, hasCameraSet] = useState(false)
  const [hasCapture, hasCaptureSet] = useState(false)

  const app = useContext(AppBridgeContext)

  const { items } = useAppSelector(s => s.transfer)

  const scanner = Scanner.create(app);
  const features = Features.create(app);

  useEffect(() => {
    if (hasCapture) {
      scanner.subscribe(Scanner.Action.CAPTURE, (payload) => {
        onScan(payload.data?.scanData)
      });
    }    
  }, [hasCapture, onScan, scanner, items]);

  const scan = useCallback(() => {
    if (hasCamera) {
      scanner.dispatch(Scanner.Action.OPEN_CAMERA)
    } else {
      features.subscribe(Features.Action.REQUEST_UPDATE, function (payload) {
        if (payload.feature[Scanner.Action.OPEN_CAMERA]?.Dispatch) {
          hasCameraSet(true)
          scanner.dispatch(Scanner.Action.OPEN_CAMERA)
        }
      });
      features.dispatch(Features.Action.REQUEST, {
        feature: Group.Scanner,
        action: Scanner.Action.OPEN_CAMERA
      });
    }
  }, [features, hasCamera, scanner])


  app.featuresAvailable([Group.Scanner]).then((state) => {
    hasCaptureSet(state.Scanner?.[Scanner.Action.CAPTURE]?.Subscribe || false)
    hasCameraSet(state.Scanner?.[Scanner.Action.OPEN_CAMERA]?.Dispatch || false)
  });

  // return null
  if (!hasCapture) return null;

  return <div onClick={scan}>{activator}</div>

}



export default ShopifyScanner
