import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  matchErrors: [],
  multipleMatches: [],
  creating: false,
  isDirty: false,
};

export const slice = createSlice({
  name: 'transfer',
  initialState,
  reducers: {
    addScanError: (state, { payload = {} }) => {
      state.matchErrors = [
        ...state.matchErrors,
        payload
      ]
    },
    addMatchErrors: (state, { payload = {} }) => {
      state.matchErrors = [
        ...state.matchErrors,
        ...payload
      ]
    },
    removeScanError: (state, { payload = {} }) => {
      let { matchErrors } = state
      matchErrors.splice(payload, 1)
      state.matchErrors = matchErrors
    },
    addMultiMatch: (state, { payload = {} }) => {
      state.multipleMatches = [
        ...state.multipleMatches,
        payload
      ]
    },
    setCreatingTransfer: (state, { payload = {} }) => {
      state.creating = payload
    },
    addMultipleMatches: (state, { payload = {} }) => {
      state.multipleMatches = [
        ...state.multipleMatches,
        ...payload
      ]
    },
    removeMultiMatch: (state, { payload = {} }) => {
      let { multipleMatches } = state
      multipleMatches.splice(payload, 1)
      state.multipleMatches = multipleMatches
    },
    clearTransferState: (state, { payload = {} }) => {
      return initialState
    },
    setDirtyState: (state, { payload = {} }) => {
      state.isDirty = payload
    },
  },
});

export const { 
  addScanError,
  addMatchErrors, 
  removeScanError,
  addMultiMatch,
  addMultipleMatches,
  removeMultiMatch,
  clearTransferState,
  setCreatingTransfer,
  setDirtyState,
} = slice.actions;

export default slice.reducer;
