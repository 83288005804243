import { withFirebase } from 'providers/firebase';

import React, { useCallback, useState, useMemo, useEffect } from 'react';

import {
  ResourceList,
  ResourceItem,
  TextStyle,
  Pagination,
  Filters,
  Stack,
  Card,
  ChoiceList,
  Badge,
  Button,
  ButtonGroup,
  Subheading,
} from '@shopify/polaris';

import {
  SortAscendingMajor,
  SortDescendingMajor
} from '@shopify/polaris-icons';

import styled from 'styled-components'
import { mapQuerySnapshot, objectToArray } from 'utils/helpers'
import { dbPaths } from 'refs/db-paths';
import ShopRenderItem from './componets/ShopRenderItem';

function Shops({db, firestore}) {

  const [shops, shopsSet] = useState([]);
  const [loading, loadingSet] = useState(true);
  const [page, pageSet] = useState(1);
  const [cursors, cursorsSet] = useState({});
  const [filters, filtersSet] = useState({
    // app_installed: true,
    // app_plan_active: true,
    // app_plan_name: null
    sort: "seen_last"
  });
  const [sortDirection, sortDirectionSet] = useState('desc')
  const [query, querySet] = useState('');
  const [shopDomain, shopDomainSet] = useState('');
  const [queryTimeout, queryTimeoutSet] = useState(null);
  const paginationLimit = 50

const handlePagination = useCallback((direction) => {
    let move = 1 * (direction === 'prev' ? -1 : 1)
    pageSet(p => p + move)
  }, [])

  const handleFilterSet = useCallback((key, value) => {
    filtersSet({ ...filters, [key]: value })
  },[filters])

  const handleFilterRemove = useCallback((key) => {
    filtersSet({ ...filters, [key]: null })
  },[filters])

  const handleFilterRemoveAll = useCallback((key) => {
    let newFilters
    Object.keys(filters).forEach(k =>  newFilters[k] = null)
    filtersSet(newFilters)
  },[filters])

  const setSortDirection = useCallback((direction) => {
    sortDirectionSet(direction)
  },[])

  const sortOptions = useMemo(() => ({
    myshopify_domain: 'Shopify domain',
    app_plan_name: 'Plan',
    seen_last: 'Seen last',
    app_installed: 'Installed',
  }), [])

    const filterFields = useMemo(() => {
    return {
      sort: {
        index: 0,
        key: 'sort',
        label: 'Sort by',
        shortcut: true,
        filterLabel: (value) => `Sort by: ${sortOptions[value]} - ${sortDirection === 'desc' ? 'Decending' : 'Ascending'}`,
        filter: (
          <>
            <ChoiceList
              selected={[filters.sort]}
              onChange={v => handleFilterSet('sort', v[0])}
              choices={Object.keys(sortOptions).map(k => ({ label: sortOptions[k], value: k}))}
            />
            <div style={{padding: '0.8rem 0 0.4rem'}}>
              <Subheading>Direction</Subheading>
            </div>
            <ButtonGroup segmented fullWidth>
              <Button primary={sortDirection === "asc"} icon={SortAscendingMajor} onClick={() => setSortDirection("asc")}></Button>
              <Button primary={sortDirection === "desc"} icon={SortDescendingMajor} onClick={() => setSortDirection("desc")}></Button>
            </ButtonGroup>
          </>
        )
      },
      app_plan_active: {
        index: 2,
        key: 'app_plan_active',
        field: 'app_plan_active',
        label: 'Active plan',
        operation: '==',
        isBoolean: true,
        filterLabel: (value) => value ? 'Active plan' : 'No plan',
        shortcut: true,
        filter: (
          <ChoiceList
            selected={[filters.app_plan_active]}
            onChange={v => handleFilterSet('app_plan_active', v[0])}
            choices={[
              { label: 'Active plan', value: true },
              { label: 'No plan', value: false },
            ]}
          />
        )
      },
      app_plan_name: {
        index: 3,
        key: 'app_plan_name',
        field: 'app_plan_name',
        label: 'Plan Name',
        operation: '==',
        isBoolean: false,
        filterLabel: (value) =>  `Plan Name: ${value}`,
        shortcut: true,
        filter: (
          <ChoiceList
            selected={[filters.app_plan_name]}
            onChange={v => handleFilterSet('app_plan_name', v[0])}
            choices={[
              { label: 'LIGHTNING', value: 'LIGHTNING' },
              { label: 'LIGHTNING PRO', value: 'LIGHTNING PRO' },
            ]}
          />
        )
      },
      app_installed: {
        index: 4,
        key: 'app_installed',
        field: 'app_installed',
        label: 'App installed',
        operation: '==',
        isBoolean: true,
        filterLabel: (value) => value ? 'App installed' : 'App not installed',
        shortcut: false,
        filter: (
          <ChoiceList
            selected={[filters.app_installed]}
            onChange={v => handleFilterSet('app_installed', v[0])}
            choices={[
              { label: 'Installed', value: true },
              { label: 'Not-Installed', value: false },
            ]}
          />
        )
      },
    }
  }, [filters.app_installed, filters.app_plan_active, filters.app_plan_name, filters.sort, handleFilterSet, setSortDirection, sortDirection, sortOptions]);

  const filtersOptions = useMemo(() => objectToArray(filterFields), [filterFields]);

  const appliedFilters = useMemo(() => {
    return Object
      .keys(filters)
      .map(key => ({
          ...filterFields[key],
          label: filterFields[key].filterLabel(filters[key]),
          value: filters[key],
          onRemove: handleFilterRemove
        })
      )
      .filter(f => (f.value !== null))
  }, [filterFields, filters, handleFilterRemove]);

  const cursor = useMemo(() => cursors[page - 1], [cursors, page])

  useEffect(() => {
    let dbRef = db.collection(dbPaths.shopCollection())

    const setItems = (snapshot) => {
      const cursor = snapshot.docs[snapshot.docs.length - 1];
      cursorsSet(c => ({ ...c, [page]: cursor }))
      loadingSet(false)
      shopsSet(mapQuerySnapshot(snapshot).filter(({id}) => id !== '_index'))
    }

    loadingSet(true)

    appliedFilters
      .forEach(({ key, field, operation, value }) => {
        if (key === 'sort') {
          if (value) dbRef = dbRef.orderBy(value, sortDirection)
        } else 
        if (operation === '==' && Array.isArray(value)) {
          value.forEach(t => {
            dbRef = dbRef.where(`${field}.${t}`, operation, true)
          })
        } else {
          dbRef = dbRef.where(field, operation, value)
        }
      })

    if (shopDomain) dbRef = dbRef.where('myshopify_domain', '==', shopDomain)
    if (cursor) dbRef = dbRef.startAfter(cursor)
    dbRef = dbRef.limit(paginationLimit)
   
    dbRef.get().then(setItems)
  }, [db, cursor, shopDomain, appliedFilters, page, sortDirection]);

  const handleQuerySet = useCallback((query) => {
    if (queryTimeout) clearTimeout(queryTimeout)

    loadingSet(true)
    querySet(query)
    queryTimeoutSet(setTimeout(() => {
        shopDomainSet((query || '').toLowerCase())
      }, 1000)
    )
  },[queryTimeout])


  const filterControl = (
    <Filters
      queryPlaceholder="[shop-name].myshopify.com"
      queryValue={query}
      onQueryChange={handleQuerySet}
      onQueryClear={() => { querySet(''); shopDomainSet('') }}
      filters={filtersOptions}
      appliedFilters={appliedFilters}
      onClearAll={handleFilterRemoveAll}
    />
  );


  return (
      <>
        <ResourceList
            resourceName={{ singular: 'shop', plural: 'shops' }}
            items={shops}
            renderItem={(item) => <ShopRenderItem item={item}/>}
            filterControl={filterControl}
            loading={loading}
          />      
          <BorderTop>
            <Card.Section>
              <Stack distribution="center">
                <Pagination
                  hasPrevious={page > 1}
                  onPrevious={() => handlePagination('prev')}
                  hasNext={true}
                  onNext={() => handlePagination('next')}
                />
              </Stack>

            </Card.Section>
          </BorderTop>
      </>
  );

}


const BorderTop = styled.div`
  border-top: .1rem solid var(--p-border-subdued);
`



export default withFirebase(Shops)