import './../style.scss'

import { useCallback, useMemo, useContext } from 'react';
import { AppBridgeContext } from '@shopify/app-bridge-react/context';
import { Redirect } from '@shopify/app-bridge/actions';

import {
  Caption,
  Stack,
  TextStyle,
  Thumbnail,
  Icon,
  Button,
  Badge,
  SkeletonDisplayText,
  Tooltip,
  ExceptionList,
} from '@shopify/polaris';

import {
  ImageMajor,
  CancelSmallMinor,
  ChevronRightMinor,
  CircleInformationMajor,
} from '@shopify/polaris-icons';

import useWindowSize from 'hooks/useWindowSize';
import styled from 'styled-components'
import { Row, Cell, SpacedDiv, InventoryLevelWrap, Link } from './styled-components'
import { compareTwoStrings } from 'string-similarity'
import { formatMoney } from 'utils/formatters';

import { textsTransfer } from 'refs/resource-names'
import QtyField from './QtyField'
import { useAppSelector } from 'hooks/hooks';

function InventoryItem(props) {
  const {
    item,
    active,
    onChange,
    onRemove,
    onClick,
    hideControls,
    showQty,
    thumbSize = "large",
    newContext = true,
    canceled = false,
    vertical = true,
  } = props

  const {
    transfer,
    suppliers,
    inventory: { items },
    transferState: { creating },
    config: {
      transfer_show_prices = false,
      transfer_show_cost = false,
    }
  } = useAppSelector(s => s)

  const {
    id,
    qty,
    operation,
  } = item

  const {
    image,
    title,
    productTitle,
    sku,
    price,
    compareAtPrice,
    unitCost,
    vendor,
    status,
    barcode,
    hasOnlyDefaultVariant,
    pid,
    vid,
  } = items[id] || {}

  const app = useContext(AppBridgeContext)

  const redirect = Redirect.create(app);

  const windowSize = useWindowSize()

  const transferType = useMemo(() => transfer?.type, [transfer])

  const splitToSmallView = windowSize.width < (transferType === 'internal' ? 1180 : 960)

  const hasClick = useMemo(() => !!onClick, [onClick])

  const handleClick = useCallback(() => onClick && onClick(id), [id, onClick])

  const supplierName = useMemo(() => {
    return transfer?.type === 'external'
      ? suppliers[transfer?.origin]?.name || ''
      : ''
  }, [suppliers, transfer?.origin, transfer?.type])

  const vendorNameLikenss = useMemo(() => {
    return transfer?.type === 'external' && supplierName && vendor
      ? compareTwoStrings(supplierName, vendor)
      : 1
  }, [supplierName, transfer?.type, vendor])

  const gotoProduct = useCallback(() => {
    redirect.dispatch(Redirect.Action.ADMIN_SECTION, {
      section: {
        name: Redirect.ResourceType.Product,
        resource: {
          id: pid,
          variant: {
            id: vid,
          },
        },
      },
      newContext,
    }
    );
  }, [newContext, pid, redirect, vid])

  const RemoveButton = () => (
    <Button
      icon={<Icon source={CancelSmallMinor} color="subdued" />}
      plain
      onClick={() => onRemove(id)}
      disabled={creating}
    ></Button>
  )

  return (
    <Row active={active} onClick={handleClick} hasClick={hasClick}>

      <Cell fullWidth maxWidth="300px">
        <Stack wrap={false}>
          <Thumbnail customer size={thumbSize} source={image || ImageMajor} />
          <Stack.Item fill>
            <Stack vertical={vertical} spacing={vertical ? "extraTight" : ""} distribution="fillEvenly">
              <Stack.Item>
                {!productTitle && <SkeletonDisplayText size="small" />}
                <TextStyle variation="strong">
                  <span style={{ marginRight: '0.8rem' }}>
                    <Link
                      onClick={gotoProduct}
                      newContext={newContext}
                    >
                      {productTitle}
                      <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M4.5 3A1.5 1.5 0 0 0 3 4.5v11A1.5 1.5 0 0 0 4.5 17h11a1.5 1.5 0 0 0 1.5-1.5V12h-2v3H5V5h3V3H4.5zM17 4a1 1 0 0 0-1-1h-5a1 1 0 1 0 0 2h2.586l-5.293 5.293a.999.999 0 1 0 1.414 1.414L15 6.414V9a1 1 0 1 0 2 0V4z"></path></svg>
                    </Link>
                  </span>
                  {status === 'DRAFT' && <Badge status="info">Draft</Badge>}
                  {status === 'ARCHIVED' && <Badge status="default">Archived</Badge>}
                </TextStyle>
                <Caption><TextStyle variation="strong">{!hasOnlyDefaultVariant && title}</TextStyle></Caption>
              </Stack.Item>
              <Stack.Item>
                <Caption><TextStyle variation="subdued">SKU: {sku}</TextStyle> </Caption>
                <Caption><TextStyle variation="subdued">Barcode: {barcode}</TextStyle></Caption>
                {transfer_show_prices &&
                  <>
                    <Caption><TextStyle variation="subdued">Price: {formatMoney(price)}</TextStyle> </Caption>
                    <Caption><TextStyle variation="subdued">Compare at price: {formatMoney(compareAtPrice)}</TextStyle></Caption>
                  </>
                }
                {transfer_show_cost &&
                  <Caption><TextStyle variation="subdued">Cost: {formatMoney(unitCost)}</TextStyle></Caption>
                }
                {transfer?.type === 'external' &&
                  <Caption>
                    <TextStyle variation="subdued">
                      Vendor: {vendor}
                      {transfer?.status !== "complete" && vendor && vendorNameLikenss < 0.85 &&
                        <Tooltip content="Vendor name appears to be different to the supplier for this transfer">
                          <VendorWarningWrapper>
                            {/* <Badge status="warning" size="small">Vendor mismatch</Badge> */}
                            <Icon source={CircleInformationMajor} color="warning" />
                          </VendorWarningWrapper>
                        </Tooltip>
                      }
                    </TextStyle>
                  </Caption>
                }
              </Stack.Item>
              {/* <ExceptionWrapper>
                <Caption>
                  <ExceptionList
                    items={[
                      {
                        status: 'warning',
                        title: 'Vendor name appears to be different to the supplier for this transfer',
                        icon: CircleInformationMajor,
                      },
                    ]}
                  />
                </Caption>
              </ExceptionWrapper> */}


            </Stack>

          </Stack.Item>
          {splitToSmallView && !hideControls &&
            <RemoveButton />
          }
        </Stack>
        {splitToSmallView && !hideControls &&
          <>
            <SpacedDiv>
              <Stack>
                {transferType === 'internal' &&
                  <InventoryLevel
                    locationType="origin"
                    item={item}
                    inventoryId={id}
                  />
                }
                <InventoryLevel
                  locationType="destination"
                  item={item}
                  inventoryId={id}
                />

              </Stack>

            </SpacedDiv>
            <SpacedDiv>
              <QtyField
                item={item}
                onChange={onChange}
                transferType={transferType}
                disabled={creating}
                isSmallView={splitToSmallView}
              />
            </SpacedDiv>
          </>
        }
      </Cell>
      {!splitToSmallView && !hideControls &&
        <>
          <Cell noWrap>
            {transferType === 'internal' &&
              <InventoryLevel
                locationType="origin"
                item={item}
                inventoryId={id}
              />
            }
          </Cell>
          <Cell noWrap>
            <InventoryLevel
              locationType="destination"
              item={item}
              inventoryId={id}
            />
          </Cell>
          <Cell minWidth="160px">
            <QtyField
              item={item}
              onChange={onChange}
              transferType={transferType}
              disabled={creating}
              isSmallView={splitToSmallView}
            />
          </Cell>
        </>


      }
      {!splitToSmallView && !hideControls &&
        <Cell>
          <RemoveButton />
        </Cell>
      }
      {showQty &&
        <Cell noWrap>
          <QtyWrapper canceled={canceled}>
            <Stack wrap={false} spacing="tight">
              {textsTransfer[transferType] &&
                <Stack.Item>
                  {textsTransfer[transferType].operationName(operation, transfer?.status === 'complete')}
                </Stack.Item>
              }
              <Stack.Item><TextStyle variation="strong">{qty || 0}</TextStyle></Stack.Item>
            </Stack>
          </QtyWrapper>
        </Cell>
      }
    </Row>
  );
}

const InventoryLevel = ({ item, inventoryId, locationType }) => {
  const {
    operation
  } = item
  const {
    transfer,
    inventory: { levels },
    appData: { locations }
  } = useAppSelector(s => s)

  const qty = Number(item.qty) * (locationType === 'origin' || item.operation === 'subtract' ? -1 : 1)
  const locationId = transfer[locationType]
  const location = locations[locationId] || {}
  const inventoryItem = levels[inventoryId] || {}
  const level = inventoryItem[locationId] !== undefined ? Number(inventoryItem[locationId]) : 'Not stocked'
  const newLevel = operation === 'set' ? qty : (typeof level === 'string' ? 0 : level) + qty
  const hasInventoryItem = Boolean(levels[inventoryId])
  // const sign = operation === 'add' 
  //   ? '+'
  //   : operation === 'subtract'
  //   ? '-'
  //   : '='

  return (
    <div>
      <TextStyle variation="strong">{location.name}</TextStyle>
      {hasInventoryItem
        ? (
          // <div>
          //   <Badge>
          //     <span>{level} </span> {sign} {qty} = {newLevel}
          //   </Badge>
          // </div>
          <InventoryLevelWrap>
            <Badge status={typeof level === 'string' ? 'warning' : 'default'}>
              {level}
            </Badge>
            <Icon source={ChevronRightMinor} color="base" />
            <Badge status={[0, 1].includes(Math.sign(newLevel)) ? 'attention' : 'critical'}>{newLevel}</Badge>
          </InventoryLevelWrap>
        )
        : <SkeletonDisplayText size="small" />
      }
    </div>
  )
}

const QtyWrapper = styled.div`
  ${props => props.canceled ? 'text-decoration: line-through;' : ''}
`

const VendorWarningWrapper = styled.span`
  .Polaris-Icon {
    display: inline;
  }
svg {
  display: inline;
  width: 1.4rem;
  height: 1.4rem;
  margin-bottom: -3px;
  margin-left: 4px;
}
`
const ExceptionWrapper = styled.div`
  /* margin-top: 1rem; */

  svg {
  display: inline;
  width: 1.4rem;
  height: 1.4rem;
  margin-bottom: -3px;
  margin-left: 4px;
}
`


export default InventoryItem
