import React, { useCallback } from "react"
import enTranslations from '@shopify/polaris/locales/en.json';

import {
  AppProvider,
  Modal,
  Button,
  Card,
  DisplayText,
  Form,
  FormLayout,
  Page,
  TextField
} from "@shopify/polaris";


import { useEffect } from 'react';
import { useState } from 'react';
import useAxios from 'hooks/useAxios';
import PlansList from 'components/PlansList/PlansList';
import { AdapterLink } from "layout/components/Routes";
import { getQueryParam } from "utils/helpers";
import { serverAuthPath } from "refs/links";
import { formatShop, formatShopName } from "refs/shop";


function PlansView(props) {
  const [plans, plansSet] = useState([])
  const [open, openSet] = useState(false)
  const [shop, shopSet] = useState('')
  const [missingShop, setMissingShop] = useState(false)
  const [loading, loadingSet] = useState(false)
  const [targetPlan, targetPlanSet] = useState('')

  const { axios, axiosCancel, axiosCancelError } = useAxios()

  const appName = process.env.REACT_APP_APP_NAME || 'App'
  const shopParam = getQueryParam('shop')

  const handleClose = useCallback(() => {
    openSet(false)
    shopSet('')
  }, [])

  const handleChange = useCallback((value) => {
    shopSet(value.replace('.myshopify.com', '').replace('https://'))
  }, [shopSet])

  const handleInstall = useCallback(() => {
    setMissingShop(false)
    loadingSet(true)

    if (shop.length > 0) {
      window.top.location.href = serverAuthPath({ shop: formatShop(shop), plan: targetPlan })
    } else {
      setMissingShop(true)
      loadingSet(false)
    }
  }, [shop, targetPlan])

  const handleSelectPlan = useCallback((plan) => {
    targetPlanSet(plan)
    if (shopParam) {
      handleInstall()
    } else {
      openSet(true)
    }
  },[handleInstall, shopParam])

  useEffect(() => {
    if (shopParam) shopSet(formatShopName(shopParam))
  }, [shopParam])

  useEffect(() => {
    const getPlans = async () => {
      try {
        const plans = await axios.get(`/services/plans`)
        plansSet(plans)
      } catch (error) {
        if (!axiosCancelError(error)) console.error(error);
      }
    }
    getPlans()
    return () => axiosCancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop])

  return (
    <AppProvider linkComponent={AdapterLink} i18n={enTranslations}>

      <Page title={`${process.env.REACT_APP_APP_NAME} Plans`}>
        <PlansList 
        plans={plans} 
        onSelectPlan={handleSelectPlan}
        settingPlan={targetPlan}
        />

        <Modal open={open} onClose={handleClose} small>
          <Card sectioned>
            <DisplayText size="large">{`Install ${appName}`}</DisplayText>
            <br />
            <Form>
              <FormLayout>
                <TextField
                  id="shop"
                  name="shop"
                  value={shop}
                  onChange={handleChange}
                  label="Shop Domain"
                  type="text"
                  placeholder="shop-name"
                  suffix=".myshopify.com"
                  error={missingShop && 'Shop domain is required'}
                  helpText={`Enter your shop domain to install ${appName}`}
                  required=""
                />
                <Button
                  primary
                  onClick={handleInstall}
                  fullWidth
                  loading={loading}
                >
                  Install {appName}
                </Button>
              </FormLayout>
            </Form>
          </Card>
        </Modal>
      </Page>

    </AppProvider>
  )
}



export default PlansView