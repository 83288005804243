import './../style.scss'

import { useCallback, useEffect, useState, useMemo } from 'react';


import {
  TextField,
  Stack,
  Button,
  ButtonGroup,
  Popover,
  Card,
} from '@shopify/polaris';

import {
  PlusMinor,
  MinusMinor
} from '@shopify/polaris-icons';


import ScanGuard from 'components/ScanGuard/ScanGuard';

import uniqid from 'uniqid';

import styled, { css } from 'styled-components'

function QtyField(props) {
  const { 
    item = {}, 
    onChange, 
    transferType, 
    disabled,
    isSmallView
  } = props
  const { qty, operation } = item
  const [active, activeSet] = useState()

  useEffect(() => {
    if (!item.operation || (transferType !== 'adjustment' && item.operation !== 'add')) {
      onChange({ ...item, operation: 'add' })
    }
  }, [operation, onChange, transferType, item])

  const handleChange = useCallback((key, value) => {
      onChange({ ...item, [key]: value })
  },[item, onChange])


  return (
    <QtyFieldWrapper 
      hasAdjustmentButtons={transferType === 'adjustment' }
      isSmallView={isSmallView}
    >
      <QtyFieldInput>
        <ScanGuard
            component={TextField}
            value={(qty || 0).toString()}
            onChange={(v) => handleChange('qty', Number(v))}
            type="number"
            placeholder="Quantity"
            autoComplete={false}
            disabled={active || disabled}
          />

        <AddSubtract 
          value={qty} 
          onUpdate={(v) => handleChange('qty', v)} 
          onActiveChange={activeSet}
          disabled={disabled}
          isSmallView={isSmallView}
        />
      </QtyFieldInput>
      {transferType === 'adjustment' &&
        <ButtonGroup segmented connectedTop fullWidth>
          <Button 
            primary={operation === 'add'} 
            onClick={() => handleChange('operation', 'add')} 
            size="slim"
            disabled={disabled}
            >
              Add
          </Button>
          <Button 
            primary={operation === 'subtract'} 
            onClick={() => handleChange('operation', 'subtract')} 
            size="slim"
            disabled={disabled}
            >
              Remove
          </Button>
          <Button 
            primary={operation === 'set'} 
            onClick={() => handleChange('operation', 'set')} 
            size="slim"
            disabled={disabled}
            >
              Set
          </Button>
        </ButtonGroup>
      }

    </QtyFieldWrapper>
  )

}


const AddSubtract = (props) => {
  const { value, onUpdate, onActiveChange, disabled, isSmallView } = props
  const [active, activeSet] = useState(false)
  const [deltaValue, deltaValueSet] = useState('')
  const [sign, signSet] = useState('+')

  const newValue = Number(value) + (deltaValue * (sign === '+' ? 1 : -1))

  const id = useMemo(() => uniqid(), [])

  const toggleActive = useCallback(() => {
    const state = !active
    activeSet(state)
    onActiveChange(state)
    if (!state) {
      deltaValueSet('')
      signSet('+')
    }
  }, [active, onActiveChange]);

  const toggleSign = useCallback((e) => {
    e.stopPropagation()
    signSet(s => s === '+' ? '-' : '+')
  }, [])

  const handleDeltaChange = useCallback((v) => {
    let delta = Number(v)
    if (isNaN(delta)) return
    deltaValueSet(Number(delta))
  }, [])

  const handleUpdate = useCallback(() => {
    onUpdate(newValue)
    toggleActive()
  }, [newValue, onUpdate, toggleActive])

  const handleKeyDown = useCallback((e) => {
    if (e.keyCode === 13) handleUpdate()
  },[handleUpdate])

  useEffect(() => {
    setTimeout(() => {
      let input = document.getElementById(id)
      if (input && active) input.focus()
    });
  }, [id, active]);

  const activator = active 
  ? <div></div>
  : (
    <TextFieldButton
      onClick={toggleActive}
      disabled={disabled}
      isSmallView={isSmallView}
      >
        <span className="Polaris-Icon">
          <svg height='20px' width='20px' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" x="0px" y="0px">
            <g><rect width="35.185467" height="5.1854663" x="14.907267" y="21.816357" fill="inherit" fillOpacity="1" fillRule="evenodd" stroke="inherit" strokeWidth="0.81453395px" strokeLinecap="butt" strokeLinejoin="round" strokeOpacity="1"></rect>
              <rect width="5.1893382" height="34.825703" x="29.905331" y="6.6326036" fill="inherit" fillOpacity="1" fillRule="evenodd" stroke="inherit" strokeWidth="0.81066155px" strokeLinecap="butt" strokeLinejoin="round" strokeOpacity="1"></rect>
              <rect width="35.185467" height="5.1854663" x="15.270904" y="49.089085" fill="inherit" fillOpacity="1" fillRule="evenodd" stroke="inherit" strokeWidth="0.81453395px" strokeLinecap="butt" strokeLinejoin="round" strokeOpacity="1"></rect></g>
          </svg>
        </span>
      </TextFieldButton>
  )

  const OperationButtons = (
    <ButtonGroup segmented>
      <Button icon={PlusMinor} onClick={toggleSign} primary={sign === '+'}></Button>
      <Button icon={MinusMinor} onClick={toggleSign} primary={sign === '-'}></Button>
    </ButtonGroup>
  )

  return (
    <Popover
      active={active}
      activator={activator}
      onClose={toggleActive}
    >
      <QtyAdjustWrapper isSmallView={isSmallView}>

        <Card.Section subdued>
          <Stack alignment="center" distribution="center">
            <Stack.Item>{value || 0}</Stack.Item>
            <Stack.Item><b>{sign}</b></Stack.Item>
            <Stack.Item><b>{deltaValue || 0}</b></Stack.Item>
            <Stack.Item><b>=</b></Stack.Item>
            <Stack.Item><b>{newValue}</b></Stack.Item>
          </Stack>
        </Card.Section>
        <Card.Section>
          <div onKeyDown={handleKeyDown}>
            <ScanGuard
              component={TextField}
              value={deltaValue.toString()}
              onChange={handleDeltaChange}
              type="number"
              id={id}
              autoFocus
              connectedLeft={OperationButtons}
              connectedRight={<Button primary onClick={handleUpdate}> Update</Button>}
            />
          </div>
        </Card.Section>
      </QtyAdjustWrapper>
    </Popover>

  )
}

const TextFieldButton = styled.div`
  position: absolute;
  z-index: 30;
  top: 0.3rem;
  right: 2.7rem;
  ${props => props.fullWidth ? 'left: 0.3rem;' : ''};
  ${props => props.disabled ? 'display: none;' : 'display: flex;'};
  background: var(--p-surface-neutral);
  border-radius: calc(var(--p-border-radius-base) - var(--p-text-field-spinner-offset));
  flex: 1 1;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  height: 3rem;
  min-width: 3.1rem;
  cursor: pointer;
  

  svg {
    fill: var(--p-icon)
  }

  ${ props => props.isSmallView && css`
    right: 6.9rem;
    `
  }
`

const QtyFieldWrapper = styled.div`
  ${ props => props.hasAdjustmentButtons && css`
    .Polaris-TextField__Backdrop {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: -1px;
    }
    `
  }
  ${ props => props.isSmallView && css`
    .Polaris-TextField {
      input {
        min-width: 13rem;
      }
      .Polaris-TextField__Spinner{
        flex-direction: row-reverse;
        width: 6.3rem;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-tap-highlight-color: transparent;
        
        .Polaris-TextField__Segment:last-child {
          margin-right: .3rem;
        }
        
        .Polaris-TextField__Segment:first-child {
          margin-bottom: 0;
        }
        .Polaris-TextField__SpinnerIcon {
          height: 2rem;
          width: 2rem;
        }
      }
    }
    `
  }
`
const QtyFieldInput = styled.div`
  position: relative;
  input {
    padding-right: 3.8rem;
  }
`
const QtyAdjustWrapper = styled.div`
  .Polaris-TextField {
    width: 10rem
  }
  ${ props => props.isSmallView && css`
    right: 6.9rem;
    `
  }
`

export default QtyField
