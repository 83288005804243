
import { withFirebase } from 'providers/firebase'

import { useCallback, useMemo, useState } from "react"
import {
  Layout,
  Button,
  Card,
  Select,
  Checkbox,
  Collapsible,
} from "@shopify/polaris";

import {Modal} from '@shopify/app-bridge-react';
import { useAppSelector } from 'hooks/hooks';
import { objectToArray } from 'utils/helpers';
import { dbPaths } from 'refs/db-paths';
import styled from 'styled-components';
import { articlePath } from 'refs/links';


export function SplitTransferSection (props) {
  const { db, shop } = props
  const [infoActive, infoActiveSet] = useState(false)
  const {
    config: {
      default_split_location,
      default_split_location_hidden
    },
    appData: {
      locations,
    },
  } = useAppSelector(s => s)
  
  const locationOptions = useMemo(() =>{
    return [
      { label: '[ none ]', value: 'nil' },
      ...objectToArray(locations).map(l => ({ label: l.name, value: l.id }))
    ]
  }, [locations])

  const setDefaultSplitLocation = useCallback((key, value) => {
      db.doc(dbPaths.settingsConfig(shop))
      .update({
         [key]: value,
         ...( key === 'default_split_location' && value === 'nil' ? { default_split_location_hidden: false } : {})
        })
  },[db, shop])

  return (
    <Layout.AnnotatedSection
      title="Split transfers"
      description={<div>
       Transfer inventory between your locations in two steps. Remove from the origin instantly and add to the destination when you are ready.
        <br/>
        <Button onClick={() => infoActiveSet(true)} plain>Learn more</Button>
        </div>
      }
  >
    <Modal
        src={articlePath({id: '4579142523151'})}
        open={infoActive} 
        onClose={() => infoActiveSet(false)} 
        size="Large"
      />

   <Card >
    <Card.Section title="Holding location">
        <Select
        label="Default holding location"
          options={locationOptions}
          onChange={v => setDefaultSplitLocation('default_split_location', v)}
          value={default_split_location}
          disabled={locationOptions.length < 4}
          placeholder={locationOptions.length < 4 && "Requires at least three locations"}
          helpText="Default holding location when doing a location based split transfer"
        />
        <Collapsible open={default_split_location && default_split_location !== 'nil'}>
          <Spacer/>
          <Checkbox
            label="Hide default holding location"
            checked={default_split_location_hidden}
            onChange={v => setDefaultSplitLocation('default_split_location_hidden', v)}
            helpText="Hide holding location from the locations options shown when creating a new transfer."
          />
        </Collapsible>
   </Card.Section>
   </Card>
  </Layout.AnnotatedSection>  
  )
}

const Spacer = styled.div`
  margin-top: 1.6rem
`


export default withFirebase(SplitTransferSection)
