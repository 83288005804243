import axios from 'axios';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { auth } from 'providers/firebase'
import { serverUrl } from 'refs/server-paths';
import { useAppSelector } from './hooks';
import { getRequestShop } from 'redux/slices/env';


export default function useAxios () {

  const shop = useAppSelector(getRequestShop)
  
  const instance = axios.create();
  
  const cancelToken = axios.CancelToken.source()
  // Intercept all requests on this Axios instance
  instance.interceptors.request.use(async (config) => {
    config.url = config.url.includes('http')
      ? config.url
      : `${serverUrl}${config.url.charAt(0) === '/' ? '' : '/' }${config.url}`
      config.cancelToken = cancelToken.token
      config.headers['X-Shopify-Shop-Domain'] = shop;
      config.headers['Bypass-Tunnel-Reminder'] = true;

    if (config.shopifySessionToken) {
      config.headers['Authorization'] = `Bearer ${config.shopifySessionToken}`;
      config.headers['Authorization-Type'] = 'shopify';
    } 
    else { 
      const token = await auth.currentUser?.getIdToken() || ''

      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['Authorization-Type'] = 'firebase';
    }
      return config
  });
  
  instance.interceptors.response.use(function (response) {
    return response.data;
  }, function (error) {
    return Promise.reject(error);
  });

  return {
    axios: instance,
    axiosCancel: cancelToken.cancel,
    axiosCancelError: axios.isCancel
  }
};