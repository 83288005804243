import { useCallback } from 'react';
import { auth, db } from 'providers/firebase'
import { serverUrl } from 'refs/server-paths';
import { useAppSelector } from 'hooks/hooks';
import useFeatures from 'hooks/useFeatures';
import { getRequestShop } from 'redux/slices/env';
import { selectFilterValues } from "redux/slices/transfers";
import { DateTime } from "luxon";



function useExportToCSV() {
  const { hasFeature, gotoUpgrade } = useFeatures()

  const {
    transfer,
  } = useAppSelector(s => s)

  const filterValues = useAppSelector(selectFilterValues)


  const shop = useAppSelector(getRequestShop)
  
  // const callExportTransfersToCSV = useCallback(async (filters, formatNumbers = true) => {
  //   if (!hasFeature('csv')) return gotoUpgrade('csv', true)
  //   const status = 'Retrieving transfers'
  //   const timestamp = DateTime.now().toISO()
  //   const timestamp_text = DateTime.now().toFormat("yyyyMMdd-HHmmss")
  //   const request = { filters, format_numbers: formatNumbers, status, timestamp, timestamp_text }
  //   db.doc(`shops/${shop}/csv/${timestamp}`).set(request)
  //   // const token = await auth.currentUser?.getIdToken() || ''
  //   // const encodedFilters = encodeURIComponent(JSON.stringify(filters))

  //   // const query = `?shop=${shop}&token=${token}&format_numbers=${formatNumbers ? 1 : 0}&filters=${encodedFilters}`
  //   // const url = `${serverUrl}/api/transfers/csv${query}` 
  //   // window.open(url, '_blank')
  // },[gotoUpgrade, hasFeature, shop])
 
  const exportTransferToCSV = useCallback(async (formatNumbers = true) => {
    // callExportTransfersToCSV([{ field: 'id', operation: '==', value: transfer.id }], formatNumbers)
    const token = await auth.currentUser?.getIdToken() || ''
    const filters = [{ field: 'id', operation: '==', value: transfer.id }]
    const encodedFilters = encodeURIComponent(JSON.stringify(filters))

    const query = `?shop=${shop}&token=${token}&format_numbers=${formatNumbers ? 1 : 0}&filters=${encodedFilters}`
    const url = `${serverUrl}/api/transfers/csv${query}` 
    window.open(url, '_blank')
  },[shop, transfer.id])

  const exportBulkTransfersToCSV = useCallback(async (formatNumbers = true) => {
    // callExportTransfersToCSV(filterValues, formatNumbers)
    if (!hasFeature('csv')) return gotoUpgrade('csv', true)
    const timestamp = DateTime.now().toISO()
    const timestamp_text = DateTime.now().toFormat("yyyyMMdd-HHmmss")
    const request = { 
      filters: filterValues, 
      format_numbers: formatNumbers, 
      status: "retrieving", 
      timestamp, 
      timestamp_text
    }
    db.doc(`shops/${shop}/csv/${timestamp}`).set(request)
  },[filterValues, gotoUpgrade, hasFeature, shop])
    

  return {
    exportTransferToCSV,
    exportBulkTransfersToCSV,
  }
}



export default useExportToCSV

