import { configureStore } from '@reduxjs/toolkit';
import app from './slices/app';
import env from './slices/env';
import config from './slices/config';
import appData from './slices/app-data';
import inventory from './slices/inventory';
import suppliers from './slices/suppliers';
import transfer from './slices/transfer';
import transfers from './slices/transfers';
import transferState from './slices/transfer-state';

export const store = configureStore({
  reducer: {
    app,
    env,
    config,
    appData,
    inventory,
    suppliers,
    transfer,
    transfers,
    transferState,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch