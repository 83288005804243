import {
  AppProvider,
  Card,
  Page,
} from "@shopify/polaris";


import useWindowSize from "hooks/useWindowSize";


function Info() {
  const windowSize = useWindowSize()

  return (
    <AppProvider forceRedirect={false}>
      <Page>
        <Card sectioned title="System Info">
          <div><b>User agent:</b> <br />{navigator.userAgent}</div>
          <div><b>Screen:</b> <br />width: {windowSize.width} <br />height: {windowSize.height}</div>
        </Card>
      </Page>
    </AppProvider>
  )
}

export default Info
