import { withFirebase } from 'providers/firebase'

import { useCallback } from "react"



import {
  Card,
  Checkbox,
  FormLayout,
  Layout,
  Select,
} from "@shopify/polaris";


import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/hooks';
import { setConfigValue } from 'redux/slices/config';
import useFeatures from 'hooks/useFeatures';
import { dbPaths } from 'refs/db-paths';

function CSVSection(props) {
  const { db, shop } = props

  const { config } = useSelector(s => s)
  const dispatch = useAppDispatch()
  const { hasFeature } = useFeatures()
  
  const handleValueChange = useCallback((key, value) => {
    dispatch(setConfigValue({ key, value }))
    db.doc(dbPaths.settingsConfig(shop)).update({ [key]: value })
  }, [db, dispatch, shop]) 

  if (!hasFeature('csv')) return null

  return (

    <Layout.AnnotatedSection title="CSV options" >
      <Card>
        <Card.Section title="CSV">
          <FormLayout>
              <Select
                label="Match code first"
                value={config.csv_code_priority}
                onChange={(v) => handleValueChange('csv_code_priority', v)}
                options={[
                  { label: 'SKU', value: 'sku' },
                  { label: 'Barcode', value: 'barcode' },]
                }
                helpText="Set if SKUs or Barcodes should be matched first when importing CSVs"
              />
              <Checkbox
                label="Include zero quantities"
                checked={config.csv_include_zero_qty}
                onChange={v => handleValueChange('csv_include_zero_qty', v)}
                helpText="Add items with no quantity to transfer. Excluded by default"
              />
              <Select
                label="Default operation"
                value={config.csv_default_operation}
                onChange={(v) => handleValueChange('csv_default_operation', v)}
                options={[
                  { label: 'add', value: 'add' },
                  { label: 'subtract', value: 'subtract' },
                  { label: 'set', value: 'set' },
                ]
                }
                helpText="When importing into Inventory Adjustment use operation as default if none present in CSV"
              />
          </FormLayout>
        </Card.Section>
      </Card>

    </Layout.AnnotatedSection>


  )
}

export default withFirebase(CSVSection)