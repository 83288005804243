
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Loading, Toast } from '@shopify/app-bridge-react';
import useImportFromCSV from "hooks/useImportFromCSV";

function CSVImport({ children }, ref) {
  const [toast, toastSet] = useState(false)
  const [loading, loadingSet] = useState(false)
  const fileRef = useRef()

  const { importFromCSV } = useImportFromCSV()

  useImperativeHandle(ref, () => ({
    uploadCSV() {
      fileRef.current.click()
    }
  }))

  const handleFileUpload = useCallback((e) => {
    loadingSet(true)
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (e) => {
        importFromCSV(e.target.result)
        toastSet(true)
        loadingSet(false)
    });

    reader.readAsText(file);
    fileRef.current.value = "";
  }, [importFromCSV])

  return (
    <>
      { loading && 
        <Loading/>
      }
      {toast &&
        <Toast content="CSV successfully uploaded" onDismiss={() => toastSet(false)} />
      }
      <input
        ref={fileRef}
        onChange={handleFileUpload}
        type="file"
        style={{ display: "none" }}
        multiple={false}
        accept=".csv"
      />
      <div onClick={() => fileRef.current.click()}>
        {children}
      </div>
    </>
  )
}

export default forwardRef(CSVImport)


