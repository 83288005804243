import { useAppSelector } from 'hooks/hooks';
import { useCallback, useState, useEffect } from 'react';

export default function ScanGuard(props) {
  const {
    value,
    onChange,
    component: Component,
  } = props
  const [lastInputTime, lastInputTimeSet] = useState(0)
  const [inputValue, inputValueSet] = useState(value)
  const [inputTimeout, inputTimeoutSet] = useState(null)
  const [changeTimeout, changeTimeoutSet] = useState(null)
  const [preventDefault, preventDefaultSet] = useState(false)
  const [fastInputCount, fastInputCountSet] = useState(0)
  const [changes, changesSet] = useState([])

  const {
    scan_keystroke_delay: scanKeystrokeDelay,
    scan_min_length: minScanLength
  } = useAppSelector(s => s.config)

  const handleInputChange = useCallback((value) => {  
    if (preventDefault){
      if (changeTimeout) clearTimeout(changeTimeout)
    } else {
      if (changeTimeout) clearTimeout(changeTimeout)
      if (inputTimeout) clearTimeout(inputTimeout)
      // inputValueSet(value)
      changesSet([...changes, value].slice(-(minScanLength + 1)))
      inputTimeoutSet(
        setTimeout(() => {
          inputValueSet(value)
        }, scanKeystrokeDelay / 3) 
      )
      changeTimeoutSet(
        setTimeout(() => {
          onChange(value)
        }, scanKeystrokeDelay * (minScanLength + 1)) // should wait longer than sum of keystroke delay for each scan iteration umtil min scan lenght is met
      )
    }
  }, [preventDefault, changeTimeout, inputTimeout, changes, minScanLength, scanKeystrokeDelay, onChange])

  useEffect(() => {
    inputValueSet(value)
    changesSet(changes => [...changes, value].slice(-(minScanLength + 1)))
  }, [minScanLength, value]);


  const handleKeyDown = useCallback((e) => {
    let { key, ctrlKey, altKey, shiftKey } = e
    const eventTime = Number(new Date())
    const timeSinceLast = eventTime - lastInputTime

      lastInputTimeSet(eventTime)

      if (key && key.length === 1 && !ctrlKey && !altKey && !shiftKey) {     
        if (timeSinceLast < scanKeystrokeDelay) {
          fastInputCountSet(c => c + 1)

          if (fastInputCount + 1 >= minScanLength) {
            preventDefaultSet(true)
        
            if (changeTimeout) clearTimeout(changeTimeout)
            if (inputTimeout) clearTimeout(inputTimeout)

            inputValueSet(changes[0])
            changesSet([changes[0]])
            preventDefaultSet(false)
            fastInputCountSet(0)
          }
        } else {
          preventDefaultSet(false)
          fastInputCountSet(0)
        }
      } else {
        preventDefaultSet(false)
        fastInputCountSet(0)
      }

  },[changeTimeout, changes, fastInputCount, inputTimeout, lastInputTime, minScanLength, scanKeystrokeDelay])

  return (
    <div onKeyDown={handleKeyDown}>
      <Component
        {...props}
        value={inputValue}
        onChange={handleInputChange}
      />
    </div>
  );
}