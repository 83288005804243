import { withFirebase } from 'providers/firebase'

import React, { useState, useCallback } from "react"

import { 
  Avatar,
  DropZone,
  Popover,
  ActionList,
  Spinner,
  Icon,
  Caption,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import {
  CircleAlertMajor
} from '@shopify/polaris-icons';

import uniqid from 'uniqid'


import useDbPath from 'hooks/useDbPath'
import { preLoadImage } from 'utils/helpers'
import styled from 'styled-components';


const ImageUpload = (props) => {
  const { 
    avatar,
    label,
    storage,
    firestore,
    image = {},
    size = "large",
    dataPath,
    srcPath,
    storagePath,
    onChange = () => {},
    onDelete = () => {},
    onLoading = () => {},
  } = props

  const [ img, imgSet ] = useState({});
  const [ imageMenu, imageMenuSet ] = useState(false);
  // const [ displayImage, displayImageSet ] = useState({})
  const dbPath = useDbPath(props)

  const toggleImageMenu = useCallback( () => imageMenuSet((imageMenu) => !imageMenu),[]);
  
  const uploadFile = useCallback(async (path, file, params) => {
    return new Promise((resolve, reject) => {
      var uploadTask = storage.ref(path).put(file, params)
  
      uploadTask.on('state_changed',
        (snapshot) => console.log('Upload is ' + (snapshot.bytesTransferred / snapshot.totalBytes) * 100 + '% done'), 
        (error) => reject(error), 
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(url => resolve(url))
        }
        )
    })
  },[storage])

  const handleDelete = useCallback(({path}) => {
    toggleImageMenu(false)
    imgSet({})
    if (onChange) onChange(null)
    if (onDelete) onDelete()
    if (srcPath) dbPath.path(srcPath).update(firestore.FieldValue.delete())
    if (dataPath) dbPath.path(dataPath).update(firestore.FieldValue.delete())
    storage.ref(path).delete()
  },[toggleImageMenu, onChange, onDelete, srcPath, dbPath, firestore.FieldValue, dataPath, storage])

  const getThumbnail = useCallback((path) => {
   return new Promise((resolve, reject) => {
     let retry = 0
     async function retrieve () {
       try {
         let src = await storage.ref(path).getDownloadURL()
         resolve(src)
       } catch (error) {
        retry++
        if (retry < 20) {
          setTimeout(retrieve, 100);
        } else {
          reject(error)
        }
       }
     }
     retrieve()
   }) 
  },[storage])

  const handleDropZoneDrop = useCallback(async (_dropFiles, acceptedFiles, _rejectedFiles) => {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    const file = acceptedFiles.filter(f => validImageTypes.includes(f.type))[0]
    const thumb_suffix = '_thumb'
    
    if (!file) return 

    const name = file.name
    const id = `image_${uniqid()}`
    const path = `${storagePath}/${id}`
    const thumbPath = `${path}${thumb_suffix}`
    const imageRedord = { id, loading: true, name, path, src: window.URL.createObjectURL(file) }
    try {
      imgSet(imageRedord)
      onLoading(true)
  
      let src = await uploadFile(path, file, { id, name })

      const src_thumb = await getThumbnail(thumbPath)
      // let thumbSrc = src.replace("?", "_thumb?")
      let update = { id, name, path, src, src_thumb }          
      if (dataPath) dbPath.path(dataPath).set(update, { merge: true })
      if (srcPath) dbPath.path(srcPath).update(src)
      if (onChange) onChange(update)
  
      
      await preLoadImage(src)
      
      imgSet(update)
      onLoading(false)
      imageMenuSet(false)
    } catch (error) {
      console.log(error);
      imgSet({ ...imageRedord, error: true, loading: false})
      onLoading(false)
    }

  },[storagePath, onLoading, uploadFile, getThumbnail, dataPath, dbPath, srcPath, onChange]);

  const displayImage = img.src ? img : image

  return (
      <div>
        { label && 
          <div className="Polaris-Labelled__LabelWrapper">
            <label className="Polaris-Label__Text">{label}</label>
          </div>
          }
        { (displayImage || {}).src 
          ? <ImageThumbnailWrapper>
              <ImageThumbnail onClick={toggleImageMenu}>
                <Popover
                  active={displayImage.loading ? false : imageMenu}
                  onClose={toggleImageMenu}
                  activator={
                    avatar 
                    ? <Avatar size={size} source={displayImage.thumb_src || displayImage.src} customer/>
                    :  <Thumbnail size={size} source={displayImage.thumb_src || displayImage.src}/>
                  }
                >
                  <Popover.Pane>
                    <ActionList items={[{content: 'Remove image', onAction: () => handleDelete(displayImage)}]} />
                  </Popover.Pane>
                  {displayImage.error &&
                    <Popover.Pane fixed>
                      <Popover.Section>
                        <Caption><TextStyle variation="negative">Image failed to upload</TextStyle></Caption>
                      </Popover.Section>
                    </Popover.Pane>
                  }
                </Popover>
                { (displayImage.loading || displayImage.error) && (
                  <ImageThumbnailOverlay white={displayImage.error}>
                    <ImageThumbnailLoader>
                      {displayImage.loading && <Spinner size="small" color="white"/>}
                      {displayImage.error && <Icon source={CircleAlertMajor} color="critical"/>}
                    </ImageThumbnailLoader>
                  </ImageThumbnailOverlay>
                )}
              </ImageThumbnail>
            
            </ImageThumbnailWrapper>
          : <DropZoneWrapper>
              <DropZone onDrop={handleDropZoneDrop} 
                accept={['image/gif', 'image/jpeg', 'image/png']} 
                type="image" 
                overlay 
                allowMultiple={false}
                >
                <DropZone.FileUpload />
              </DropZone>
            </DropZoneWrapper>
        }
      </div>
  )
}

const DropZoneWrapper = styled.div`
    height: 6rem;
    width: 6rem;
    .Polaris-Labelled--hidden{
      height: 100%;
      .Polaris-DropZone {
        height: 100%;
        .Polaris-DropZone-FileUpload {
          height: 100%;
        }
      }
    }
`

const ImageThumbnailWrapper = styled.div`
  display: inline-flex
`
const ImageThumbnail = styled.div`
  position: relative;
  display: block;
  cursor: pointer;

  .Polaris-Spinner {
    svg {
      fill: white
    }
  }
`

const ImageThumbnailOverlay = styled.div`
  background-color: ${props => props.white ? 'rgba(255, 255, 255, 0.7)' : 'rgba(33, 43, 54, 0.4)'};
  position: absolute;    
  z-index: 15;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: ${ props => props.avatar ? '100%' : 'var(--p-border-radius-base)'};
`

const ImageThumbnailLoader= styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`




export default withFirebase(ImageUpload)

