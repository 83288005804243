
import {
  Modal,
} from '@shopify/polaris';

import { Modal as ModalEmbedded } from '@shopify/app-bridge-react';
import { useAppSelector } from 'hooks/hooks';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppBridgeContext } from '@shopify/app-bridge-react/context';
import { Modal as ModalActions } from '@shopify/app-bridge/actions';

export default function ModalAll(props) {
  const { open, onClose, activator, onData, primaryAction, secondaryActions } = props
  const [isOpen, isOpenSet] = useState(open)
  const [processing, processingSet] = useState(false)
  const { isEmbedded } = useAppSelector(state => state.env)
  const app = useContext(AppBridgeContext)

  const openModal = useCallback(() => {
    isOpenSet(true)
  },[])

  const handleClose = useCallback(() => {
    if (onClose) onClose()
    isOpenSet(false)
  },[onClose])


  const modalProps = useMemo(() => {
    const onAction = ({onAction = () => {}, closeOnAction = true}) => () => {
      if (closeOnAction) handleClose()
      onAction() 
    }
    return {
      ...props,
      open: isOpen,
      onClose: handleClose,
      primaryAction: { 
        ...primaryAction, 
        disabled: processing || primaryAction.disabled, 
        onAction: onAction(primaryAction) 
      },
      secondaryActions: secondaryActions.map(a => ({ 
        ...a, 
        disabled: processing || a.disabled,
        onAction: onAction(a),
      }))
    }      
  }, [handleClose, isOpen, primaryAction, processing, props, secondaryActions])

  useEffect(() => isOpenSet(open), [open]);

  useEffect(() => {
    const dataSubscription = app.subscribe(ModalActions.Action.DATA, (data) => {
      const { _private, loading } = data
      if (onData && !_private) onData(data)
      if (_private && loading !== undefined) {
        processingSet(loading)
      }
    });
    return () => dataSubscription()
  }, [app, onData]);

  return (
    <div onClick={openModal}>
      {isEmbedded && activator}
      {isEmbedded 
        ? <ModalEmbedded {...modalProps}/>
        : <ModalNonEmbedded {...modalProps}/>
      }
    </div>
 )
}

function ModalNonEmbedded (props) {
  const { componentPath, componentProps} = props
  const Component = componentPath ? require(`../../${componentPath}`).default : null
  return (
    <Modal {...props}>
      <Component {...componentProps}/>
    </Modal>
  )
}