import { useCallback } from 'react';


import { 
  Banner,
  Icon,
 } from "@shopify/polaris"
import styled from 'styled-components';
import { useAppSelector } from 'hooks/hooks';
import {
  LockMajor
} from '@shopify/polaris-icons';
import useFeatures from 'hooks/useFeatures';

interface Props {
  feature: string,
  children: string,
  prompt: boolean,
  preview: boolean,
  title?: string,
  message?: string,
  returnOnUpgrade: boolean
  hideLock: boolean
}



const FeatureToggle = (props: Props) => {
  const {
    feature,
    children,
    prompt,
    preview,
    title,
    message,
    returnOnUpgrade = true,
    hideLock = false
  } = props

  const { upgradeUrl, gotoUpgrade } = useFeatures()

  const { features, _init } = useAppSelector(s => s.app)

  const gotoPlans = useCallback(() => {
    gotoUpgrade(feature, returnOnUpgrade)
  },[feature, gotoUpgrade, returnOnUpgrade])

  if (!_init) return null

  return (
    <>
      {features[feature]
        ? <> {children} </>
        : ((prompt || preview) &&
          <div>
            {prompt &&
              <BannerWrap>
                <Banner
                  title={title}
                  status="warning"
                  action={{
                    content: 'Upgrade now',
                    url: upgradeUrl(feature, returnOnUpgrade)
                  }}
                >
                  {message}
                </Banner>
              </BannerWrap>
            }
            {preview &&
              <PreviewWrap onClick={gotoPlans}>
                <PreviewOverlay>
                    { !hideLock && <Icon source={LockMajor} color="base" /> }
                 
                </PreviewOverlay>
                  <EventBlock>
                    {children}
                  </EventBlock>
              </PreviewWrap>
            }
          </div>
        )
      }
    </>
  )
}

export default FeatureToggle


const PreviewWrap = styled.div`
  cursor: pointer;
  position: relative;
  /* opacity: 0.3; */
`
const EventBlock = styled.div`
  pointer-events: none;
`
const BannerWrap = styled.div`
  margin-bottom: 1.6rem;
`
const PreviewOverlay = styled.div`

  position: absolute;
  background-color: rgb(229 229 229 / 30%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 1000;
  align-items: center;
  justify-content: center;
`
  

