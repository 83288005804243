import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Button,
  Caption,
  Card,
  Icon,
  Layout,
  ResourceList,
  Spinner,
  Stack,
  TextStyle,
} from '@shopify/polaris';

import {
  PageMajor,
  DeleteMajor,
  CircleCancelMajor,
} from '@shopify/polaris-icons';

import { useAppSelector } from 'hooks/hooks';

import { objectToArray } from 'utils/helpers';
import { db, storage, withFirebase } from "providers/firebase";
import { dbPaths } from "refs/db-paths";
import { DateTime } from "luxon";
import useFeatures from "hooks/useFeatures";

function Exports({ shop }) {
  const [exports, setExports] = useState([]);

  const { hasFeature } = useFeatures()

  useEffect(() => {
    if (!hasFeature('csv')) return
    const unsubscribe = db
      .collection(dbPaths.csvExport())
      .onSnapshot((snapshot) => {
        const newExports = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        setExports(newExports)
      })
    return () => unsubscribe()
  }, [hasFeature]);

  if (exports.length <= 0) return null


  return (
    <Layout.Section>
      <Card>
        <ResourceList
          resourceName={{ singular: 'csv', plural: 'csvs' }}
          items={exports}
          renderItem={item => <ResourceItemRender item={item} shop={shop} />}
        />

      </Card>
    </Layout.Section>
  );
}

const ResourceItemRender = ({ item, shop }) => {
  const { id, filename, status, path, timestamp } = item;

  const downloadFile = useCallback((path) => {
    storage.ref(path).getDownloadURL().then((url) => {
      window.open(url);
    }).catch((error) => {
      console.error(error);
    });
  }, [])

  const handleDelete = useCallback((event, id) => {
    event.preventDefault()
    event.stopPropagation()
    db.doc(dbPaths.csvExport(shop, id)).delete()
  }, [shop])

  const statusText = useMemo(() => {
    switch (status) {
      case 'retrieving':
        return 'Retrieving transfers'
      case 'fetching':
        return 'Fetching product information'
      case 'error':
        return 'Error'
      default:
        return 'Unknown'
    }
  }, [status])

  return (
    <ResourceList.Item
      id={id}
      key={id}
      filename={filename}
      status={status}
      path={path}
      onClick={() => downloadFile(path)}
    >
      <Stack alignment="center">
        {status === 'complete'
          ? <Icon source={PageMajor} color="green" />
          : <Spinner size="small" accessibilityLabel="Exporting" />
        }
        <Stack.Item fill>
          {status === 'complete'
            ? <h3>
              <TextStyle variation="">{filename}</TextStyle>
              <Caption>
                <TextStyle variation="subdued">
                  {DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_MED)}
                </TextStyle>
              </Caption>
              <p></p>
            </h3>
            : <TextStyle>Export {DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_MED)} - {statusText}</TextStyle>
          }
        </Stack.Item>
        {/* { status === 'complete' && <Button plain icon={PageDownMajor}/> } */}
        <Button
          plain
          icon={status === 'complete' ? DeleteMajor : CircleCancelMajor}
          onClick={e => handleDelete(e, id)}
        />
      </Stack>
    </ResourceList.Item>
  );
}


export default withFirebase(Exports);

