import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const slice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    setSuppliers: (state, { payload = {} }) => {
      return payload
    },
    setSupplier: (state, { payload = {} }) => {
      const { id, data } = payload
      state[id] = data
    },
  },
});

export const { 
  setSuppliers, 
  setSupplier,
} = slice.actions;

export default slice.reducer;
