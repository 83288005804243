import './style.scss'

import styled from 'styled-components';
import {
  Card,
  Page,
  Layout,
  Stack,
  Subheading,
  TextStyle,
  Heading
} from '@shopify/polaris';
import { StatusBadge } from 'utils/formatters';
import { useAppSelector } from 'hooks/hooks';
import { textsTransfer } from 'refs/resource-names'
import { useMemo } from 'react';
import { objectToArray } from 'utils/helpers';
import TransferTotals from 'views/transfers/components/TransferTotals';
import LinkedIndicator from 'views/transfers/components/LinkedIndicator';
import { DateTime } from 'luxon'
import Location from 'views/transfers/components/Location';
import TransferPrintItem from './TransferPrintItem';
import QRCode from "react-qr-code";
import { apiUrls } from "refs/links";




export default function TransferPrintWrapper({ children }) {

  return (
    <>
      <Screen>
        {children}
      </Screen>
      <Print>
        <PrintRender />
      </Print>
    </>
  )
}

const PrintRender = () => {
  const {
    env: { shop },
    transfer,
    config: {
      transfer_items_add_to = 'top'
    }
  } = useAppSelector(s => s)

  const items = useMemo(() => {
    return transfer_items_add_to === 'top'
      ? objectToArray(transfer.items).reverse()
      : objectToArray(transfer.items)
  }, [transfer.items, transfer_items_add_to])

  const gotoTransferLink = useMemo(() => apiUrls.gotoTransfer(transfer.id, {shop}), [shop, transfer.id])

  if (!transfer) return null

  return (
    <PrintWrap>
      <PrintBlock>
            <Layout.Section>
              <Stack alignment="leading">
                <Stack.Item fill>
                    <Stack alignment="center" >
                      <PageTitle>{transfer.name}</PageTitle>
                      <StatusBadge status={transfer.status} />
                    </Stack>
                    <div style={{marginTop: '0.5rem'}}>
                      <TextStyle variation="subdued">{textsTransfer[transfer.type]?.title}</TextStyle>
                    </div>
                </Stack.Item>
                <Stack.Item>
                  <div style={{marginRight: "16px"}}>
                    <QRCode value={gotoTransferLink} size={54} />
                  </div>
                </Stack.Item>
              </Stack>
            </Layout.Section>


            <Layout.Section>
              <Stack distribution="fillEvenly">
                <Stack.Item>
                  <CardSectionWrapper>
                    <TransferTotals vertical={false} title="Overview" />
                  </CardSectionWrapper>
                </Stack.Item>
                {(transfer.parent || transfer.child) &&
                  <Stack.Item>
                    <CardSectionWrapper>
                      <Card.Section title="Split transfer">
                        <LinkedIndicator transfer={transfer} mini />
                      </Card.Section>
                    </CardSectionWrapper>
                  </Stack.Item>
                }

              </Stack>

              <Stack distribution="fillEvenly">
                {transfer.origin &&
                  <CardSectionWrapper>
                    <Location
                      locationId={transfer.origin}
                      locationType="origin"
                      transferType={transfer.type}
                      displayOnly
                      avatarSize="small"
                    />
                  </CardSectionWrapper>
                }
                <CardSectionWrapper>
                  <Location
                    locationId={transfer.destination}
                    locationType="destination"
                    transferType={transfer.type}
                    displayOnly
                    avatarSize="small"
                  />
                </CardSectionWrapper>
              </Stack>
            </Layout.Section>

          {/* </Layout>
        </Page> */}
      </PrintBlock>

      <Layout.Section>
        <Subheading>Items</Subheading>
      </Layout.Section>
      <Divider top="0.8rem" />

      {
        items.map((item, i) => (
          <PrintBlock key={item.id}>
            <TransferPrintItem
              item={item}
              border={i > 0}
            />
          </PrintBlock>
        ))
      }


      <PrintBlock>
        <Divider />

        <Layout.Section>
          <Stack spacing="extraTight" vertical>
            {transfer?.reference && <Stack.Item><b>Reference:</b> {transfer?.reference}</Stack.Item>}
            {transfer?.note &&
              <Stack.Item>
                <b>Notes:</b>
                <div style={{ whiteSpace: 'pre' }}>{transfer?.note}</div>
              </Stack.Item>
            }
            {transfer.time_created && <Stack.Item><b>Created:</b> {DateTime.fromISO(transfer.time_created).toLocaleString(DateTime.DATETIME_MED)}</Stack.Item>}
            {transfer.time_executed && <Stack.Item><b>Transfered:</b> {DateTime.fromISO(transfer.time_executed).toLocaleString(DateTime.DATETIME_MED)}</Stack.Item>}
            {transfer.time_canceled && <Stack.Item><b>Canceled:</b> {DateTime.fromISO(transfer.time_canceled).toLocaleString(DateTime.DATETIME_MED)}</Stack.Item>}
          </Stack>
        </Layout.Section>
      </PrintBlock>
    </PrintWrap>
  )
}

const PrintBlock = styled.div`
  page-break-inside: avoid;
  position: relative; 
`
const PrintWrap = styled.div`
  /* page-break-inside: avoid; */
  position: relative; 
  .Polaris-Page-Header__TitleWrapper {
    width: 100%;
  }
`
const PageTitle = styled.div`
  font-size: 2rem;
  font-weight: 600;
  word-break: break-word;
  overflow-wrap: break-word;
  line-height: 2.8rem;
`


const Screen = styled.div`
  @media print {
    display: none;
  }
`
const Print = styled.div`
  @media screen {
    display: none;
  }
  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    html,
    body {
      width: 210mm;
      height: 297mm;
    }
    .Polaris-Caption {
      font-size: 1.1rem;
      line-height: 1.6rem;
  }
  }

`

const CardSectionWrapper = styled.div`
  margin: -0.4rem -2rem;
  padding: 1rem 0;
`
const Divider = styled.div`
  margin-top: ${p => p.top || 0};
  margin-bottom: ${p => p.bottom || 0};
  width: 100%;
  border-bottom: 0.1rem solid #e1e3e5;
`
export const callPrintTransfer = () => {
  // eslint-disable-next-line no-undef
  try { zE('webWidget', 'hide') } catch (e) { }
  try {
    if(!document.execCommand('print', false, null)) {
      window.print()
    }
  } catch {
    window.print()
  }
  // eslint-disable-next-line no-undef
  try { zE('webWidget', 'show') } catch (e) { }
}