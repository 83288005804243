import './style.scss'

import {
  AppProvider,
  Spinner
} from "@shopify/polaris";

export default function Loader({ color, centered = true }) {

  return (
    <div className={centered ? 'AC-Loader__Wrap AC-Loader--Centered' : 'AC-Loader__Wrap'}>

      {color && <style>{'body { background-color: ' + color + ' }'}</style>}
      <AppProvider i18n={{}}>
        <Spinner accessibilityLabel="Loading" size="large" />
      </AppProvider>
    </div>
  )
}