import { useMemo } from 'react';

import {
  Caption,
  Card,
  Icon,
  Stack,
  TextStyle,
} from '@shopify/polaris';

import {
  ProductsMajor,
  ExistingInventoryMajor,
  BankMajor,
} from '@shopify/polaris-icons';

import { useAppSelector } from 'hooks/hooks';

import { objectToArray } from 'utils/helpers';
import { formatMoney } from "utils/formatters";

export default function TransferTotals({ title, vertical = true }) {
  const {
    transfer: {
      items
    },
    inventory: {
      items: inventoryItems
    },
    config: {
      transfer_show_prices = false,
      transfer_show_cost = false,
    }
  } = useAppSelector(s => s)

  const itemArray = useMemo(() => objectToArray(items), [items])
  const productCount = useMemo(() => itemArray.length, [itemArray])

  const inventoryCount = useMemo(() => itemArray
    .reduce((total, item) => (
      total + (item.qty * (item.operation === 'subtract' ? -1 : 1))
    ), 0), [itemArray])

  const totalPrice = useMemo(() => itemArray
    .reduce((total, item) => (
      total + (Number(inventoryItems[item.id]?.price || 0) * item.qty * (item.operation === 'subtract' ? -1 : 1))
    ), 0), [itemArray, inventoryItems])

  const totalCost = useMemo(() => itemArray
    .reduce((total, item) => (
      total + (Number(inventoryItems[item.id]?.unitCost || 0) * item.qty * (item.operation === 'subtract' ? -1 : 1))
    ), 0), [itemArray, inventoryItems])

  const missingCosts = useMemo(() => itemArray
    .some(item => inventoryItems[item.id]?.title && Number(inventoryItems[item.id]?.unitCost || 0) === 0), [itemArray, inventoryItems])

  const missingPrices = useMemo(() => itemArray
    .some(item => inventoryItems[item.id]?.title && Number(inventoryItems[item.id]?.price || 0) === 0), [itemArray, inventoryItems])

  return (
    <Card.Section title={title}>
      <Stack vertical={vertical} spacing={vertical ? "tight" : "loose"}>
        <Stack>
          <Icon source={ProductsMajor} color="base" />
          <Stack.Item>Products: <b>{productCount}</b></Stack.Item>
        </Stack>
        <Stack>
          <Icon source={ExistingInventoryMajor} color="base" />
          <Stack.Item>Total inventory: <b>{inventoryCount}</b></Stack.Item>
        </Stack>
        {transfer_show_prices &&
          <Stack>
            <Icon source={BankMajor} color="base" />
            <Stack.Item>
              Total price: <b>{formatMoney(totalPrice * 100)}</b>
              {missingPrices && <TextStyle variation="subdued"><Caption>! some products missing price</Caption></TextStyle>}

              </Stack.Item>
          </Stack>
        }
        {transfer_show_cost && 
          <Stack>
            <Icon source={BankMajor} color="base" />
            <Stack.Item>
              Total cost: <b>{formatMoney(totalCost * 100)}</b>
              {missingCosts && <TextStyle variation="subdued"><Caption>! some products missing unit cost</Caption></TextStyle>}
            </Stack.Item>
          </Stack>
        }
      </Stack>

    </Card.Section>

  );
}


