import { withFirebase } from 'providers/firebase'

import React, { useCallback } from 'react';

import {
  Button,
} from '@shopify/polaris';


import store from 'store'

import ModalAll from 'components/ModalAll/ModalAll';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { setSupplier } from 'redux/slices/suppliers';

function SuppliersNewModal(props) {
  const {
    storage,
    db,
    // auth,
    id,
    title,
    activator,
    onNew,
    isNew
  } = props

  const {
    env: {
      shop,
      host
    }
  } = useAppSelector(s => s)

  const dispatch = useAppDispatch()

  const handleData = useCallback((data) => {
    store.set('supplier', data)
  }, [])
  
  const handleCreateSupplier = useCallback(async (v) => {
    let supplier = store.get('supplier')

    db.doc(`shops/${shop}/shop/suppliers`).set(
      { [supplier.id]: supplier },
      { merge: true }
      )

    dispatch(setSupplier({ id: supplier.id, data: [supplier] }))

    if (onNew) onNew(supplier.id)
  }, [db, dispatch, onNew, shop])

  const handleCancel = useCallback(() => {
    let supplier = store.get('supplier') || {}
    if (isNew && supplier.avatar && supplier.avatar.path) storage.ref(supplier.avatar.path).delete()
  }, [storage, isNew])

  return (

    <ModalAll
      title={title}
      componentPath="views/suppliers/components/SuppliersForm.jsx"
      componentParams={{ supplierId: id }}
      src={`/suppliers/form?id=${id}&shop=${shop}&host=${host}`}
      onData={handleData}
      activator={activator || <Button primary>Create supplier</Button>}
      primaryAction={{ content: 'Save supplier', onAction: handleCreateSupplier }}
      secondaryActions={[{ content: 'Cancel', onAction: handleCancel }]}
      size="large"
    />
  );
}


export default withFirebase(SuppliersNewModal)

